import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ArrowLeft, ArrowRight } from "lucide-react";

const TestimonialSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  const testimonials = [
    {
      quote:
        "Before working with Hear Me Out we were struggling with our content. Luke & George changed all that. From the first call, every step was purposeful and effective.",
      author: "Sophie Wilson",
      role: "Co-Founder, Courtney Legal",
      image: "/images/courtney.jpg",
    },
    {
      quote:
        "Working with George and Luke at Hear Me Out Music was an exceptional experience. They demonstrated a profound understanding of our company and brand. Throughout the entire process, they ensured our inclusion and provided excellent guidance. The process was well-structured, allowing for fantastic dialogue and collaboration every step of the way. We were thrilled with the final results.",
      author: "Rebecka & Sebastian Bhiladvala",
      role: "LISCH Spirits, Co-Founders",
      image: "/images/lisch.avif",
    },
    {
      quote:
        "The team at Hear Me Out had a remarkable ability to create music that aligns perfectly with our brand, bringing the global nature of our business to life through sound. We are thrilled with the results and truly appreciate their creative input.",
      author: "Nikki Griffiths",
      role: "Group Communications & ESG Director, Hyve Group",
      image: "/images/hyve.png",
    },
    {
      quote:
        "An absolute dream to work with. Not only are they super talented multi-instrumentalists/vocalists, they're a joy to work with. Always go FURTHER than above and beyond after really digging into exactly what is needed from a brief, and some…",
      author: "Max Beattie",
      role: "Senior Music Supervisor, Felt Music",
      image: "/images/felt.webp",
    },
    {
      quote:
        "The team at Hear Me Out always deliver for us on every high-profile client project we have worked on together. Happy to flex to our needs whether big budget or tighter budget and make sure the end product is exactly what the client needs.",
      author: "Joe Simons",
      role: "Head of Brand, US Agency",
      image: "/images/music-agency.png",
    },
    {
      quote:
        "I absolutely love working with Hear Me Out. They've got an emotionally and musically nuanced way of working that means he consistently delivers catchy, dynamic and polished music to a brief that never fails to impress. On top of their compositional skills they are also a dream to work with - quick, reliable, great communicators and dedicated to delivering superb quality.",
      author: "Liz Williams",
      role: "Music Supervisor",
      image: "/images/music-supervisor.jpg",
    },
    {
      quote:
        "We brought Hear Me Out in to help us create the music for our rebrand launch film. They worked closely with our creative agency to deliver music that had the energy, vibe and rhythm we were looking for. Highly recommended!",
      author: "Joe White",
      role: "Head of Creative, Lowe Alpine",
      image: "/images/lowe-alpine.jpg",
    },
  ];

  const nextSlide = useCallback(() => {
    setCurrentIndex((prev) => (prev + 1) % testimonials.length);
  }, [testimonials.length]);

  const prevSlide = () => {
    setCurrentIndex(
      (prev) => (prev - 1 + testimonials.length) % testimonials.length
    );
  };

  useEffect(() => {
    if (isAutoPlaying) {
      const timer = setInterval(nextSlide, 5000);
      return () => clearInterval(timer);
    }
  }, [isAutoPlaying, nextSlide]);

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
      </div>

      {/* Progress Bar */}
      <div className="absolute top-12 left-1/2 -translate-x-1/2">
        <div className="flex gap-2">
          {testimonials.map((_, index) => (
            <div
              key={index}
              className="w-16 h-[2px] bg-white/10 overflow-hidden"
            >
              <motion.div
                className="h-full bg-primary-green"
                initial={{ width: "0%" }}
                animate={{
                  width: currentIndex === index ? "100%" : "0%",
                }}
                transition={{
                  duration: currentIndex === index ? 5 : 0,
                  ease: "linear",
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="max-w-[1400px] mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Text Content */}
          <div className="flex flex-col">
            <motion.span
              className="block text-primary-green mb-6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.6 }}
            >
              WHAT BRANDS ARE SAYING
            </motion.span>

            {/* Content Container */}
            <div className="mb-8 min-h-[300px]">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.6 }}
                >
                  <blockquote className="text-white text-2xl leading-tight mb-8">
                    "{testimonials[currentIndex].quote}"
                  </blockquote>
                  <div>
                    <p className="text-white font-medium text-lg">
                      {testimonials[currentIndex].author}
                    </p>
                    <p className="text-white/60">
                      {testimonials[currentIndex].role}
                    </p>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>

            {/* Navigation - Now in its own container after content */}
            <div className="relative z-10 flex items-center gap-4">
              <button
                onClick={prevSlide}
                className="p-2 border border-white/10 rounded-full hover:border-primary-green hover:text-primary-green transition-colors text-white"
              >
                <ArrowLeft size={24} />
              </button>
              <button
                onClick={nextSlide}
                className="p-2 border border-white/10 rounded-full hover:border-primary-green hover:text-primary-green transition-colors text-white"
              >
                <ArrowRight size={24} />
              </button>
            </div>
          </div>

          {/* Image */}
          <div className="relative aspect-[4/3] overflow-hidden rounded-lg">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentIndex}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.6 }}
                className="absolute inset-0"
              >
                <img
                  src={testimonials[currentIndex].image}
                  alt={testimonials[currentIndex].author}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSlider;
