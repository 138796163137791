import React from "react";
import { motion } from "framer-motion";
import { ArrowLeft, ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

const ProjectNavigation = ({ currentProject, allProjects }) => {
  if (!currentProject || !allProjects || !allProjects.length) return null;

  const currentIndex = allProjects.findIndex(
    (p) => p.slug === currentProject.slug
  );
  const nextProject = allProjects[(currentIndex + 1) % allProjects.length];
  const prevProject =
    allProjects[currentIndex === 0 ? allProjects.length - 1 : currentIndex - 1];

  const NavButton = ({ project, direction }) => {
    const isNext = direction === "next";
    const videoSection = project.sections.find((s) => s.type === "video");

    return (
      <Link
        to={`/work/${project.slug}`}
        className="group relative w-full md:w-[400px] rounded-lg overflow-hidden bg-[#111111]"
      >
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5 opacity-0 group-hover:opacity-100 transition-opacity"
          initial={false}
          whileHover={{ opacity: 1 }}
        />

        {/* Background Image */}
        <div className="aspect-[16/9] relative overflow-hidden">
          <div className="absolute inset-0 bg-black/50 z-10 group-hover:bg-black/30 transition-colors" />
          <img
            src={videoSection?.data.posterImage}
            alt={project.title}
            className="w-full h-full object-cover scale-105 group-hover:scale-100 transition-transform duration-500"
          />
        </div>

        {/* Content Overlay */}
        <div className="absolute inset-0 p-6 flex flex-col justify-between z-20">
          <div
            className={`flex items-center gap-2 text-sm text-primary-green
            ${isNext ? "justify-end" : "justify-start"}`}
          >
            {!isNext && <ArrowLeft className="w-4 h-4" />}
            {isNext ? "Next Project" : "Previous Project"}
            {isNext && <ArrowRight className="w-4 h-4" />}
          </div>

          <div
            className={`flex flex-col ${
              isNext ? "items-end text-right" : "items-start"
            }`}
          >
            <h3 className="text-xl font-bold text-white/90 group-hover:text-white transition-colors">
              {project.title}
            </h3>
            <p className="text-sm text-white/70 group-hover:text-white/90 transition-colors">
              {project.sections.find((s) => s.type === "overview")?.data.brief}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className="w-full bg-[#111111] border-t border-white/5 mt-32">
      <div className="max-w-7xl mx-auto px-6 py-16">
        <div className="flex flex-col md:flex-row justify-between items-center gap-8">
          <NavButton project={prevProject} direction="prev" />
          <NavButton project={nextProject} direction="next" />
        </div>
      </div>
    </div>
  );
};

export default ProjectNavigation;
