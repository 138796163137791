import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import AdminAuth from "./AdminAuth";
import AdminDashboard from "./AdminDashboard";

const AdminRoute = () => {
  const { user, loading, logout } = useAuth();

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="w-8 h-8 border-4 border-primary-green border-t-transparent rounded-full animate-spin" />
      </div>
    );
  }

  // Check if user is admin
  if (user && user.email === process.env.REACT_APP_ADMIN_EMAIL) {
    return <AdminDashboard onLogout={logout} />;
  }

  return <AdminAuth />;
};

export default AdminRoute;
