import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { audioManager } from "../utils/audioContext";

const EQVisualization = () => {
  const [frequencyData, setFrequencyData] = useState(new Array(32).fill(0));
  const [isInitialized, setIsInitialized] = useState(false);
  const animationFrameRef = useRef(null);
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 1200, height: 400 });

  const FIXED_WIDTH = 1200;
  const FIXED_HEIGHT = 400;

  const FREQ_MIN = 50;
  const FREQ_MAX = 16000;
  const AMPLITUDE_MULTIPLIER = 1;

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
        });
      }
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    let mounted = true;

    const initAnalyzer = async () => {
      while (mounted && !audioManager.isInitialized()) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      if (!mounted) return;

      const analyzer = audioManager.getAnalyzer();
      if (analyzer) {
        // Set analyzer settings for faster response
        analyzer.smoothingTimeConstant = 0.4; // Reduced from 0.85 for faster response
        setIsInitialized(true);
        startVisualization();
      }
    };

    const startVisualization = () => {
      const updateVisualization = () => {
        if (!mounted) return;

        const analyzer = audioManager.getAnalyzer();
        if (!analyzer) return;

        const dataArray = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.getByteFrequencyData(dataArray);

        const processedData = processFrequencyData(
          dataArray,
          analyzer.context.sampleRate
        );

        setFrequencyData(processedData);
        animationFrameRef.current = requestAnimationFrame(updateVisualization);
      };

      updateVisualization();
    };

    initAnalyzer();

    return () => {
      mounted = false;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  const frequencyToX = (freq) => {
    const logMin = Math.log10(FREQ_MIN);
    const logMax = Math.log10(FREQ_MAX);
    return (Math.log10(freq) - logMin) / (logMax - logMin);
  };

  const processFrequencyData = (data, sampleRate) => {
    const bands = 64;
    const processedData = new Array(bands).fill(0);

    const binFreq = (i) => (i * sampleRate) / (data.length * 2);

    for (let i = 0; i < data.length; i++) {
      const freq = binFreq(i);
      if (freq < FREQ_MIN || freq > FREQ_MAX) continue;

      const band = Math.floor(bands * frequencyToX(freq));
      if (band < bands) {
        // Adjusted power curve for faster response
        processedData[band] = Math.max(
          processedData[band],
          Math.pow(data[i] / 255, 0.5) * 255 * AMPLITUDE_MULTIPLIER
        );
      }
    }

    // Minimal smoothing for faster response
    return processedData.map((value, i, arr) => {
      if (i === 0 || i === arr.length - 1) return value;
      return arr[i - 1] * 0.1 + value * 0.8 + arr[i + 1] * 0.1;
    });
  };

  const createEQPath = () => {
    if (!isInitialized) {
      return `M 0 ${FIXED_HEIGHT} L ${FIXED_WIDTH} ${FIXED_HEIGHT}`;
    }

    const width = FIXED_WIDTH;
    const height = FIXED_HEIGHT;
    // Adjusted padding - removed bottom padding
    const padding = {
      left: 20,
      right: 20,
      top: 20,
      bottom: 0, // Changed to 0
    };

    const points = frequencyData.map((value, index) => {
      const freq =
        FREQ_MIN *
        Math.pow(FREQ_MAX / FREQ_MIN, index / (frequencyData.length - 1));
      const x =
        padding.left +
        frequencyToX(freq) * (width - padding.left - padding.right);
      const y =
        padding.top + (1 - Math.min(value / 255, 1)) * (height - padding.top); // Removed bottom padding from calculation
      return { x, y };
    });

    let path = `M ${points[0].x} ${points[0].y}`;

    for (let i = 0; i < points.length - 1; i++) {
      const current = points[i];
      const next = points[i + 1];

      const cp1x = current.x + (next.x - current.x) / 3;
      const cp2x = current.x + (2 * (next.x - current.x)) / 3;
      const cp1y = current.y;
      const cp2y = next.y;

      path += ` C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${next.x} ${next.y}`;
    }

    // Modified to go straight to bottom edge
    path += ` L ${points[points.length - 1].x} ${height}`;
    path += ` L ${points[0].x} ${height}`;
    path += " Z";

    return path;
  };

  return (
    <div className="absolute inset-0 w-full h-full overflow-hidden flex items-center justify-center">
      <div
        ref={containerRef}
        className="relative w-[1200px] h-[400px] overflow-visible" // Added overflow-visible
        style={{
          maxWidth: "90vw",
          maxHeight: "50vh",
          marginBottom: "-2%",
        }}
      >
        <svg
          viewBox={`0 0 ${FIXED_WIDTH} ${FIXED_HEIGHT}`}
          className="w-full h-full"
          preserveAspectRatio="none"
          style={{ display: "block" }} // Added to remove any default spacing
        >
          <defs>
            <linearGradient id="eqGradient" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="0%"
                stopColor="rgb(111, 87, 165)"
                stopOpacity="0.9"
              />
              <stop
                offset="50%"
                stopColor="rgb(111, 87, 165)"
                stopOpacity="0.5"
              />
              <stop offset="100%" stopColor="rgb(0, 0, 77)" stopOpacity="0.3" />
            </linearGradient>
            <filter id="glow">
              <feGaussianBlur stdDeviation="2" result="blur" />
              <feComposite in="blur" operator="over" />
              <feMerge>
                <feMergeNode in="blur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>

          <motion.path
            d={createEQPath()}
            fill="url(#eqGradient)"
            className="transition-all duration-[25ms]"
          />

          <motion.path
            d={createEQPath()}
            fill="none"
            stroke="white"
            strokeWidth="1"
            filter="url(#glow)"
            className="transition-all duration-[25ms]"
            opacity="0.6"
          />
        </svg>
      </div>
    </div>
  );
};

export default EQVisualization;
