import React from "react";

const BlogSectionRenderer = ({ section }) => {
  switch (section.type) {
    case "text":
      return (
        <div className="prose prose-invert max-w-none">
          {section.content.split("\n").map((paragraph, index) => (
            <p key={index} className="text-white/70 text-lg">
              {paragraph}
            </p>
          ))}
        </div>
      );

    case "subtitle":
      return (
        <h2 className="text-2xl md:text-4xl font-light text-white/90 my-8">
          {section.content}
        </h2>
      );

    case "image":
      return (
        <div className="my-8">
          <img src={section.content} alt="" className="w-full rounded-xl" />
        </div>
      );

    case "video":
      return (
        <div className="my-8 aspect-w-16 aspect-h-9">
          <iframe
            src={
              section.content.includes("youtube.com")
                ? `https://www.youtube.com/embed/${
                    section.content.split("v=")[1]
                  }`
                : section.content.includes("vimeo.com")
                ? `https://player.vimeo.com/video/${section.content
                    .split("/")
                    .pop()}`
                : section.content
            }
            className="w-full rounded-xl"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );

    default:
      return null;
  }
};

export default BlogSectionRenderer;
