import {
  Music, // Custom Composition
  Volume2, // Sonic Logo
  Workflow, // SoundBoard
  Waveform, // Brand Track
  PlaySquare, // Ad Track
  Globe, // Website Sounds
  Smartphone, // App/UI Sounds
  Mic2, // Podcast Sounds
  Waves, // Soundscape
  Film, // TV/Film Composition
  // Where Icons
  Youtube, // Social Media
  Tv, // TV Ads
  MonitorPlay, // Online Ads
  Radio, // Radio
  Mic, // Podcasts
  PartyPopper, // Events
  Store, // In-Store
  Compass, // Immersive Brand Experience
  Video, // TV/Film
  Users, // Team
  Building2, // Studio
  Award, // Awards
  Star, // Featured
  Volume1 as Volume1Icon, // Brand Track
} from "lucide-react";

export const WHAT_ICONS = [
  { id: "soundboard", icon: Workflow, label: "SoundBoard" },
  { id: "sonic-logo", icon: Volume2, label: "Sonic Logo" },
  { id: "brand-track", icon: Music, label: "Brand Track" },
  { id: "ad-track", icon: PlaySquare, label: "Ad Track" },
  { id: "website-sounds", icon: Globe, label: "Website Sounds" },
  { id: "app-sounds", icon: Smartphone, label: "App/UI Sounds" },
  { id: "podcast-sounds", icon: Mic2, label: "Podcast Sounds" },
  { id: "soundscape", icon: Waves, label: "Soundscape" },
  { id: "tv-film-composition", icon: Film, label: "TV/Film Composition" },
];

export const WHERE_ICONS = [
  { id: "social-media", icon: Youtube, label: "Social Media" },
  { id: "tv-ads", icon: Tv, label: "TV Ads" },
  { id: "online-ads", icon: MonitorPlay, label: "Online Ads" },
  { id: "podcasts", icon: Mic, label: "Podcasts" },
  { id: "events", icon: PartyPopper, label: "Events" },
  { id: "in-store", icon: Store, label: "In-Store" },
  { id: "immersive", icon: Compass, label: "Immersive Brand Experience" },
  { id: "tv-film", icon: Video, label: "TV/Film" },
  { id: "radio", icon: Radio, label: "Radio" },
];

export const CREDITS_ICONS = [
  { id: "team", icon: Users, label: "Team" },
  { id: "studio", icon: Building2, label: "Studio" },
  { id: "awards", icon: Award, label: "Awards" },
  { id: "featured", icon: Star, label: "Featured" },
];

const ALL_ICONS = [...WHAT_ICONS, ...WHERE_ICONS, ...CREDITS_ICONS];
export const ICON_MAP = ALL_ICONS.reduce((acc, { id, icon }) => {
  acc[id] = icon;
  return acc;
}, {});
