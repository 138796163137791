import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Card, CardContent } from "../Components/ui/card";
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../firebase"; // Adjust path as needed
import Navigation from "../Components/Navigation";
import { audioManager } from "../utils/audioContext";
import SEO from "../Components/SEO";

// Custom hook for managing blog posts
const useBlogPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch posts
  const fetchPosts = async () => {
    try {
      const postsRef = collection(db, "blog-posts");
      const q = query(postsRef, orderBy("date", "desc"), limit(10));

      const querySnapshot = await getDocs(q);
      const fetchedPosts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate(),
      }));

      setPosts(fetchedPosts);
      setError(null);
    } catch (err) {
      console.error("Error fetching posts:", err);
      setError("Failed to fetch blog posts");
    } finally {
      setLoading(false);
    }
  };

  // Create a new post
  const createPost = async (postData, imageFile) => {
    try {
      let imageUrl;
      if (imageFile) {
        const storageRef = ref(
          storage,
          `blog-images/${Date.now()}_${imageFile.name}`
        );
        const uploadResult = await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(uploadResult.ref);
      }

      const newPost = {
        ...postData,
        featuredImage: imageUrl,
        date: Timestamp.fromDate(new Date()),
        published: false,
      };

      const docRef = await addDoc(collection(db, "blog-posts"), newPost);
      return docRef.id;
    } catch (err) {
      console.error("Error creating post:", err);
      throw new Error("Failed to create blog post");
    }
  };

  // Update an existing post
  const updatePost = async (postId, postData, imageFile) => {
    try {
      let imageUrl = postData.featuredImage;
      if (imageFile) {
        const storageRef = ref(
          storage,
          `blog-images/${Date.now()}_${imageFile.name}`
        );
        const uploadResult = await uploadBytes(storageRef, imageFile);
        imageUrl = await getDownloadURL(uploadResult.ref);
      }

      const postRef = doc(db, "blog-posts", postId);
      await updateDoc(postRef, {
        ...postData,
        ...(imageUrl && { featuredImage: imageUrl }),
        updatedAt: Timestamp.fromDate(new Date()),
      });
    } catch (err) {
      console.error("Error updating post:", err);
      throw new Error("Failed to update blog post");
    }
  };

  // Delete a post
  const deletePost = async (postId) => {
    try {
      await deleteDoc(doc(db, "blog-posts", postId));
    } catch (err) {
      console.error("Error deleting post:", err);
      throw new Error("Failed to delete blog post");
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return {
    posts,
    loading,
    error,
    fetchPosts,
    createPost,
    updatePost,
    deletePost,
  };
};

// Blog Page Component
const BlogPage = () => {
  const { posts, loading, error, createPost, updatePost, deletePost } =
    useBlogPosts();

  const navigate = useNavigate();
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);

  const getFirstImage = (sections) => {
    if (!Array.isArray(sections)) return null;

    const imageSection = sections.find(
      (section) => section.type === "image" && section.content
    );

    return imageSection ? imageSection.content : null;
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  if (error) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-white/70 text-center">
          <p>Error: {error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-6 py-2 bg-primary-green/90 rounded-full hover:scale-105 transition-transform"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO
        title="Blog"
        description="Insights, stories and updates from Hear Me Out Music Co. - Audio production and sound design studio"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] relative overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        {/* Gradient background effects */}
        <div className="fixed inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
        <div className="fixed inset-0 bg-gradient-to-b from-primary-purple/5 via-transparent to-primary-green/5" />

        {/* Header Section */}
        <div className="relative pt-32 pb-16">
          <div className="container max-w-7xl mx-auto px-4 sm:px-6">
            <div className="relative bg-[#111111]/80 backdrop-blur-xl rounded-2xl border border-white/5 p-8 sm:p-12">
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-4xl md:text-7xl font-light text-white mb-4"
              >
                Latest{" "}
                <span className="font-bold text-primary-green">Insights</span>
              </motion.h1>
              <p className="text-lg sm:text-xl text-white/70 max-w-2xl">
                Stories, updates and creative thoughts from our team
              </p>
            </div>
          </div>
        </div>

        {/* Blog Posts Grid */}
        <div className="container max-w-7xl mx-auto px-4 sm:px-6 py-12">
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
          >
            {loading
              ? // Loading skeleton
                [...Array(6)].map((_, i) => (
                  <div key={i} className="animate-pulse">
                    <div className="bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-xl h-[420px]" />
                  </div>
                ))
              : // Actual blog posts
                posts.map((post) => (
                  <motion.div
                    key={post.id}
                    variants={itemVariants}
                    className="group"
                    onClick={() => navigate(`/blog/${post.id}`)}
                  >
                    <Card className="h-full bg-[#111111]/80 backdrop-blur-xl border-white/5 hover:border-primary-green/20 transition-all duration-300 cursor-pointer overflow-hidden">
                      <CardContent className="p-0">
                        {/* Use featured image or first section image */}
                        {(post.featuredImage ||
                          getFirstImage(post.sections)) && (
                          <div className="relative h-48 overflow-hidden">
                            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#111111]/50" />
                            <img
                              src={
                                post.featuredImage ||
                                getFirstImage(post.sections)
                              }
                              alt={post.title}
                              className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
                            />
                          </div>
                        )}
                        {/* Fallback when no image is available */}
                        {!post.featuredImage &&
                          !getFirstImage(post.sections) && (
                            <div className="h-48 bg-gradient-to-br from-primary-purple/10 to-primary-green/10 flex items-center justify-center">
                              <div className="text-white/30 text-sm">
                                No image available
                              </div>
                            </div>
                          )}
                        <div className="p-6 space-y-4">
                          <div className="flex items-center space-x-2 text-sm text-white/60">
                            <span>{post.date.toLocaleDateString()}</span>
                            {post.category && (
                              <>
                                <span>•</span>
                                <span className="text-primary-green">
                                  {post.category}
                                </span>
                              </>
                            )}
                          </div>
                          <h2 className="text-xl font-bold text-white/90 group-hover:text-primary-green transition-colors duration-300">
                            {post.title}
                          </h2>
                          <p className="text-white/70 line-clamp-3">
                            {post.excerpt}
                          </p>
                          <div className="pt-2">
                            <span className="text-primary-green group-hover:text-primary-purple transition-colors duration-300">
                              Read More →
                            </span>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </motion.div>
                ))}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
