import React from "react";
import { motion } from "framer-motion";
import { Quote, ArrowUpRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const TestimonialSection = () => {
  const navigate = useNavigate();
  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        {/* Dot Pattern */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(111,87,165)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />

        {/* Large Quote Mark */}
        <div className="absolute -top-20 -left-20 text-primary-purple/5">
          <Quote size={400} />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          {/* Image Column */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="relative"
          >
            <div className="aspect-[4/3] rounded-2xl overflow-hidden">
              <img
                src="/images/sky-bingo.jpg"
                alt="Sky Bingo Office"
                className="w-full h-full object-cover"
              />
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-tr from-primary-dark/80 via-primary-dark/40 to-transparent" />
            </div>

            {/* Company Logo */}
            <motion.div
              className="absolute -bottom-8 -right-8 w-32 h-32 bg-gradient-to-b from-primary-green/5 via-primary-purple/5 to-transparent backdrop-blur-md rounded-2xl p-4 shadow-xl border border-white/10 hover:border-white/20 transition-all duration-300"
              initial={{ opacity: 0, scale: 0.8 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              viewport={{ once: true }}
            >
              <img
                src="/svg/Sky Bingo.svg"
                alt="Sky Bingo Logo"
                className="w-full h-full object-contain filter brightness-0 invert opacity-80"
              />
            </motion.div>
          </motion.div>

          {/* Content Column */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            {/* Quote Icon */}
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary-purple/20 mb-8">
              <Quote className="w-8 h-8 text-primary-purple" />
            </div>

            {/* Testimonial Text */}
            <blockquote className="text-3xl font-light text-white leading-relaxed mb-8">
              You chaps really took us on the journey. So knowledgeable and
              professional. Already looking forward to the next time we
              collaborate
            </blockquote>

            {/* Author Info */}
            <div className="mb-12">
              <p className="text-xl font-medium text-white">Mark Forster</p>
              <p className="text-white/60">
                Creative + Art Direction, Flutter PLC - Sky Bingo
              </p>
            </div>

            {/* CTA Button */}
            <motion.button
              className="relative z-10 group flex items-center gap-3 px-8 py-4 bg-primary-green hover:bg-primary-green/90 text-white rounded-full font-medium text-lg transition-all"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => {
                document
                  .getElementById("start")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              START YOUR SONIC BRANDING
              <ArrowUpRight className="w-5 h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
            </motion.button>

            {/* Decorative Line */}
            <div className="mt-12 h-px w-24 bg-gradient-to-r from-primary-purple/50 to-transparent" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
