import React, { useState, useRef, useEffect } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import {
  Volume2,
  Play,
  Tv,
  Radio,
  Podcast,
  Smartphone,
  PartyPopper,
  X,
  Pause,
  Maximize2,
  Minimize2,
} from "lucide-react";

const ServiceCard = ({ service, audioManager }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const videoRef = useRef(null);
  const containerRef = useRef(null);
  const progressBarRef = useRef(null);
  const isPortraitVideo = service.isPortrait;

  const handleFlip = () => {
    audioManager.playSound("click", 0);
    setIsFlipped(!isFlipped);
    if (!isFlipped) {
      setIsPlaying(true);
      videoRef.current?.play();
    } else {
      setIsPlaying(false);
      videoRef.current?.pause();
    }
  };

  const handleClose = () => {
    if (isFullscreen) {
      toggleFullscreen().then(() => {
        setIsPlaying(false);
        videoRef.current?.pause();
        setIsFlipped(false);
      });
    } else {
      setIsPlaying(false);
      videoRef.current?.pause();
      setIsFlipped(false);
    }
  };

  const handleVideoClick = (e) => {
    if (e.target === progressBarRef.current) return;

    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
    setIsPlaying(!isPlaying);
  };

  const toggleFullscreen = async () => {
    if (!isFullscreen) {
      try {
        if (containerRef.current.requestFullscreen) {
          await containerRef.current.requestFullscreen();
        } else if (containerRef.current.webkitRequestFullscreen) {
          await containerRef.current.webkitRequestFullscreen();
        } else if (containerRef.current.msRequestFullscreen) {
          await containerRef.current.msRequestFullscreen();
        }
        setIsFullscreen(true);
      } catch (error) {
        console.error("Fullscreen error:", error);
      }
    } else {
      try {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          await document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          await document.msExitFullscreen();
        }
        setIsFullscreen(false);
      } catch (error) {
        console.error("Exit fullscreen error:", error);
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        Boolean(
          document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.msFullscreenElement
        )
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateProgress = () => {
      setProgress((video.currentTime / video.duration) * 100);
    };

    video.addEventListener("timeupdate", updateProgress);
    return () => video.removeEventListener("timeupdate", updateProgress);
  }, []);

  const handleProgressBarClick = (e) => {
    const progressBar = progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const width = rect.width;
    const percentage = (x / width) * 100;
    const time = (percentage / 100) * videoRef.current.duration;
    videoRef.current.currentTime = time;
  };

  return (
    <motion.div className="relative w-full">
      <div
        className={`w-full ${
          isFullscreen
            ? isPortraitVideo
              ? "h-screen"
              : "aspect-video"
            : "aspect-[4/3]"
        } relative`}
      >
        <motion.div
          className={`absolute inset-0 transition-all duration-500 preserve-3d ${
            isFlipped ? "rotate-y-180" : ""
          }`}
        >
          {/* Front of card */}
          <div className="absolute inset-0 backface-hidden">
            <div
              className="h-full group relative overflow-hidden rounded-lg p-6 transition-all duration-500
                bg-[#111111]/80 backdrop-blur-xl border border-white/5 flex flex-col"
              style={{
                background: `linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))`,
              }}
            >
              <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />

              <div className="relative z-10 flex flex-col h-full">
                <div className="text-primary-green mb-4">{service.icon}</div>
                <h3 className="text-xl font-bold mb-3 text-white/90">
                  {service.title}
                </h3>
                <p className="text-white/70 mb-4">{service.description}</p>

                <motion.button
                  whileHover={{ scale: service.videoUrl ? 1.05 : 1 }}
                  whileTap={{ scale: service.videoUrl ? 0.95 : 1 }}
                  onClick={handleFlip}
                  className={`mt-auto px-6 py-2 rounded-full text-sm font-medium
                        ${
                          service.videoUrl
                            ? "bg-primary-green/90 hover:scale-105 active:scale-95"
                            : "bg-gray-500/50 cursor-not-allowed"
                        }
                        backdrop-blur-sm border border-primary-green/20
                        transition-all duration-300
                        inline-flex items-center gap-2 w-fit`}
                >
                  {service.videoUrl ? "Watch Demo" : "Coming Soon"}
                  {service.videoUrl && <Play className="w-4 h-4" />}
                </motion.button>
              </div>
            </div>
          </div>

          {/* Back of card */}
          <div
            ref={containerRef}
            className="absolute inset-0 rotate-y-180 backface-hidden"
          >
            <div className="h-full relative overflow-hidden rounded-lg bg-[#111111]/80 backdrop-blur-xl border border-white/5">
              <div className="w-full h-full flex items-center justify-center bg-black">
                <video
                  ref={videoRef}
                  className={`${
                    isFullscreen
                      ? isPortraitVideo
                        ? "h-full"
                        : "w-full"
                      : "h-full"
                  } object-contain cursor-pointer`}
                  playsInline
                  onClick={handleVideoClick}
                  src={service.videoUrl}
                />
              </div>

              {/* Video Controls Overlay */}
              <div
                className={`absolute inset-0 bg-black/30 flex items-center justify-center cursor-pointer transition-opacity duration-300 ${
                  isPlaying ? "opacity-0 hover:opacity-100" : "opacity-100"
                }`}
                onClick={handleVideoClick}
              >
                {!isPlaying && (
                  <motion.div
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    className="w-16 h-16 rounded-full bg-primary-green/90 flex items-center justify-center"
                  >
                    <Play className="w-8 h-8 text-white" />
                  </motion.div>
                )}
              </div>

              {/* Control Buttons */}
              <div className="absolute top-4 right-4 flex gap-2 z-10">
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={toggleFullscreen}
                  className="p-2 rounded-full bg-primary-green/90 text-white hover:bg-primary-green transition-colors"
                >
                  {isFullscreen ? (
                    <Minimize2 className="w-5 h-5" />
                  ) : (
                    <Maximize2 className="w-5 h-5" />
                  )}
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleClose}
                  className="p-2 rounded-full bg-primary-green/90 text-white hover:bg-primary-green transition-colors"
                >
                  <X className="w-5 h-5" />
                </motion.button>
              </div>

              {/* Progress Bar */}
              <div
                ref={progressBarRef}
                onClick={handleProgressBarClick}
                className="absolute bottom-0 left-0 right-0 h-2 bg-white/20 cursor-pointer group"
              >
                <div
                  className="h-full bg-primary-green relative"
                  style={{ width: `${progress}%` }}
                >
                  <div className="absolute right-0 top-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

const ServicesGrid = ({ audioManager }) => {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  // Adjusted transforms with higher starting position for mobile
  const logoY = useTransform(scrollYProgress, [0, 1], ["-50%", "30%"]); // Changed from ["0%", "50%"]
  const logoScale = useTransform(scrollYProgress, [0, 1], [1.5, 1.8]); // Increased initial scale
  const logoRotate = useTransform(scrollYProgress, [0, 1], [-5, 10]); // Adjusted rotation range
  const noiseY = useTransform(scrollYProgress, [0, 1], ["-20%", "0%"]); // Reversed noise movement

  // Check if device is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const services = [
    {
      title: "Sonic Branding",
      icon: <Volume2 className="w-8 h-8" />,
      description:
        "Create distinctive audio identities that resonate with your audience and leave a lasting impression across all touchpoints.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_6349df8b21d648afafa6e08f1d9f1904/1080p/mp4/file.mp4",
    },
    {
      title: "Advertising",
      icon: <Play className="w-8 h-8" />,
      description:
        "Craft compelling audio content for your advertising campaigns that captures attention and drives engagement.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_74d9aa09b299460ba04e75dc26c30f66/1080p/mp4/file.mp4",
    },
    {
      title: "Events",
      icon: <PartyPopper className="w-8 h-8" />,
      description:
        "Create immersive sonic experiences for live events, conferences, and brand activations.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_1b93487af1064a9eb00c5ab688ee1deb/720p/mp4/file.mp4",
      isPortrait: true,
    },
    {
      title: "TV & Film",
      icon: <Tv className="w-8 h-8" />,
      description:
        "Professional sound design and music production for television, film, and video content.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_49aa6a5495f94941a623451e7d639e74/1080p/mp4/file.mp4",
    },
    {
      title: "Podcasts",
      icon: <Podcast className="w-8 h-8" />,
      description:
        "End-to-end podcast production services including sound design, editing, and distribution.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_d9096cf77a9e416da0ad0b22a9160c73/1080p/mp4/file.mp4",
    },
    {
      title: "Apps/UI",
      icon: <Smartphone className="w-8 h-8" />,
      description:
        "Design and implement intuitive sonic feedback for applications and user interfaces.",
      videoUrl: null, // or undefined
      comingSoon: true, // optional flag we can use
    },
  ];

  return (
    <div className="pt-24 sm:pt-32 relative" ref={containerRef}>
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-transparent to-[#111111] z-10" />

        <motion.div
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{
            opacity: 0.3,
          }}
          style={{
            y: isMobile ? logoY : 0,
            scale: isMobile ? logoScale : 1,
            rotate: isMobile ? logoRotate : [0, 3, -3, 0],
          }}
          transition={{
            opacity: { duration: 2 },
            rotate: {
              repeat: Infinity,
              duration: 20,
              ease: "easeInOut",
            },
          }}
          className="absolute inset-0 z-0"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src="/HEAR ME OUT - Logo - Purple.svg"
              alt=""
              className="w-[200%] md:w-[150%] max-w-none opacity-50 -mt-[25%]" // Adjusted size and added margin-top
              style={{ filter: "blur(2px)" }}
            />
          </div>
        </motion.div>

        <motion.div
          style={{
            y: isMobile ? noiseY : 0,
          }}
          className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5 z-20"
        />
      </div>

      {/* Content */}
      <div className="relative z-30 max-w-7xl mx-auto px-4 sm:px-6 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="text-center max-w-4xl mx-auto mb-16"
        >
          <h1 className="text-4xl md:text-7xl font-light mb-6 text-white/90">
            Our <span className="font-bold">Services</span>
          </h1>
          <p className="text-lg sm:text-xl text-white/70">
            Elevate your brand through strategic sound design and audio
            experiences
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              service={service}
              audioManager={audioManager}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default ServicesGrid;
