import React from "react";
import { motion, useInView } from "framer-motion";
import { Volume2, Radio, Music2, Play } from "lucide-react";

const benefits = [
  {
    title: "Increased Engagement",
    description:
      "See a trackable, measurable impact on business goals with strategic use of sound.",
    icon: Volume2,
    color: "from-primary-purple/20 to-primary-purple/5",
  },
  {
    title: "Brand Recognition",
    description:
      "Have a brand boasting a memorable, coherent and consistent audio identity that screams personality.",
    icon: Music2,
    color: "from-primary-green/20 to-primary-green/5",
  },
  {
    title: "Individuality",
    description:
      "Stop blending in like other brands that use the same old stock music. Have a sound that is as unique as the brand.",
    icon: Radio,
    color: "from-primary-purple/20 to-primary-green/5",
  },
];

const stats = [
  {
    value: "+46%",
    image:
      "https://static.wixstatic.com/media/11062b_60c703945bb843488ec28b3285cdb554~mv2.jpg/v1/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Teen%20Gay%20Couple.jpg",
    description:
      "A study conducted by Man Made Music suggests music can improve brand recognition by 46%. Millennials, in particular, are more likely to associate emotions and memories with sounds.",
    source: "Man Made Music",
  },
  {
    value: "+96%",
    image:
      "https://static.wixstatic.com/media/11062b_f70ef06ad23f4e53a9a9f7b5abd58643~mv2.jpg/v1/crop/x_1228,y_0,w_3244,h_3800/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Listening%20to%20Music.jpg",
    description:
      "Consumers are 96% more likely to recall a business if it uses brand-congruent music.",
    source: "Prof Adrian North, Leicester University",
  },
  {
    value: "+83%",
    image:
      "https://static.wixstatic.com/media/nsplsh_e086b9525b5c4d55a295f3df77fe2c1a~mv2.jpg/v1/crop/x_624,y_0,w_3412,h_4000/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Vitaly%20Gariev.jpg",
    description:
      "83% of millennials feel more connected to a brand when music or sound is integrated in a way that reflects the brand's personality and values.",
    source: "Pandora",
  },
  {
    value: "8x",
    image:
      "https://static.wixstatic.com/media/nsplsh_39ebca9cd941490d845bcb17a3bc39f5~mv2.jpg/v1/crop/x_0,y_408,w_2529,h_2962/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Nik.jpg",
    description:
      "TikTok's own research shows that branded content with a custom sound or music is 8x more likely to be remembered by users compared to content with no sound or generic stock music.",
    source: "TikTok",
  },
];

const StatCard = ({ stat, index }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
      className="bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-lg overflow-hidden group"
    >
      <div className="relative">
        <img
          src={stat.image}
          alt={stat.description}
          className="w-full aspect-square object-cover group-hover:scale-105 transition-transform duration-500"
        />
        <div
          className="absolute inset-0"
          style={{
            background:
              "linear-gradient(135deg, rgba(111,87,165,0.2), transparent 50%, rgba(1,178,128,0.2))",
          }}
        />
      </div>

      <div className="p-6">
        <div className="text-5xl font-bold text-primary-purple mb-4">
          {stat.value}
        </div>
        <p className="text-white/70 text-base mb-4">{stat.description}</p>
        <span className="text-primary-green text-sm font-medium">
          {stat.source}
        </span>
      </div>
    </motion.div>
  );
};

const SoundboardIntro = () => {
  return (
    <div className="relative pt-32">
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        <div className="absolute inset-0 " />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="absolute inset-0"
          // style={{
          //   background:
          //     "linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
          // }}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 relative z-10">
        {/* Hero */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-24"
        >
          <h1 className="text-4xl md:text-7xl font-light text-white/90 mb-6">
            OUR UNIQUE CREATIVE PROCESS TO
            <span className="font-bold block mt-2">
              HELP BRANDS SOUND BETTER THAN THE COMPETITION
            </span>
          </h1>
        </motion.div>

        {/* What is SoundBoard */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-24"
        >
          <div className="bg-[#6F56A5]/30 backdrop-blur-xl border border-white/5 rounded-lg p-12 relative overflow-hidden">
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />

            <h2 className="text-4xl md:text-6xl font-bold text-white/90 text-center mb-8">
              A SOUNDBOARD IS A MOOD BOARD FOR MUSIC.
            </h2>

            <div className="relative space-y-6">
              <p className="text-lg text-white/70 text-center max-w-4xl mx-auto">
                Creativity and strategy are at the heart of every SoundBoard,
                and as such, each one is completely unique to every brand. Every
                time a brand uses sound it should be from a strategic position
                to support the greater marketing strategy.
              </p>
              <p className="text-lg text-white/70 text-center max-w-4xl mx-auto">
                If you are not being strategic with sound then you are wasting
                money, the SoundBoard makes sure this never happens.
              </p>
            </div>
          </div>
        </motion.div>

        {/* Benefits */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mb-24"
        >
          <h2 className="text-4xl md:text-6xl font-bold text-white/90 text-center mb-16">
            THE SOUNDBOARD BENEFITS:
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={benefit.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className={`relative group bg-[#111111]/80 backdrop-blur-xl border border-white/5 p-8 rounded-lg overflow-hidden`}
                whileHover={{ scale: 1.02 }}
              >
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />

                <motion.div
                  className="mb-6"
                  initial={{ scale: 1 }}
                  whileHover={{ scale: 1.1, rotate: 5 }}
                  transition={{ type: "spring", stiffness: 300 }}
                >
                  <benefit.icon className="w-12 h-12 text-primary-green" />
                </motion.div>

                <h3 className="text-xl font-bold text-white/90 mb-4">
                  {benefit.title}
                </h3>

                <p className="text-white/70 relative z-10">
                  {benefit.description}
                </p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-24">
          {stats.map((stat, index) => (
            <StatCard key={index} stat={stat} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SoundboardIntro;
