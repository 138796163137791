import StatsSection from "../Components/StatsSection";
import Navigation from "../Components/Navigation";
import { audioManager } from "../utils/audioContext";
import { useState } from "react";
import EmotionalConnectionSection from "../Components/EmotionalConnectionSection";
import AudioConsumptionTrends from "../Components/AudioConsumptionTrends";
import AnimatedBackground from "../Components/AnimatedBackground";
import SEO from "../Components/SEO";

const ROI = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  return (
    <>
      <SEO
        title="ROI"
        description="Understand the value and impact of professional audio production and sound design for your brand"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />
        <AnimatedBackground>
          <main className="relative pt-20 ">
            <section id="stats">
              <StatsSection />
            </section>
            <section id="emotional-connection">
              <EmotionalConnectionSection />
            </section>
            <section id="audio-consumption-trends">
              <AudioConsumptionTrends />
            </section>
          </main>
        </AnimatedBackground>
      </div>
    </>
  );
};

export default ROI;
