export const clientsData = [
  {
    id: "elliot-simpson-lost-focus",
    slug: "lost-focus",
    title: "Elliot Simpson - 'Lost Focus'",
    type: "tv-commercial",
    sections: [
      {
        type: "brand",
        data: {
          logo: "/svg/hear-me-out-white-purple.svg", // Path to brand logo SVG
        },
      },
      {
        type: "video",
        data: {
          videoUrl:
            "https://video.wixstatic.com/video/ac6faa_ac0751b3c1ac4ebc8abe223f55888492/1080p/mp4/file.mp4",
          posterImage:
            "https://static.wixstatic.com/media/ac6faa_47174573ae2941cf87895fc1eab77f4e~mv2.jpg/v1/fill/w_555,h_289,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Lost%20Focus%20Final%20Draft%20v2_00_00_53_01_Still006.jpg",
        },
      },
      {
        type: "overview",
        data: {
          brief:
            "Director and Filmmaker Elliot Simpson was looking for a sound partner to bring a notepad concept to life.",
          overview:
            "A film about digital detoxing that doubles as a future thinking car Ad campaign.",
        },
      },
      {
        type: "feedback",
        data: {
          whatTheyLoved:
            "Elliot had a clear vision and concept for the soundtrack to accompany this short film. It needed to feel mysterious, 'otherworldly' and represent the uncertainty of our future as a human race with the relationship we currently have with digital media. The energy needed to fit the pace and mood of the scenes presented as well as create a journey in itself sonically.",
          whatWeLoved:
            "This was a bespoke commission and license so we had to make Elliot feel like the process and results were worth the additional investment above and beyond licensing pre-existing music. It allowed freedom to explore how the music could weave between scenes and create continuity that pre-existing music limits and restrains.",
          result:
            "Bespoke commissions are one of our favourite pieces of work because of the fact we get the opportunity to work so closely with creative teams to bring ideas to life and push barriers further than pre-existing music libraries ever can.",
        },
      },
      {
        type: "imageGrid",
        data: {
          images: [
            "https://static.wixstatic.com/media/ac6faa_72c2ac66daf24621963f880f239f2c05~mv2.jpg/v1/fill/w_184,h_95,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Lost%20Focus%20Final%20Draft%20v2_00_00_47_07_Still005.jpg",
            "https://static.wixstatic.com/media/ac6faa_6e305efa52f045408ea3490b9f4c3a2a~mv2.jpg/v1/fill/w_184,h_95,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Lost%20Focus%20Final%20Draft%20v2_00_00_37_20_Still004.jpg",
            "https://static.wixstatic.com/media/ac6faa_e9a8e946241d4d30b94e9383d603611f~mv2.jpg/v1/fill/w_184,h_95,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Lost%20Focus%20Final%20Draft%20v2_00_00_13_21_Still003.jpg",
          ],
        },
      },
    ],
  },
  {
    id: "sky-bingo",
    slug: "sky-bingo",
    title: "Sky Bingo - 'All Fun, No Fuss'",
    subtitle: "Sonic Branding",
    type: "tv-commercial",
    sections: [
      {
        type: "brand",
        data: {
          logo: "/svg/Sky Bingo.svg", // Path to brand logo SVG
        },
      },
      {
        type: "video",
        data: {
          videoUrl:
            "https://video.wixstatic.com/video/ac6faa_7fcc0b0921f448188720f42dbeed34a5/1080p/mp4/file.mp4",
          posterImage:
            "https://static.wixstatic.com/media/ac6faa_ae90a859df9c483095fcceb46f412ee1~mv2.png/v1/fill/w_535,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Screenshot%202024-02-17%20at%2016_01_26.png",
        },
      },
      {
        type: "overview",
        data: {
          brief:
            "Sky Bingo knew they needed a solid Sonic Strategy to make sure they were getting the most ROI with their new campaign.",
          overview:
            "They came to us right from the start to make sure their Sonic Branding was as considered and consistent as their visual branding.",
        },
      },
      {
        type: "feedback",
        data: {
          whatTheyLoved:
            "Imagine our thrill when Sky Bingo approached us to pioneer the very first sound of Sky Bingo! It felt like we hit the jackpot! The task was clear: craft a complete Sonic Branding Strategy for the brand, complete with a fresh Brand Track and a dynamic Sonic Logo. But that's not all – they entrusted us with launching this exciting new sound through a nationwide ad campaign set to dazzle the UK across all media channels.",
          whatWeLoved:
            "Once we agreed on the right direction we started to create the concept of 'All Fun, No Fuss,' injecting a competitive edge, and carving out a distinctive space for Sky Bingo among its competitors. This vision demanded a Brand Track that exuded calm confidence. With the Brand Track solidified – akin to a visual guideline, but in sound – we extracted all the right elements to craft the Sonic Logo and the Ad Track. Each step brought us closer to bringing the essence of Sky Bingo to life.",
          result:
            "Nothing beats the feeling when the whole project comes together and you get that warm fuzzy feeling of a cohesive, well structured and emotionally engaging sonic branding campaign. If you listen closely to the Ad Campaign's final moment - there, you'll catch the unmistakable tones of the Sonic Logo seamlessly integrated. As the track unfolds, you'll notice the unmistakable motifs from the Brand Track we crafted. And at the outset, observe how we deftly adapted the Brand Track to mirror the brand's desired image amidst the chaos. So, did we achieve that cohesive Sonic Branding journey with Sky Bingo? Take a listen to the Brand Track we delivered and the Sonic Logo, each standing tall on their own, yet together forming the vibrant auditory landscape of Sky Bingo.👇🏼",
        },
      },
      {
        type: "imageGrid",
        data: {
          images: [
            "https://static.wixstatic.com/media/ac6faa_159822a6a8f9409caa1478754df28a36~mv2.png",
            "https://static.wixstatic.com/media/ac6faa_e85e6872f058426ea421c9441c9a9b12~mv2.png",
            "https://static.wixstatic.com/media/ac6faa_a74810f6020d487fbc8adf8b3c5bbb51~mv2.png",
          ],
        },
      },
    ],
  },

  {
    id: "tesco-eid-campaign",
    slug: "tesco-eid",
    title: "Tesco - Food Love Stories - Eid Campaign",
    type: "sonic-branding",
    sections: [
      {
        type: "video",
        data: {
          videoUrl:
            "https://video.wixstatic.com/video/ac6faa_37bf4c1537da4258851bd8a9a0feae78/1080p/mp4/file.mp4",
          posterImage:
            "https://static.wixstatic.com/media/ac6faa_c1bb6cbb240245b183c64ef1cef20f25~mv2.jpg",
        },
      },
      {
        type: "brand",
        data: {
          logo: "/svg/Tesco.svg", // You'll need to provide the actual logo path
        },
      },
      {
        type: "overview",
        data: {
          brief:
            "Tesco needed a rework of the Rudimental track 'Feel The Love' to work in Punjabi to celebrate the end of Eid.",
          overview:
            "A cross-cultural mission that captured the spirit of inclusivity and diversity that Tesco embodies.",
        },
      },

      {
        type: "feedback",
        data: {
          whatTheyLoved:
            "The creative team behind Tesco were struggling to find a way to combine the idea of singer Leo Kalyan singing a popular British pop track in Punjabi whilst also reflecting their existing sonic branding. We had to figure out a way how to make it sound authentic, suitable for all ages, and translate the english lyrics into punjabi. our thrill when Sky Bingo approached us to pioneer the very first sound of Sky Bingo! It felt like we hit the jackpot! The task was clear: craft a complete Sonic Branding Strategy for the brand, complete with a fresh Brand Track and a dynamic Sonic Logo. But that's not all – they entrusted us with launching this exciting new sound through a nationwide ad campaign set to dazzle the UK across all media channels.",
          whatWeLoved:
            "Punjabi music is known for its energetic and distinctive instrumentation. Our approach was to create a large palette of sounds that captured the upbeat and family-friendly tempo of Punjabi music to aligning with Tesco's all-inclusive brand approach. After much research we subtly wove in traditional instruments like the Dhol (drums) and Tumbi (stringed instrument). Another way we subliminally supported the on screen action lies in the transformation of Kalyan's vocals. The song starts recorded in an intimate, close-knit ambiance akin to a home recording (more subliminal referencing to the visual), but as the camera pans upward to the boy's gaze, they expand into a vast, celestial soundscape. we agreed on the right direction we started to create the concept of 'All Fun, No Fuss,' injecting a competitive edge, and carving out a distinctive space for Sky Bingo among its competitors. This vision demanded a Brand Track that exuded calm confidence. With the Brand Track solidified – akin to a visual guideline, but in sound – we extracted all the right elements to craft the Sonic Logo and the Ad Track. Each step brought us closer to bringing the essence of Sky Bingo to life.",
          result:
            "I absolutely love working with Hear Me Out. They've got an emotionally and musically nuanced way of working that means he consistently delivers catchy, dynamic and polished music to a brief that never fails to impress. On top of their compositional skills they are also a dream to work with - quick, reliable, great communicators and dedicated to delivering superb quality. beats the feeling when the whole project comes together and you get that warm fuzzy feeling of a cohesive, well structured and emotionally engaging sonic branding campaign. If you listen closely to the Ad Campaign's final moment - there, you'll catch the unmistakable tones of the Sonic Logo seamlessly integrated. As the track unfolds, you'll notice the unmistakable motifs from the Brand Track we crafted. And at the outset, observe how we deftly adapted the Brand Track to mirror the brand's desired image amidst the chaos. So, did we achieve that cohesive Sonic Branding journey with Sky Bingo? Take a listen to the Brand Track we delivered and the Sonic Logo, each standing tall on their own, yet together forming the vibrant auditory landscape of Sky Bingo.👇🏼",
        },
      },

      {
        type: "imageGrid",
        data: {
          images: [
            "https://static.wixstatic.com/media/ac6faa_c1bb6cbb240245b183c64ef1cef20f25~mv2.jpg",
            "https://static.wixstatic.com/media/ac6faa_d611f859788d46ae827ececfcef46ba1~mv2.jpg",
            "https://static.wixstatic.com/media/ac6faa_d6abe06a69af45ebb7a949e56b0b64ba~mv2.jpg",
            "https://static.wixstatic.com/media/ac6faa_27515481a9d14d52862b626b77202218~mv2.jpg",
            "https://static.wixstatic.com/media/ac6faa_44072767ee4649c6a4499026c886437f~mv2.jpg",
          ],
        },
      },
    ],
  },
];
