import { React, useState } from "react";
import Navigation from "../Components/Navigation";
import { audioManager } from "../utils/audioContext";
import ClientWorkLayout from "../Components/ClientWorkLayout";
import ClientGrid from "../Components/ClientGrid";
import { clientsData } from "../utils/clientsData";
import WorkShowcase from "../Components/WorkShowcase";
import SEO from "../Components/SEO";

const ClientWork = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  return (
    <>
      <SEO
        title="Client Work"
        description="Explore our portfolio of audio production and sound design projects for leading brands"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />
        <main className="relative">
          <ClientWorkLayout>
            <WorkShowcase clients={clientsData} />
          </ClientWorkLayout>
        </main>
      </div>
    </>
  );
};

export default ClientWork;
