import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { Play, Pause } from "lucide-react";
import Navigation from "./Navigation";
import { useNavigate } from "react-router-dom";

const ProcessStep = ({ step, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const handlePlayPause = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
      className="relative"
    >
      {/* Animated line connector */}
      <motion.div
        initial={{ scaleX: 0 }}
        whileInView={{ scaleX: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8, delay: index * 0.2 }}
        className={`absolute top-0 ${
          index % 2 === 0 ? "-left-20" : "-right-20"
        } w-40 h-px bg-gradient-to-r ${
          index % 2 === 0
            ? "from-transparent to-primary-green"
            : "from-primary-green to-transparent"
        }`}
      />

      <div
        className={`flex flex-col ${
          index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
        } gap-12 items-center bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-lg p-8`}
      >
        <div className="w-full md:w-1/2 space-y-6">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="text-7xl font-bold text-primary-green"
          >
            {step.number}
          </motion.div>

          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="text-4xl font-bold mb-4 text-white/90"
          >
            {step.title}
          </motion.h3>

          {step.description.split("\n").map((line, i) => (
            <motion.p
              key={i}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: i * 0.1 }}
              className="text-lg text-white/70"
            >
              {line}
            </motion.p>
          ))}

          {step.additionalLink && (
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block text-lg text-primary-green hover:text-primary-green/80 transition-colors cursor-pointer"
              onClick={() => navigate("/soundboard")}
            >
              {step.additionalLink}
            </motion.a>
          )}
        </div>

        <div className="w-full md:w-1/2 aspect-video relative rounded-lg overflow-hidden group">
          <video
            ref={videoRef}
            src={step.videoUrl}
            poster={step.videoUrl.replace("/mp4/file.mp4", "/jpg/file.jpg")}
            className="w-full h-full object-cover"
            playsInline
            onClick={handlePlayPause}
          />

          {!isPlaying && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="absolute inset-0 flex items-center justify-center bg-black/30 group-hover:bg-black/20 transition-colors"
            >
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handlePlayPause}
                className="w-16 h-16 bg-primary-green/90 rounded-full flex items-center justify-center group"
              >
                {isPlaying ? (
                  <Pause className="w-8 h-8 text-white" />
                ) : (
                  <Play className="w-8 h-8 text-white translate-x-0.5" />
                )}
              </motion.button>
            </motion.div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

const ProcessSection = ({ audioManager }) => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const navigate = useNavigate();
  const processSteps = [
    {
      number: "01",
      title: "Onboarding",
      description:
        "Have your brand elevated through a cost-effective, innovative audio solution that sparks excitement. You focus on your brand, we'll focus on making it sound great.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_e6710c7c90fe4b169afbc8ba983a91c7/1080p/mp4/file.mp4",
    },
    {
      number: "02",
      title: "SoundBoard",
      description: `We create custom SoundBoards for every client.\nA mood board for music.\nWe dive deep into your brand's DNA to collate a customised selection of sound samples and reference tracks tailored to your brand's ethos and values.`,
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_48ab3a80d7994630a4a8336818c63823/1080p/mp4/file.mp4",
      additionalLink: "Click HERE for a deeper dive into SoundBoards.",
    },
    {
      number: "03",
      title: "How do you use a SoundBoard?",
      description:
        "You might have questions about where would you apply the SoundBoard and in what context. Here are the most common examples used by brands that help them cut through the noise and be bold market leaders.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_dd8c92e8d32e4f648bfa316f32da5343/1080p/mp4/file.mp4",
    },
    {
      number: "04",
      title: "Brand Track",
      description:
        "We offer an accessible team. Work direct with the sonic branding musical creatives, we won't channel you through a third party.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_5f13e49cdd3d4e52ae767941ca5384e8/1080p/mp4/file.mp4",
    },
    {
      number: "05",
      title: "Sound Logo",
      description:
        "Enjoy your sonic branding signature becoming synonymous with your identity, making your audience feel connected and engaged.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_6f6c381cf5c74c4fbcf722f816bdbec1/1080p/mp4/file.mp4",
    },
    {
      number: "06",
      title: "Additional Touchpoints & Offboarding Files",
      description:
        "You may want Product Sounds or App Sounds, and Advertising Soundtrack or Podcast Audio Toolkit. Once we have the Sonic Strategy we can build thousands of Assets across a brand's lifetime.",
      videoUrl:
        "https://video.wixstatic.com/video/ac6faa_c7a050e5a78f4eb9b57a386aa48d06fb/1080p/mp4/file.mp4",
    },
  ];
  return (
    <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
      <Navigation
        audioManager={audioManager}
        isAudioInitialized={isAudioInitialized}
        setIsAudioInitialized={setIsAudioInitialized}
      />

      <div className="relative bg-[#111111] text-white py-24">
        {/* Background elements */}
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute inset-0"
            style={{
              background:
                "linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
            }}
          />
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative py-24">
          <motion.h2
            initial={{ opacity: 0, y: -20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center text-4xl md:text-6xl mb-16 font-bold text-white/90"
          >
            OUR PROCESS
          </motion.h2>

          <div className="space-y-20">
            {processSteps.map((step, index) => (
              <ProcessStep
                key={step.number}
                step={step}
                index={index}
                audioManager={audioManager}
              />
            ))}
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mt-32 space-y-8"
          >
            <p className="text-2xl max-w-3xl mx-auto text-white/90">
              Don't risk failing to forge emotional connections with your
              audience, leading to weak brand loyalty.
            </p>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-primary-purple hover:bg-primary-purple/90 text-white px-8 py-4 rounded-full text-lg font-medium transition-colors"
              onClick={() => navigate("/contact")}
            >
              Let's get started!
            </motion.button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default ProcessSection;
