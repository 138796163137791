import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Calendar, ArrowUpRight, Phone, Mail, Clock, X } from "lucide-react";

const BookCallCTA = () => {
  const [showWidget, setShowWidget] = useState(false);

  const handleBookClick = () => setShowWidget(true);
  const handleCloseWidget = () => setShowWidget(false);
  const handleHover = () => {
    // Add your hover logic here if needed
  };
  const handleClick = () => {
    // Add your click logic here if needed
  };

  return (
    <>
      <section className="relative pt-16 pb-16">
        {/* Background Elements */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-transparent to-[#111111] " />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
          <div className="grid lg:grid-cols-2 gap-8 items-center">
            {/* Left Column - Main CTA */}
            <div className="relative">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
                className="bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-2xl p-8 sm:p-12"
              >
                <h2 className="text-3xl sm:text-4xl font-light text-white mb-6">
                  Ready to Make Your Brand
                  <br />
                  <span className="font-bold">Sound Exceptional?</span>
                </h2>

                <p className="text-white/70 text-lg mb-8">
                  Schedule a free consultation to discuss how we can elevate
                  your brand's sonic identity.
                </p>

                <div className="space-y-4 mb-8">
                  {[
                    { icon: Clock, text: "30-minute discovery call" },
                    {
                      icon: Phone,
                      text: "Personalized sound strategy discussion",
                    },
                    { icon: Calendar, text: "Flexible scheduling options" },
                  ].map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: index * 0.1 }}
                      className="flex items-center gap-3 text-white/80"
                    >
                      <item.icon className="w-5 h-5 text-primary-green" />
                      <span>{item.text}</span>
                    </motion.div>
                  ))}
                </div>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onHoverStart={handleHover}
                  onClick={handleBookClick}
                  className="w-full sm:w-auto px-8 py-4 bg-primary-green text-white rounded-full font-medium text-lg 
                           hover:bg-primary-green/90 transition-all inline-flex items-center justify-center gap-2"
                >
                  Book Your Free Call
                  <ArrowUpRight className="w-5 h-5" />
                </motion.button>
              </motion.div>
            </div>

            {/* Right Column - Contact Info */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="lg:pl-8"
            >
              <div className="bg-[#111111]/60 backdrop-blur-lg border border-white/5 rounded-2xl p-8">
                <h3 className="text-2xl font-bold text-white mb-6">
                  Other Ways to Connect
                </h3>

                <div className="space-y-6">
                  <a
                    href="mailto:luke@hearmeout.com"
                    onMouseEnter={handleHover}
                    onClick={handleClick}
                    className="block group"
                  >
                    <p className="text-white/50 mb-1 text-sm">Email Us</p>
                    <div className="flex items-center gap-2 text-white group-hover:text-primary-green transition-colors">
                      <Mail className="w-5 h-5" />
                      <span>contact@hearmeout.com</span>
                    </div>
                  </a>

                  <a
                    href="tel:+4407851 487782"
                    onMouseEnter={handleHover}
                    onClick={handleClick}
                    className="block group"
                  >
                    <p className="text-white/50 mb-1 text-sm">Call Direct</p>
                    <div className="flex items-center gap-2 text-white group-hover:text-primary-green transition-colors">
                      <Phone className="w-5 h-5" />
                      <span>+44 07851 487782</span>
                    </div>
                  </a>

                  <div>
                    <p className="text-white/50 mb-1 text-sm">Business Hours</p>
                    <div className="flex items-center gap-2 text-white">
                      <Clock className="w-5 h-5" />
                      <span>Mon-Fri: 9:00 AM - 6:00 PM GMT</span>
                    </div>
                  </div>
                </div>

                {/* Quick Response Promise */}
                <div className="mt-8 p-4 bg-white/5 rounded-lg">
                  <p className="text-white/90 text-sm">
                    <span className="text-primary-green font-medium">
                      Quick Response Promise:
                    </span>{" "}
                    We typically respond to all inquiries within 24 hours during
                    business days.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Lightbox */}
      <AnimatePresence>
        {showWidget && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4 sm:p-6 md:p-8"
          >
            {/* Backdrop */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/80 backdrop-blur-sm"
              onClick={handleCloseWidget}
            />

            {/* Content */}
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="relative w-full max-w-3xl bg-[#111111] py-24 rounded-2xl overflow-hidden shadow-2xl"
              style={{
                maxHeight: "calc(100vh - 4rem)",
                height: "auto",
                minHeight: "400px",
              }}
            >
              {/* Close Button */}
              <button
                onClick={handleCloseWidget}
                className="absolute top-24 right-4 z-10 p-2 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
              >
                <X className="w-6 h-6 text-white" />
              </button>

              {/* Widget Container */}
              <div className="h-full overflow-y-auto pt-16">
                <iframe
                  src="https://api.leadconnectorhq.com/widget/booking/yTqQ8p5ki2sZdhs34lxI"
                  style={{
                    width: "100%",
                    height: "100vh",
                    border: "none",
                    minHeight: "700px",
                  }}
                  scrolling="yes"
                  allowFullScreen
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default BookCallCTA;
