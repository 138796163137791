import { useState, useEffect } from "react";
import { projectOperations } from "../utils/projectFirestore";

export const useProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const fetchedProjects = await projectOperations.getProjects();
      setProjects(fetchedProjects);
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const getProjectBySlug = (slug) => {
    return projects.find((project) => project.slug === slug);
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return {
    projects,
    loading,
    error,
    getProjectBySlug,
    refreshProjects: fetchProjects,
  };
};
