import React from "react";
import { motion } from "framer-motion";
import { AnimatedImageStatCard } from "./AnimatedComponents";

const EmotionalConnectionSection = () => {
  const stats = [
    {
      image:
        "https://static.wixstatic.com/media/11062b_60c703945bb843488ec28b3285cdb554~mv2.jpg/v1/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Teen%20Gay%20Couple.jpg",
      stat: "+46%",
      description:
        "Study shows music can improve brand recognition by this percentage. Millennials are particularly likely to associate emotions and memories with sounds.",
      source: "Man Made Music",
    },
    {
      image:
        "https://static.wixstatic.com/media/11062b_f70ef06ad23f4e53a9a9f7b5abd58643~mv2.jpg/v1/crop/x_1228,y_0,w_3244,h_3800/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Listening%20to%20Music.jpg",
      stat: "+96%",
      description:
        "Consumers are more likely to recall a business if it uses brand-congruent music.",
      source: "Prof Adrian North, Leicester University",
    },
    {
      image:
        "https://static.wixstatic.com/media/nsplsh_e086b9525b5c4d55a295f3df77fe2c1a~mv2.jpg/v1/crop/x_624,y_0,w_3412,h_4000/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Vitaly%20Gariev.jpg",
      stat: "83%",
      description:
        "Of millennials feel more connected to a brand when music or sound is integrated in a way that reflects the brand's personality and values.",
      source: "Pandora",
    },
    {
      image:
        "https://static.wixstatic.com/media/nsplsh_39ebca9cd941490d845bcb17a3bc39f5~mv2.jpg/v1/crop/x_0,y_408,w_2529,h_2962/fill/w_286,h_335,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Image%20by%20Nik.jpg",
      stat: "8x",
      description:
        "Branded content with custom sound or music is 8x more likely to be remembered by users compared to content with no sound or generic stock music.",
      source: "TikTok",
    },
  ];

  return (
    <div className="w-full  py-24 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
        {/* <div className="absolute inset-0 bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5" /> */}
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 relative z-10">
        {/* Header Section */}
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-primary-green text-sm font-medium tracking-wide uppercase mb-4 block"
          >
            Brand Impact
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-4xl md:text-7xl font-light text-white/90 mb-6"
          >
            Emotional Connection and
            <span className="font-bold"> Brand Recall</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-lg text-white/70 max-w-3xl mx-auto"
          >
            Discover how sound creates lasting connections with your audience
          </motion.p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <AnimatedImageStatCard
              key={index}
              index={index}
              image={stat.image}
              stat={stat.stat}
              description={stat.description}
              source={stat.source}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmotionalConnectionSection;
