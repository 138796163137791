import React, { useEffect } from "react";
import { audioManager } from "../utils/audioContext";

const useInitAudio = () => {
  useEffect(() => {
    let isInitialized = false;
    let timeout;

    const initAudio = () => {
      if (!isInitialized) {
        audioManager.initialize();
        isInitialized = true;
      }
    };

    const handleScroll = () => {
      clearTimeout(timeout);
      timeout = setTimeout(initAudio, 100);
    };

    const handleMouseMove = () => {
      clearTimeout(timeout);
      timeout = setTimeout(initAudio, 100);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      clearTimeout(timeout);
    };
  }, []);
};

export default useInitAudio;
