import React from "react";
import { motion } from "framer-motion";
import {
  Play,
  Heart,
  Tv,
  Building,
  ShoppingBag,
  Speaker,
  Headphones,
  Phone,
  Music,
  ArrowRight,
} from "lucide-react";

import { Link } from "react-router-dom";

const SonicBrandingSection = () => {
  const touchpoints = [
    { icon: <Play />, label: "Online video", delay: 0.1 },
    { icon: <Heart />, label: "Social media", delay: 0.2 },
    { icon: <Tv />, label: "TV", delay: 0.3 },
    { icon: <Building />, label: "Retail", delay: 0.4 },
    { icon: <ShoppingBag />, label: "Events", delay: 0.5 },
    { icon: <Speaker />, label: "Product", delay: 0.6 },
    { icon: <Headphones />, label: "Apps", delay: 0.7 },
    { icon: <Phone />, label: "Mobile", delay: 0.8 },
  ];

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        {/* Dot Pattern */}
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />

        {/* Large Music Icon */}
        <div className="absolute -right-40 -top-40 text-primary-green/5">
          <Music size={400} />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Header Section */}
        <div className="relative">
          <motion.div
            className="max-w-3xl"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <span className="text-primary-green mb-4 block">
              THE DEFINITION
            </span>
            <h2 className="text-5xl font-light text-white mb-8">
              WHAT DEFINES
              <br />
              <span className="font-bold">SONIC BRANDING?</span>
            </h2>
            <p className="text-xl text-white/70 leading-relaxed">
              Sonic Branding is more than just a jingle, it goes much deeper!
              Any time a brand uses sound, no matter the context, that sound is
              impacting the way customers feel about the brand. If you don't
              have a strategy for how you are using sound then you could be
              losing customers.
            </p>
          </motion.div>

          {/* Decorative Line */}
          <div className="absolute top-0 -right-20 w-40 h-px bg-gradient-to-r from-primary-green to-transparent" />
        </div>
        {/* Touchpoints Grid */}
        <div className="mt-24 relative">
          {/* Background Glow */}
          <div className="absolute inset-0 bg-gradient-to-b from-primary-green/5 via-primary-purple/5 to-transparent rounded-3xl blur-3xl" />

          <div className="relative grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-8 gap-6">
            {touchpoints.map((point, index) => (
              <motion.div
                key={point.label}
                className="group"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: point.delay }}
                viewport={{ once: true }}
              >
                <div className="flex flex-col items-center text-center">
                  {/* Icon Container */}
                  <motion.div
                    className="w-16 h-16 mb-4 rounded-xl bg-white/5 backdrop-blur-sm flex items-center justify-center border border-white/10 group-hover:border-primary-purple transition-colors duration-300"
                    whileHover={{ scale: 1.1, rotate: 5 }}
                  >
                    <div className="text-primary-green">{point.icon}</div>
                  </motion.div>

                  {/* Label */}
                  <span className="text-white/70 group-hover:text-white transition-colors duration-300">
                    {point.label}
                  </span>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Call to Action */}
        <motion.div
          className="relative z-10 mt-24 text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.9 }}
          viewport={{ once: true }}
          onClick={() => {
            document
              .getElementById("process")
              ?.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <motion.span
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="group inline-flex items-center gap-3 px-8 py-4 bg-primary-green text-white rounded-full font-medium text-lg hover:bg-primary-green/90 transition-colors"
          >
            EXPLORE OUR APPROACH
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </motion.span>
        </motion.div>
      </div>
    </section>
  );
};

export default SonicBrandingSection;
