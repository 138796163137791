import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Navigate, useNavigate } from "react-router-dom";
import { Radio, Target, Fingerprint, ArrowUpRight } from "lucide-react";

const ValueSection = () => {
  const navigate = useNavigate();
  const values = [
    {
      icon: <Radio />,
      title: "Increased Engagement",
      stat: "85%",
      description:
        "85% of millennials feel more connected to a brand when music and sound aligns with its personality and values",
      gradient: "from-primary-green/20 to-primary-purple/20",
    },
    {
      icon: <Target />,
      title: "Brand Recognition",
      stat: "2x",
      description:
        "2x faster recognition with audio branding compared to visual-only branding (7 impressions vs 1-2 moments)",
      gradient: "from-primary-purple/20 to-primary-green/20",
    },
    {
      icon: <Fingerprint />,
      title: "Individuality",
      stat: "100%",
      description:
        "100% unique sonic identity that perfectly complements your visual branding and sets you apart in your space",
      gradient: "from-primary-green/20 to-primary-purple/20",
    },
  ];

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(111,87,165)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Header Section */}
        <motion.div
          className="text-center max-w-3xl mx-auto mb-24"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <span className="text-primary-green mb-4 block">THE VALUE</span>
          <h2 className="text-5xl font-light text-white mb-8">
            Transform Your Brand's
            <br />
            <span className="font-bold">Audio Identity</span>
          </h2>

          {/* Decorative Line */}
          <div className="w-24 h-1 bg-gradient-to-r from-primary-green to-primary-purple mx-auto" />
        </motion.div>

        {/* Value Cards */}
        <div className="grid md:grid-cols-3 gap-8">
          {values.map((value, index) => (
            <motion.div
              key={value.title}
              className="group relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              {/* Glowing Background */}
              <div
                className={`absolute inset-0 bg-gradient-to-br ${value.gradient} rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500 opacity-50`}
              />

              {/* Card Content */}
              <div className="relative bg-white/5 backdrop-blur-sm rounded-2xl p-8 h-full border border-white/10 hover:border-white/20 transition-colors duration-300">
                {/* Icon & Title */}
                <div className="flex items-start justify-between mb-8">
                  <div className="flex-1">
                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-xl bg-primary-green/10 mb-4 group-hover:bg-primary-green/20 transition-colors">
                      <div className="text-primary-green">{value.icon}</div>
                    </div>
                    <h3 className="text-2xl font-bold text-white">
                      {value.title}
                    </h3>
                  </div>

                  {/* Stat */}
                  <div className="text-4xl font-bold text-primary-green">
                    {value.stat}
                  </div>
                </div>

                {/* Description */}
                <p className="text-white/70 leading-relaxed mb-8">
                  {value.description}
                </p>

                {/* Learn More Link */}
                <motion.div
                  className="flex items-center gap-2 text-primary-green group-hover:text-primary-purple transition-colors cursor-pointer"
                  whileHover={{ x: 5 }}
                >
                  <span
                    className="text-sm font-medium"
                    onClick={() => navigate("/ROI")}
                  >
                    Learn More
                  </span>
                  <ArrowUpRight className="w-4 h-4" />
                </motion.div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* CTA Section */}
        <motion.div
          className="relative z-10 text-center mt-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.button
            className="group inline-flex items-center gap-3 px-8 py-4 bg-primary-green text-white rounded-full font-medium text-lg hover:bg-primary-green/90 transition-all"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              document
                .getElementById("start")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            BUILD YOUR SONIC BRANDING STRATEGY
            <ArrowUpRight className="w-5 h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default ValueSection;
