import React, { useState } from "react";
import { motion, useSpring, useInView } from "framer-motion";
import { Volume2, Headphones, Smartphone, Radio } from "lucide-react";

// Counting animation helper
const Counter = ({ value, className }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true });
  // Extract numeric value
  const numericValue = parseFloat(value.replace(/[^\d.-]/g, ""));

  return (
    <motion.div
      ref={ref}
      className={className}
      initial={{ value: 0 }}
      animate={isInView ? { value: numericValue } : { value: 0 }}
      transition={{ duration: 1.5, ease: "easeOut" }}
    >
      {value.startsWith("+") ? "+" : ""}
      {Math.round(numericValue)}%
    </motion.div>
  );
};

const TrendCard = ({ icon: Icon, stat, description, source, index }) => {
  const [isHovered, setIsHovered] = useState(false);
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  // Spring animation for icon rotation
  const iconRotation = useSpring(0, {
    stiffness: 100,
    damping: 10,
  });

  React.useEffect(() => {
    iconRotation.set(isHovered ? 360 : 0);
  }, [isHovered]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="group relative flex flex-col items-center"
    >
      {/* Circular Icon Background */}
      <motion.div
        className="relative w-32 h-32 mb-6"
        animate={{
          scale: isHovered ? 1.1 : 1,
          y: isHovered ? -5 : 0,
        }}
        transition={{ duration: 0.3 }}
      >
        {/* Glass background with animated gradient */}
        <div className="absolute inset-0 rounded-full bg-[#111111]/80 backdrop-blur-xl border border-white/5">
          <motion.div
            className="absolute inset-0 rounded-full"
            animate={{
              background: isHovered
                ? "linear-gradient(225deg, rgba(111,87,165,0.1), transparent 50%, rgba(1,178,128,0.1))"
                : "linear-gradient(45deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
            }}
            transition={{ duration: 0.5 }}
          />
          <motion.div
            className="absolute inset-0 rounded-full opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]"
            animate={{
              scale: isHovered ? 1.1 : 1,
              opacity: isHovered ? 0.7 : 0.5,
            }}
          />
        </div>

        {/* Animated Icon */}
        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          style={{ rotate: iconRotation }}
        >
          <Icon className="w-12 h-12 text-primary-green/70 group-hover:text-primary-green transition-colors" />
        </motion.div>
      </motion.div>

      {/* Content with Sequential Animations */}
      <motion.div
        className="text-center"
        animate={{
          y: isHovered ? -5 : 0,
          scale: isHovered ? 1.02 : 1,
        }}
        transition={{ duration: 0.3 }}
      >
        <Counter
          value={stat}
          className="text-6xl font-bold text-primary-purple mb-4"
        />

        <motion.p
          className="text-white/70 mb-4 max-w-sm"
          initial={{ opacity: 0, y: 10 }}
          animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }}
          transition={{ delay: index * 0.1 + 0.3 }}
        >
          {description}
        </motion.p>

        <motion.div
          className="text-sm font-medium text-white/50"
          initial={{ opacity: 0 }}
          animate={isInView ? { opacity: 1 } : { opacity: 0 }}
          transition={{ delay: index * 0.1 + 0.4 }}
        >
          - {source}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const AudioConsumptionTrends = () => {
  const trends = [
    {
      icon: Headphones,
      stat: "+74%",
      description:
        "74% of young adults develop a better understanding of company's personality through music",
      source: "PHMG",
    },
    {
      icon: Volume2,
      stat: "79%",
      description:
        "Unlike video or other visual media, audio allows us to consume content without interruption, which is why 79% of listening occurs while we're multitasking",
      source: "Spotify for Brands",
    },
    {
      icon: Smartphone,
      stat: "+70%",
      description:
        "70% of consumers are more likely to remember a brand with a unique audio identity",
      source: "Journal of Advertising Research",
    },
  ];

  return (
    <div className="w-full  py-24 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0">
        <motion.div
          className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]"
          animate={{
            opacity: [0.03, 0.05, 0.03],
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear",
          }}
        />
        <motion.div
          className="absolute inset-0"
          // animate={{
          //   background: [
          //     "linear-gradient(to bottom right, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
          //     "linear-gradient(to bottom right, rgba(1,178,128,0.05), transparent 50%, rgba(111,87,165,0.05))",
          //     "linear-gradient(to bottom right, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
          //   ],
          // }}
          // transition={{
          //   duration: 10,
          //   repeat: Infinity,
          //   ease: "linear",
          // }}
        />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 relative z-10">
        {/* Header Section with Sequential Animations */}
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-primary-green text-sm font-medium tracking-wide uppercase mb-4 block"
          >
            The Power of Sound
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-4xl md:text-7xl font-light text-white/90 mb-6"
          >
            Audio Consumption
            <motion.span
              className="font-bold"
              animate={{
                color: [
                  "rgb(255, 255, 255)",
                  "rgb(1, 178, 128)",
                  "rgb(255, 255, 255)",
                ],
              }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              {" "}
              Trends
            </motion.span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-lg text-white/70 max-w-3xl mx-auto"
          >
            How modern audiences interact with sound
          </motion.p>
        </div>

        {/* Trends Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-16">
          {trends.map((trend, index) => (
            <TrendCard
              key={index}
              icon={trend.icon}
              stat={trend.stat}
              description={trend.description}
              source={trend.source}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AudioConsumptionTrends;
