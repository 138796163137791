import React, { useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  ArrowRight,
  Volume2,
  Tv,
  Play,
  Loader,
  Circle,
  LayoutGrid,
  Radio,
  Music2,
  Globe,
  Smartphone,
  Mic2,
  Waves,
} from "lucide-react";
import { Link } from "react-router-dom";
import { useProjects } from "../hooks/useProjects";

const PROJECT_TYPES = [
  { id: "all", label: "All Work", icon: Circle },
  { id: "tv-commercial", label: "TV/Film", icon: Tv },
  { id: "sonic-branding", label: "Sonic Branding", icon: Volume2 },
  { id: "soundboard", label: "Soundboard", icon: LayoutGrid },
  { id: "sonic-logo", label: "Sonic Logo", icon: Radio },
  // { id: "brand-track", label: "Brand Track", icon: Radio },
  { id: "ad-track", label: "Advertising", icon: Music2 },
  { id: "app-sounds", label: "App/UI", icon: Smartphone },
  { id: "podcast-sounds", label: "Podcast", icon: Mic2 },
  { id: "soundscape", label: "Immersive Brand Experiences", icon: Waves },
];

const WorkShowcase = () => {
  const { projects, loading, error } = useProjects();
  const [selectedType, setSelectedType] = useState("all");

  const filteredProjects = projects.filter((project) => {
    const isPublished = project.status === "published";

    // Handle type filtering when selectedType is "all"
    if (selectedType === "all") {
      return isPublished;
    }

    // Handle cases where project.type might be a string or an array
    const projectTypes = Array.isArray(project.type)
      ? project.type
      : [project.type];

    // Check if the selected type exists in the project's type array
    const matchesType = projectTypes.includes(selectedType);

    return isPublished && matchesType;
  });

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-primary-green" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-red-400">Error loading projects: {error}</div>
      </div>
    );
  }

  const gridVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const projectVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 25,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.2,
      },
    },
  };

  const buttonVariants = {
    selected: {
      backgroundColor: "rgb(1, 178, 128)",
      color: "rgb(255, 255, 255)",
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 500,
        damping: 30,
      },
    },
    unselected: {
      backgroundColor: "rgba(255, 255, 255, 0.05)",
      color: "rgba(255, 255, 255, 0.7)",
      scale: 1,
    },
    tap: {
      scale: 0.95,
    },
    hover: {
      scale: 1.05,
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
  };

  return (
    <div className="min-h-screen py-24 bg-[#111111] relative">
      {/* Removed 'flex items-center' from the main container */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
        <div
          className="absolute inset-0"
          style={{
            background: `linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))`,
          }}
        />
      </div>

      <div className="w-full">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          {/* Header Section */}
          <div className="mb-16 text-center mt-20">
            {/* Added 'mt-20' to push content below the navbar */}
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-primary-green mb-4"
            >
              Our work
            </motion.p>

            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-4xl md:text-7xl font-light text-white/90 max-w-4xl mx-auto mb-8"
            >
              UNLOCKING VALUE THAT ONLY
              <span className="font-bold"> SOUND</span> CAN
            </motion.h1>

            {/* <div className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-12">
              <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
                className="text-lg text-white/70 max-w-2xl"
              >
                Meet us at the intersection of creativity and craftsmanship, and
                join the chorus of visionary brands that believe in the
                transformative power of music and sound.
              </motion.p>
            </div> */}
          </div>

          {/* Filter Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mb-8 -mx-1 overflow-x-auto pb-4"
          >
            <div className="flex flex-wrap justify-center gap-4 py-1 min-w-full">
              {PROJECT_TYPES.map((type) => {
                const Icon = type.icon;
                const isSelected = selectedType === type.id;
                return (
                  <motion.button
                    key={type.id}
                    onClick={() => setSelectedType(type.id)}
                    variants={buttonVariants}
                    initial="unselected"
                    animate={isSelected ? "selected" : "unselected"}
                    whileHover="hover"
                    whileTap="tap"
                    className={`flex items-center gap-2 px-3 py-1.5 rounded-full backdrop-blur-sm border whitespace-nowrap transition-colors ${
                      isSelected ? "border-primary-green" : "border-white/10"
                    }`}
                  >
                    <motion.div
                      animate={{
                        rotate: isSelected ? [0, -360] : 0,
                      }}
                      transition={{
                        duration: 0.5,
                        type: "spring",
                        stiffness: 200,
                      }}
                    >
                      <Icon className="w-4 h-4" />
                    </motion.div>
                    <span className="text-sm font-medium">{type.label}</span>
                  </motion.button>
                );
              })}
            </div>
          </motion.div>

          {/* Projects Grid */}
          <AnimatePresence mode="wait">
            {filteredProjects.length > 0 ? (
              <motion.div
                key="grid"
                variants={gridVariants}
                initial="hidden"
                animate="visible"
                exit="hidden"
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
              >
                <AnimatePresence>
                  {filteredProjects.map((project) => (
                    <motion.div
                      key={project.slug}
                      variants={projectVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                      layout
                    >
                      <ProjectCard client={project} />
                    </motion.div>
                  ))}
                </AnimatePresence>
              </motion.div>
            ) : (
              <motion.div
                key="empty"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="text-center py-12"
              >
                <p className="text-white/70">
                  No projects found for this category.
                </p>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Navigation Dots */}
          <div className="flex justify-center gap-2 mt-8">
            {[...Array(projects.length)].map((_, i) => (
              <button
                key={i}
                className={`w-2 h-2 rounded-full transition-colors ${
                  i === 0 ? "bg-primary-green" : "bg-white/20"
                }`}
                aria-label={`Go to slide ${i + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectCard = ({ client }) => {
  const videoRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);

  const videoSection = client.sections.find((s) => s.type === "video");
  const overviewSection = client.sections.find((s) => s.type === "overview");
  const brandSection = client.sections.find((s) => s.type === "brand");

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (videoRef.current && videoSection?.data.videoUrl) {
      videoRef.current.currentTime = 0;
      videoRef.current
        .play()
        .catch((err) => console.log("Autoplay prevented:", err));
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleVideoLoad = () => {
    setIsVideoReady(true);
    if (videoRef.current) {
      // Set video duration to 3 seconds if longer
      if (videoRef.current.duration > 3) {
        videoRef.current.addEventListener("timeupdate", function () {
          if (this.currentTime >= 3) {
            this.pause();
          }
        });
      }
    }
  };

  return (
    <Link to={`/work/${client.slug}`}>
      <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="group relative aspect-[4/3] overflow-hidden rounded-lg"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* Media Content */}
        {videoSection?.data.videoUrl && (
          <video
            ref={videoRef}
            className="absolute inset-0 w-full h-full object-cover"
            src={videoSection.data.videoUrl}
            poster={videoSection.data.posterImage}
            muted
            playsInline
            onLoadedData={handleVideoLoad}
            style={{
              opacity: isHovered && isVideoReady ? 1 : 0,
              transition: "opacity 0.3s ease-in-out",
            }}
          />
        )}

        {/* Poster Image */}
        <img
          src={videoSection?.data.posterImage}
          alt={client.title}
          className="absolute inset-0 w-full h-full object-cover transition-opacity duration-300"
          style={{
            opacity: isHovered && isVideoReady ? 0 : 1,
          }}
        />

        {/* Content Overlay */}
        <div className="absolute inset-0 p-6 flex flex-col justify-between z-10">
          {/* Top Row with Type Icon and Brand Logo */}
          <div className="flex justify-between items-start">
            {/* Type Icons */}
            <div className="flex items-center gap-2">
              {Array.isArray(client.type) ? (
                client.type.map((type) => {
                  const TypeIcon =
                    PROJECT_TYPES.find((t) => t.id === type)?.icon || Circle;
                  return (
                    <div
                      key={type}
                      className="bg-primary-green/20 backdrop-blur-sm px-3 py-1 rounded-full"
                    >
                      <TypeIcon className="w-4 h-4 text-primary-green" />
                    </div>
                  );
                })
              ) : (
                // Fallback for legacy data where type might still be a string
                <div className="bg-primary-green/20 backdrop-blur-sm px-3 py-1 rounded-full">
                  {(() => {
                    const TypeIcon =
                      PROJECT_TYPES.find((t) => t.id === client.type)?.icon ||
                      Circle;
                    return <TypeIcon className="w-4 h-4 text-primary-green" />;
                  })()}
                </div>
              )}
            </div>

            {/* Brand Logo */}
            {/* <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 0.8 }}
              whileHover={{ opacity: 1, scale: 1.05 }}
              className="w-16 h-16 bg-gradient-to-b from-primary-green/5 via-primary-purple/5 to-transparent backdrop-blur-md rounded-xl p-2 shadow-xl border border-white/10"
            >
              {brandSection?.data.logo ? (
                <img
                  src={brandSection.data.logo}
                  alt={`${client.title} logo`}
                  className="w-full h-full object-contain filter brightness-0 invert opacity-80"
                />
              ) : (
                <div className="w-full h-full flex items-center justify-center text-white/50 text-xs text-center font-medium">
                  {client.title.split(" ")[0]}
                </div>
              )}
            </motion.div> */}
          </div>

          {/* Bottom Text */}
          <div className="relative z-10">
            {/* Dark gradient overlay for text readability */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent -mx-6 -mb-6 pt-12" />

            <div className="relative">
              <h3 className="text-xl font-bold text-white mb-2">
                {client.title}
              </h3>
              {/* <p className="text-sm text-white/70">
                {overviewSection?.data.brief}
              </p> */}
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

export default WorkShowcase;
