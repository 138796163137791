import React, { useState } from "react";
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useInView,
} from "framer-motion";
import { Volume2, Headphones, Smartphone } from "lucide-react";

// Shared animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 30 },
  visible: { opacity: 1, y: 0 },
};

const scaleIn = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
};

const countingAnimation = (value) => {
  // Remove any non-numeric characters
  const numericValue = parseFloat(value.replace(/[^0-9.-]/g, ""));
  return {
    hidden: { value: 0 },
    visible: {
      value: numericValue,
      transition: { duration: 1.5, ease: "easeOut" },
    },
  };
};

// ROI Stats Section
const AnimatedStatCard = ({ title, value, source, delay = 0 }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });

  return (
    <motion.div
      ref={ref}
      variants={scaleIn}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 0.5, delay }}
      className="relative overflow-hidden rounded-lg p-6 h-full transform-gpu hover:scale-105 transition-transform duration-300"
    >
      {/* Glass background with enhanced animations */}
      <motion.div
        className="absolute inset-0 bg-[#111111]/80 backdrop-blur-xl border border-white/5"
        initial={{ opacity: 0 }}
        animate={isInView ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.5, delay: delay + 0.2 }}
      >
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5"
          animate={{
            background: [
              "linear-gradient(45deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
              "linear-gradient(225deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
            ],
          }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
        <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
      </motion.div>

      {/* Content with counting animation */}
      <div className="relative z-10 flex flex-col h-full">
        <motion.div
          className="text-5xl font-bold text-primary-purple mb-4"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={countingAnimation(value)}
        >
          {value}
        </motion.div>

        <motion.p
          className="text-white/70 mb-4 flex-grow"
          variants={fadeInUp}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ duration: 0.5, delay: delay + 0.3 }}
        >
          {title}
        </motion.p>

        <motion.div
          className="text-sm font-medium text-white/50"
          variants={fadeInUp}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          transition={{ duration: 0.5, delay: delay + 0.4 }}
        >
          - {source}
        </motion.div>
      </div>
    </motion.div>
  );
};

// Emotional Connection Section
const AnimatedImageStatCard = ({ image, stat, description, source, index }) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      ref={ref}
      variants={scaleIn}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="relative aspect-[4/5] group overflow-hidden rounded-lg"
    >
      {/* Background Image with Zoom Effect */}
      <motion.div
        className="absolute inset-0"
        animate={{ scale: isHovered ? 1.05 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <img
          src={image}
          alt={description}
          className="w-full h-full object-cover"
        />
        <motion.div
          className="absolute inset-0 bg-gradient-to-t from-[#111111] via-[#111111]/50 to-transparent"
          animate={{ opacity: isHovered ? 0.7 : 0.9 }}
          transition={{ duration: 0.3 }}
        />
      </motion.div>

      {/* Content Card */}
      <motion.div
        className="absolute inset-0 flex flex-col justify-end p-6"
        animate={{ y: isHovered ? 0 : 10 }}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          className="relative bg-[#111111]/80 backdrop-blur-xl border border-white/5 rounded-lg p-6"
          animate={{ y: isHovered ? 0 : 10, scale: isHovered ? 1.02 : 1 }}
          transition={{ duration: 0.3 }}
        >
          <div className="absolute inset-0 bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5 rounded-lg" />
          <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />

          <div className="relative z-10">
            <motion.div
              className="text-6xl font-bold text-primary-purple mb-4"
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={countingAnimation(stat)}
            >
              {stat}
            </motion.div>
            <motion.p
              className="text-white/70 text-lg mb-4"
              variants={fadeInUp}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              transition={{ delay: index * 0.1 + 0.2 }}
            >
              {description}
            </motion.p>
            <motion.div
              className="text-sm font-medium text-white/50"
              variants={fadeInUp}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              transition={{ delay: index * 0.1 + 0.3 }}
            >
              - {source}
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

// Audio Consumption Trends Section
const AnimatedTrendCard = ({
  icon: Icon,
  stat,
  description,
  source,
  index,
}) => {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: true, margin: "-100px" });
  const [isHovered, setIsHovered] = useState(false);

  const iconRotation = useSpring(0, {
    stiffness: 100,
    damping: 10,
  });

  // Rotate icon on hover
  React.useEffect(() => {
    iconRotation.set(isHovered ? 360 : 0);
  }, [isHovered]);

  return (
    <motion.div
      ref={ref}
      variants={fadeInUp}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className="group relative flex flex-col items-center"
    >
      {/* Animated Icon Circle */}
      <motion.div
        className="relative w-32 h-32 mb-6"
        animate={{ scale: isHovered ? 1.1 : 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="absolute inset-0 rounded-full bg-[#111111]/80 backdrop-blur-xl border border-white/5">
          <motion.div
            className="absolute inset-0 rounded-full bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5"
            animate={{
              background: isHovered
                ? "linear-gradient(225deg, rgba(111,87,165,0.1), transparent 50%, rgba(1,178,128,0.1))"
                : "linear-gradient(45deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
            }}
          />
          <div className="absolute inset-0 rounded-full opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
        </div>

        <motion.div
          className="absolute inset-0 flex items-center justify-center"
          style={{ rotate: iconRotation }}
        >
          <Icon className="w-12 h-12 text-primary-green/70 group-hover:text-primary-green transition-colors" />
        </motion.div>
      </motion.div>

      {/* Animated Content */}
      <motion.div
        className="text-center"
        variants={fadeInUp}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        transition={{ duration: 0.5, delay: index * 0.1 + 0.2 }}
      >
        <motion.div
          className="text-6xl font-bold text-primary-purple mb-4"
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          variants={countingAnimation(stat)}
        >
          {stat}
        </motion.div>
        <motion.p
          className="text-white/70 mb-4 max-w-sm"
          animate={{ y: isHovered ? 0 : 5 }}
          transition={{ duration: 0.3 }}
        >
          {description}
        </motion.p>
        <motion.div
          className="text-sm font-medium text-white/50"
          animate={{ opacity: isHovered ? 1 : 0.7 }}
          transition={{ duration: 0.3 }}
        >
          - {source}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

// Export the enhanced components
export { AnimatedStatCard, AnimatedImageStatCard, AnimatedTrendCard };
