import { motion, useAnimation } from "framer-motion";
import { useState, useRef } from "react";

const ClientWork = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const videoControls = useAnimation();
  const videoRef = useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    setIsOverlayVisible(false);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    setIsOverlayVisible(true);
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleVideoClick = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  return (
    <section className="relative bg-[#111111] py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Section Header */}
        <motion.div
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <span className="text-primary-green mb-4 block">OUR WORK</span>
          <h2 className="text-5xl font-light text-white">
            CLIENT <span className="font-bold">WORK</span>
          </h2>
        </motion.div>

        {/* Video Container */}
        <motion.div
          className="relative aspect-video w-full max-w-5xl mx-auto rounded-2xl overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          viewport={{ once: true }}
        >
          {/* Video Thumbnail Container */}
          <div className="relative w-full h-full bg-black/50 backdrop-blur-sm group">
            {/* Video Playback */}
            <video
              ref={videoRef}
              src="https://video.wixstatic.com/video/ac6faa_3df0823f6fdc4322916b31b29befdfc5/1080p/mp4/file.mp4"
              controls={isPlaying}
              className="w-full h-full object-cover opacity-90 group-hover:opacity-100 transition-opacity duration-300"
              onPlay={handlePlay}
              onPause={handlePause}
              onClick={handleVideoClick}
              animate={videoControls}
            />

            {/* Play Button Overlay */}
            {isOverlayVisible && (
              <motion.button
                className="absolute inset-0 flex items-center justify-center bg-black/30 group-hover:bg-black/40 transition-colors"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handlePlay}
              >
                <div className="w-20 h-20 rounded-full bg-primary-green/90 flex items-center justify-center group-hover:bg-primary-green transition-colors">
                  <div className="w-16 h-16 rounded-full bg-primary-green flex items-center justify-center">
                    <svg
                      className="w-8 h-8 text-white ml-1"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8 5v14l11-7z" />
                    </svg>
                  </div>
                </div>
              </motion.button>
            )}

            {/* Video Title */}
            <motion.div
              className="absolute bottom-0 left-0 right-0 p-8 bg-gradient-to-t from-black/80 to-transparent"
              initial={{ y: 0 }}
              animate={{ y: isPlaying ? "100%" : "0%" }}
              transition={{ duration: 0.5 }}
            >
              <h3 className="text-lg lg:text-xl font-bold text-white mb-2">
                Give yourself up to the gargle
              </h3>
              <p className="text-white/70">Robinsons x ITV Sport Campaign</p>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ClientWork;
