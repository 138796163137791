import React from "react";
import { motion } from "framer-motion";

const InfiniteScrollText = () => {
  const services = [
    "AUDIO LOGOS",
    "PODCASTING",
    "SOCIAL MEDIA",
    "APP SOUNDS",
    "EVENTS",
    "PRODUCT SOUNDS",
    "SONIC BRANDING",
    "TV & FILM",
    "SONIC LOGOS",
    "AUDIO LOGOS",
    "PODCASTING",
    "SOCIAL MEDIA",
    "APP SOUNDS",
    "EVENTS",
    "PRODUCT SOUNDS",
    "SONIC BRANDING",
    "TV & FILM",
    "SONIC LOGOS",
  ];

  return (
    <div className="relative w-full left-[50%] right-[50%] mx-[-50vw] md:left-0 md:right-0 md:mx-0">
      <div className="relative md:max-w-7xl md:mx-auto">
        <div className="relative w-screen md:w-full overflow-hidden h-12">
          {/* Glass effect background */}
          <div className="absolute inset-0 bg-[#111111]/80 backdrop-blur-xl border-t border-b border-white/5">
            <div className="absolute inset-0 bg-gradient-to-r from-primary-purple/5 via-transparent to-primary-green/5" />
          </div>

          {/* Scrolling content */}
          <div className="relative flex">
            <motion.div
              className="flex whitespace-nowrap gap-8 items-center py-3 px-4 md:px-6"
              animate={{
                x: ["0%", "-50%"],
              }}
              transition={{
                duration: 20,
                ease: "linear",
                repeat: Infinity,
              }}
            >
              {services.map((service, index) => (
                <span
                  key={index}
                  className="text-white/70 font-medium tracking-wide text-sm inline-flex items-center gap-2"
                >
                  {service}
                  <span className="w-2 h-2 rounded-full bg-primary-green/40" />
                </span>
              ))}
            </motion.div>

            {/* Duplicate for seamless loop */}
            <motion.div
              className="flex whitespace-nowrap gap-8 items-center py-3 px-4 md:px-6"
              animate={{
                x: ["0%", "-50%"],
              }}
              transition={{
                duration: 20,
                ease: "linear",
                repeat: Infinity,
              }}
            >
              {services.map((service, index) => (
                <span
                  key={index}
                  className="text-white/70 font-medium tracking-wide text-sm inline-flex items-center gap-2"
                >
                  {service}
                  <span className="w-2 h-2 rounded-full bg-primary-green/40" />
                </span>
              ))}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollText;
