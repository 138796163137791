import React from "react";
import { motion } from "framer-motion";
import { Headphones, Target, Smartphone, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const InfoBoxesSection = () => {
  const navigate = useNavigate();
  const infoBoxes = [
    {
      icon: <Headphones className="w-8 h-8" />,
      title: "Strategic Sound Impact",
      content:
        "Using sound without strategic intention could lead to miscommunication of your brand's personality. Strategic sonic branding ensures clear messaging and enhanced engagement.",
      color: "from-primary-green/20 to-primary-purple/20",
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "Building Trust Through Sound",
      content:
        "Building trust requires consistency across all touchpoints. A well-planned sonic branding strategy ensures your brand maintains its unique voice across every interaction.",
      color: "from-primary-purple/20 to-primary-green/20",
    },
    {
      icon: <Smartphone className="w-8 h-8" />,
      title: "Enhanced Brand Connection",
      content:
        "A clear brand personality attracts ideal recurring customers by creating stronger emotional connections, leading to increased engagement and better results.",
      color: "from-primary-green/20 to-primary-purple/20",
    },
  ];

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Section Header */}
        <motion.div
          className="text-center max-w-2xl mx-auto mb-24"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <span className="text-primary-green mb-4 block">THE ADVANTAGES</span>
          <h2 className="text-4xl font-light text-white mb-6">
            Transforming Your Brand Through
            <span className="font-bold block">Strategic Sound Design</span>
          </h2>
        </motion.div>

        {/* Info Boxes Grid */}
        <div className="grid md:grid-cols-3 gap-8">
          {infoBoxes.map((box, index) => (
            <motion.div
              key={index}
              className="group relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              {/* Card Content */}
              <div className="relative h-full">
                {/* Glowing Background */}
                <div
                  className={`absolute inset-0 bg-gradient-to-br ${box.color} rounded-2xl blur-xl group-hover:blur-2xl transition-all duration-500 opacity-50`}
                />

                {/* Main Content */}
                <div className="relative bg-white/5 backdrop-blur-sm rounded-2xl p-8 h-full border border-white/10 hover:border-white/20 transition-colors duration-300">
                  {/* Icon Container */}
                  <div className="inline-flex items-center justify-center w-16 h-16 rounded-xl bg-primary-green/10 mb-6 group-hover:bg-primary-green/20 transition-colors">
                    <div className="text-primary-green">{box.icon}</div>
                  </div>

                  <h3 className="text-2xl font-bold text-white mb-4">
                    {box.title}
                  </h3>

                  <p className="text-white/70 leading-relaxed mb-6">
                    {box.content}
                  </p>

                  {/* Learn More Link */}
                  <div className="absolute bottom-8 left-8 right-8">
                    <motion.div
                      className="flex items-center gap-2 text-primary-green group-hover:text-primary-purple transition-colors cursor-pointer"
                      whileHover={{ x: 5 }}
                    >
                      <span
                        className="text-sm font-medium"
                        onClick={() => navigate("/services")}
                      >
                        Learn More
                      </span>
                      <ArrowRight className="w-4 h-4" />
                    </motion.div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Optional CTA */}
        <motion.div
          className="relative z-10 text-center mt-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.button
            className="px-8 py-4 bg-primary-green text-white rounded-full font-medium inline-flex items-center gap-2 hover:bg-primary-green/90 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/services")}
          >
            <span>Explore Our Services</span>
            <ArrowRight className="w-5 h-5" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default InfoBoxesSection;
