// import React from "react";
// import { motion } from "framer-motion";

// const PartnersSection = () => {
//   const partners = [
//     "prime video",
//     "netflix",
//     "tesco",
//     "sky bingo",
//     "bbh",
//     "bt",
//     "mccann",
//     "liquona",
//     "primark",
//     "Hyve",
//     "Robinsons",
//     "thomas cook",
//     "courtney",
//     "felt",
//     "cif",
//   ];

//   return (
//     <motion.div
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.8 }}
//       className="bg-primary-green py-16"
//     >
//       <div className="max-w-7xl mx-auto px-4">
//         <h2 className="text-4xl font-bold text-white text-center mb-12">
//           TRUSTED BY OUR PARTNERS
//         </h2>
//         <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8 items-center justify-items-center">
//           {partners.map((partner) => (
//             <motion.div
//               key={partner}
//               className="w-32 h-16 bg-white/10 backdrop-blur-sm rounded-lg flex items-center justify-center p-4 hover:bg-white/20 transition-colors duration-300"
//               whileHover={{ scale: 1.05 }}
//               whileTap={{ scale: 0.95 }}
//             >
//               <div className="text-white w-full h-full">
//                 <img
//                   src={`/svg/${partner}.svg`}
//                   alt={`${partner} logo`}
//                   className="w-full h-full object-contain filter brightness-0 invert"
//                   loading="lazy"
//                 />
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </motion.div>
//   );
// };

// export default PartnersSection;

import React from "react";
import { motion } from "framer-motion";
import { ArrowRight } from "lucide-react";

const PartnersSection = () => {
  const partners = [
    "Prime Video",
    "Netflix",
    "Tesco",
    "Sky Bingo",
    "BBH",
    "BT",
    "McCann",
    "Liquona",
    "Primark",
    "Hyve",
    "Robinsons",
    "Thomas Cook",
    "Courtney",
    "Felt",
    "CIF",
  ];

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Header Section */}
        <motion.div
          className="text-center max-w-3xl mx-auto mb-24"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <span className="text-primary-green mb-4 block">OUR PARTNERS</span>
          <h2 className="text-5xl font-light text-white mb-8">
            Trusted By Industry
            <br />
            <span className="font-bold">Leading Brands</span>
          </h2>

          {/* Decorative Elements */}
          <div className="flex items-center justify-center gap-4 mt-8">
            <div className="h-px w-12 bg-gradient-to-r from-transparent to-primary-green/50" />
            <span className="text-white/50">15+ Global Partners</span>
            <div className="h-px w-12 bg-gradient-to-l from-transparent to-primary-green/50" />
          </div>
        </motion.div>

        {/* Partners Grid */}
        <div className="relative">
          {/* Background Glow */}
          <div className="absolute inset-0 bg-gradient-to-b from-primary-green/5 via-primary-purple/5 to-transparent rounded-3xl blur-3xl" />

          <div className="relative grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-8">
            {partners.map((partner, index) => (
              <motion.div
                key={partner}
                className="group relative"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                {/* Card with Hover Effect */}
                <div className="relative h-full">
                  {/* Glowing Border */}
                  <div className="absolute inset-0 bg-gradient-to-br from-primary-green/20 to-primary-purple/20 rounded-xl blur-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

                  {/* Content Container */}
                  <div className="relative bg-white/5 backdrop-blur-sm rounded-xl p-6 border border-white/10 group-hover:border-white/20 transition-all duration-300">
                    <div className="aspect-video flex items-center justify-center">
                      <img
                        src={`/svg/${partner}.svg`}
                        alt={`${partner} logo`}
                        className="w-full h-full object-contain filter brightness-0 invert opacity-70 group-hover:opacity-100 transition-opacity duration-300"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* CTA Section */}
        <motion.div
          className=" relative z-10 text-center mt-16"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
        >
          <motion.button
            className="z-index 10 group inline-flex items-center gap-3 px-8 py-4 bg-primary-green/10 text-primary-green border border-primary-green/20 rounded-full font-medium hover:bg-primary-green hover:text-white transition-all duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => //I want to link to the client-work page
              (window.location.href = "/client-work")
            }
          >
            VIEW PROJECTS
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </motion.div>
      </div>
    </section>
  );
};

export default PartnersSection;
