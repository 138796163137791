import React, { useState } from "react";
import {
  motion,
  useSpring,
  useInView,
  useScroll,
  useTransform,
} from "framer-motion";

import { AnimatedStatCard } from "../Components/AnimatedComponents";

const StatsSection = () => {
  const stats = [
    {
      value: "+80%",
      title:
        "80% boost in brand recognition when effective Sonic Branding is used",
      source: "McKinsey Research",
    },
    {
      value: "+63%",
      title:
        "63% of consumers see brands with consistent Sonic identities as more trustworthy",
      source: "Nielsen Research",
    },
    {
      value: "+12%",
      title:
        "12% increase in long term revenue due to a stronger emotional connection and brand loyalty",
      source: "SoundOut",
    },
    {
      value: "+24%",
      title:
        "Higher return on investment than ads without integrated Sonic Branding elements",
      source: "AdWeek",
    },
    {
      value: "+20%",
      title:
        "20% increase in purchase intent when Sonic Branding is used, leading to increased ROI",
      source: "Veritonic",
    },
    {
      value: "75%",
      title:
        "75% of 18–24-year-olds say music helps them feel more connected to a brand",
      source: "Sonic Science, Spotify",
    },
  ];

  return (
    <div className="w-full my-auto  py-24 relative overflow-hidden">
      {/* Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 opacity-5 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px]" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 relative z-10">
        {/* Header Section */}
        <div className="text-center mb-16">
          <motion.span
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-primary-green text-sm font-medium tracking-wide uppercase mb-4 block"
          >
            Real World Numbers
          </motion.span>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-4xl md:text-7xl font-light text-white/90 mb-6"
          >
            Strategic use of sound will give you a
            <span className="font-bold"> competitive edge</span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="text-lg text-white/70 max-w-3xl mx-auto"
          >
            ROI, Brand Engagement & Loyalty
          </motion.p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {stats.map((stat, index) => (
            <AnimatedStatCard
              key={index}
              value={stat.value}
              title={stat.title}
              source={stat.source}
              delay={0.2 + index * 0.1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
