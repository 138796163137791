import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDocs,
  getDoc,
  query,
  orderBy,
  setDoc,
  writeBatch,
  limit,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../firebase";

// Media Upload Helper Functions
const uploadProjectMedia = async (files) => {
  try {
    const mediaUrls = {};
    for (const [key, file] of Object.entries(files)) {
      if (file instanceof File) {
        const storageRef = ref(storage, `projects/${Date.now()}_${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        mediaUrls[key] = url;
      } else if (typeof file === "string") {
        mediaUrls[key] = file;
      }
    }
    return mediaUrls;
  } catch (error) {
    console.error("Error uploading media:", error);
    throw error;
  }
};

const deleteProjectMedia = async (project) => {
  try {
    const mediaKeys = [
      "mainVideo",
      "poster",
      "challengeImage",
      "approachImage",
      "resultImage",
      "thumbnails",
    ];

    for (const key of mediaKeys) {
      if (project[key]) {
        if (Array.isArray(project[key])) {
          for (const url of project[key]) {
            if (url?.startsWith("gs://") || url?.includes("firebase")) {
              const fileRef = ref(storage, url);
              await deleteObject(fileRef).catch((error) =>
                console.error(`Error deleting ${key} array item:`, error)
              );
            }
          }
        } else if (
          project[key]?.startsWith("gs://") ||
          project[key]?.includes("firebase")
        ) {
          const fileRef = ref(storage, project[key]);
          await deleteObject(fileRef).catch((error) =>
            console.error(`Error deleting ${key}:`, error)
          );
        }
      }
    }
  } catch (error) {
    console.error("Error in deleteProjectMedia:", error);
    throw error;
  }
};

// Order Management Helper Functions
const initializeProjectOrders = async () => {
  try {
    const q = query(collection(db, "projects"), orderBy("createdAt", "desc"));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.docs.forEach((doc, index) => {
      const projectRef = doc.ref;
      batch.update(projectRef, { order: index });
    });

    await batch.commit();
    console.log("Successfully initialized project orders");
  } catch (error) {
    console.error("Error initializing project orders:", error);
    throw error;
  }
};

const repairProjectOrders = async () => {
  try {
    const projectsRef = collection(db, "projects");
    const snapshot = await getDocs(projectsRef);
    const batch = writeBatch(db);

    snapshot.docs.forEach((doc, index) => {
      batch.update(doc.ref, { order: index });
    });

    await batch.commit();
    console.log("Project orders repaired successfully");
    return true;
  } catch (error) {
    console.error("Error repairing project orders:", error);
    throw error;
  }
};

// Main Project Operations Object
export const projectOperations = {
  // Get All Projects
  async getProjects() {
    try {
      // First attempt: Get projects ordered by order
      const q = query(collection(db, "projects"), orderBy("order", "asc"));
      const querySnapshot = await getDocs(q);

      const projects = querySnapshot.docs.map((doc, index) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          order: typeof data.order === "number" ? data.order : index,
        };
      });

      return projects.sort((a, b) => a.order - b.order);
    } catch (error) {
      console.error("Initial query error:", error);

      try {
        // Fallback: Get projects by createdAt
        const fallbackQuery = query(
          collection(db, "projects"),
          orderBy("createdAt", "desc")
        );

        const querySnapshot = await getDocs(fallbackQuery);
        const projects = querySnapshot.docs.map((doc, index) => ({
          id: doc.id,
          ...doc.data(),
          order: index,
        }));

        // Initialize orders if they're missing
        await initializeProjectOrders();
        return projects;
      } catch (fallbackError) {
        console.error("Fallback query error:", fallbackError);
        throw fallbackError;
      }
    }
  },

  // Create New Project
  async createProject(projectData, files = {}) {
    try {
      const mediaUrls = await uploadProjectMedia(files);
      const timestamp = new Date().toISOString();

      // Get highest current order
      const q = query(
        collection(db, "projects"),
        orderBy("order", "desc"),
        limit(1)
      );
      const orderSnapshot = await getDocs(q);
      const highestOrder = orderSnapshot.empty
        ? 0
        : orderSnapshot.docs[0].data().order || 0;

      const docRef = await addDoc(collection(db, "projects"), {
        ...projectData,
        ...mediaUrls,
        createdAt: timestamp,
        updatedAt: timestamp,
        order: highestOrder + 1,
      });

      return docRef.id;
    } catch (error) {
      console.error("Error creating project:", error);
      throw error;
    }
  },

  // Update Existing Project
  async updateProject(projectId, updateData, files = {}) {
    try {
      const docRef = doc(db, "projects", projectId);
      const docSnap = await getDoc(docRef);

      const mediaUrls = await uploadProjectMedia(files);
      const timestamp = new Date().toISOString();

      if (!docSnap.exists()) {
        const q = query(
          collection(db, "projects"),
          orderBy("order", "desc"),
          limit(1)
        );
        const orderSnapshot = await getDocs(q);
        const highestOrder = orderSnapshot.empty
          ? 0
          : orderSnapshot.docs[0].data().order || 0;

        await setDoc(docRef, {
          ...updateData,
          ...mediaUrls,
          createdAt: timestamp,
          updatedAt: timestamp,
          order: highestOrder + 1,
        });
      } else {
        await updateDoc(docRef, {
          ...updateData,
          ...mediaUrls,
          updatedAt: timestamp,
          order: docSnap.data().order || 0,
        });
      }

      return true;
    } catch (error) {
      console.error("Error updating project:", error);
      throw error;
    }
  },

  // Update Project Order
  async updateProjectOrder(orderUpdates) {
    try {
      const batch = writeBatch(db);

      orderUpdates.forEach(({ id, order }) => {
        const projectRef = doc(db, "projects", id);
        batch.update(projectRef, {
          order,
          updatedAt: new Date().toISOString(),
        });
      });

      await batch.commit();
      return true;
    } catch (error) {
      console.error("Error updating project order:", error);
      throw error;
    }
  },

  // Delete Project
  async deleteProject(projectId) {
    try {
      const docRef = doc(db, "projects", projectId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error("Project not found");
      }

      const project = { id: docSnap.id, ...docSnap.data() };
      await deleteProjectMedia(project);

      // Get all projects to reorder
      const q = query(collection(db, "projects"), orderBy("order", "asc"));
      const querySnapshot = await getDocs(q);
      const projects = querySnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((p) => p.id !== projectId);

      const batch = writeBatch(db);

      // Delete the project
      batch.delete(docRef);

      // Update remaining projects' orders
      projects.forEach((project, index) => {
        const ref = doc(db, "projects", project.id);
        batch.update(ref, { order: index });
      });

      await batch.commit();
      return true;
    } catch (error) {
      console.error("Error deleting project:", error);
      throw error;
    }
  },

  // Reorder All Projects
  async reorderAllProjects() {
    try {
      const q = query(collection(db, "projects"), orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);

      querySnapshot.docs.forEach((docSnapshot, index) => {
        const projectRef = doc(db, "projects", docSnapshot.id);
        batch.update(projectRef, { order: index });
      });

      await batch.commit();
      return true;
    } catch (error) {
      console.error("Error reordering all projects:", error);
      throw error;
    }
  },

  // Additional utility functions
  initializeProjectOrders,
  repairProjectOrders,
};

export default projectOperations;
