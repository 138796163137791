import React from "react";
import { motion } from "framer-motion";
import { ArrowRight, Phone, MusicIcon, Radio } from "lucide-react";
import { useNavigate } from "react-router-dom";

const WorkingProcess = () => {
  const navigate = useNavigate();

  const steps = [
    {
      number: "01",
      icon: <Phone className="w-6 h-6" />,
      title: "Let's talk",
      description:
        "Grab a quick 15-minute no-strings-attached check in call to see if we're a good fit. Bring your questions along, find out the costs, deliverables, timeline and how to put this to work.",
    },
    {
      number: "02",
      icon: <MusicIcon className="w-6 h-6" />,
      title: "Discover The Sound",
      description:
        "Is your brand a nostalgic ballad, a rock anthem, smooth jazz, a club remix, or an original classic? We help a brand find their voice and new song. Work with our team to create a unique SoundBoard that perfectly plans the brand's vibe.",
    },
    {
      number: "03",
      icon: <Radio className="w-6 h-6" />,
      title: "Bring Your Brand to Life",
      description:
        "Have the magic as we bring Sonic Branding to life. From catchy sound bites to full-on compositions, we'll make any brand unforgettable...",
    },
  ];

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 opacity-5">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(111,87,165)_1px,_transparent_0)] [background-size:40px_40px]" />
      </div>

      <div className="max-w-[1400px] mx-auto px-6">
        <div className="grid lg:grid-cols-2 gap-24">
          {/* Left Column - Intro Text */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <span className="text-primary-green mb-6 block">OUR PROCESS</span>
            <h2 className="text-5xl font-light text-white mb-8 leading-tight">
              What does working with
              <br />
              <span className="font-bold">Hear Me Out</span>
              <br />
              look like?
            </h2>
            <div className="space-y-6 text-lg text-white/80">
              <p>
                As musical artists at heart, we know the pressure of capturing
                someone's attention with a fleeting moment of magic and grabbing
                attention. The good news? Hear Me Out has honed this skill with
                decades of ears on the ground.
              </p>
              <p>
                Our music producers (the Co-Founders) are versatile,
                sound-obsessed creatives who've worked with some of the hottest
                names in the music industry (in fact, you've probably bopped
                along to our music without ever realising!).
              </p>
            </div>
          </motion.div>

          {/* Right Column - Steps */}
          <div className="relative">
            {/* Connecting Line */}
            <div className="absolute left-[41px] top-[80px] bottom-[80px] w-[1px] bg-gradient-to-b from-primary-purple/20 via-primary-purple to-primary-purple/20" />

            <div className="space-y-24">
              {steps.map((step, index) => (
                <motion.div
                  key={step.number}
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.2 }}
                  viewport={{ once: true }}
                  className="relative"
                >
                  {/* Step Number */}
                  <div className="absolute -left-8 top-0 text-4xl font-light text-white/20">
                    {step.number}
                  </div>

                  {/* Content */}
                  <div className="pl-16">
                    <div className="flex items-center gap-4 mb-4">
                      <div className="w-12 h-12 rounded-full bg-primary-purple/20 flex items-center justify-center text-primary-purple">
                        {step.icon}
                      </div>
                      <h3 className="text-2xl font-bold text-white">
                        {step.title}
                      </h3>
                    </div>
                    <p className="text-white/70 text-lg leading-relaxed">
                      {step.description}
                    </p>
                  </div>

                  {/* Arrow for continuation */}
                  {index < steps.length - 1 && (
                    <div className="absolute -left-3 top-[90px]">
                      <ArrowRight className="w-4 h-4 text-primary-purple" />
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* CTA Buttons */}
        <motion.div
          className="relative z-10 mt-24 flex flex-col sm:flex-row items-center justify-center gap-6"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
          viewport={{ once: true }}
        >
          <motion.button
            className="px-8 py-4 bg-primary-green hover:bg-primary-green/90 text-white rounded-full font-medium text-lg min-w-[200px]"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate("/services")}
          >
            SERVICES
          </motion.button>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <button
              className="px-8 py-4 border-2 border-primary-green text-primary-green hover:bg-primary-green/10 rounded-full font-medium text-lg min-w-[200px]"
              onClick={() => {
                document
                  .getElementById("contact")
                  ?.scrollIntoView({ behavior: "smooth" });
              }}
            >
              LET'S TALK
            </button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default WorkingProcess;
