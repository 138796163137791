import React from "react";
import { useParams } from "react-router-dom";
import ProjectDetail from "../Components/ProjectDetail";
import BookCallCTA from "../Components/BookCallCTA";
import { useProjects } from "../hooks/useProjects";
import { Loader } from "lucide-react";
import SEO from "../Components/SEO";

const ProjectPage = () => {
  const { slug } = useParams();
  const { projects, loading, error, getProjectBySlug } = useProjects();
  const project = getProjectBySlug(slug);

  if (loading) {
    return (
      <div className="w-full min-h-screen bg-[#111111] flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-primary-green" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-red-400">Error loading project: {error}</div>
      </div>
    );
  }

  if (!project) {
    return (
      <div className="w-full min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-white">Project not found</div>
      </div>
    );
  }

  return (
    <>
      <SEO pageType="project" pageData={project} />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <ProjectDetail project={project} clients={projects} />
        <BookCallCTA />
      </div>
    </>
  );
};

export default ProjectPage;
