import React from "react";
import { motion } from "framer-motion";
import { ArrowUpRight } from "lucide-react";

const StatSection = () => {
  return (
    <section className="relative bg-[#111111] py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0 -z-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(111,87,165)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Main Content */}
        <motion.div
          className="max-w-4xl mx-auto text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <h2 className="text-5xl font-bold text-white mb-8">
            Don't risk your brand being forgettable
          </h2>

          {/* Main Text */}
          <div className="space-y-8 mb-12">
            <p className="text-xl text-white/70 leading-relaxed">
              If you're thinking this sonic mastery is reserved for the big
              guns, think again. Hear Me Out gives even the humblest of brands a
              shot at star status with our no-nonsense process. No overblown,
              costly presentations. No dragged-out timeline. No middleman
              killing the vibe.
            </p>

            {/* Stats Section */}
            <div className="grid md:grid-cols-2 gap-8 my-16">
              <motion.div
                className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
                viewport={{ once: true }}
              >
                <div className="text-4xl font-bold text-primary-green mb-4">
                  62%
                </div>
                <p className="text-white/70">
                  of millennials are aware of the term "Sonic Branding"
                </p>
              </motion.div>

              <motion.div
                className="bg-white/5 backdrop-blur-sm rounded-2xl p-8 border border-white/10"
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6, delay: 0.4 }}
                viewport={{ once: true }}
              >
                <div className="text-4xl font-bold text-primary-green mb-4">
                  70%
                </div>
                <p className="text-white/70">
                  recognize when a brand uses a signature sound (like Intel's or
                  Netflix's audio logo)
                </p>
              </motion.div>
            </div>

            <p className="text-lg text-white/70 italic">
              According to Veritonic
            </p>
          </div>

          {/* CTA Button */}
          <motion.div
            className="relative z-10"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
            viewport={{ once: true }}
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <a
                className="group inline-flex items-center gap-3 px-8 py-4 bg-primary-green text-white rounded-full font-medium hover:bg-primary-green/90 transition-all"
                href="https://join.hearmeoutmusic.co/free-pdf"
                target="_blank"
                rel="noreferrer"
              >
                Free 'Sonic Boom' eBook
                <ArrowUpRight className="w-5 h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
              </a>
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default StatSection;
