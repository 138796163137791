import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import SoundboardProcess from "../Components/SoundboardProcess";
import SoundboardIntro from "../Components/SoundboardIntro";
import AnimatedBackground from "../Components/AnimatedBackground";
import { audioManager } from "../utils/audioContext";
import SEO from "../Components/SEO";

const Soundboard = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  return (
    <>
      <SEO
        title="Soundboard"
        description="Experience our interactive soundboard showcasing audio production and sound design capabilities"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        <AnimatedBackground>
          <main className="relative">
            <section id="soundboard-intro">
              <SoundboardIntro />
            </section>
            <section id="soundboard-process">
              <SoundboardProcess />
            </section>
          </main>
        </AnimatedBackground>
      </div>
    </>
  );
};

export default Soundboard;
