import React from "react";
import { motion } from "framer-motion";
import { Radio, Target, Fingerprint, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

const StartSection = () => {
  const navigate = useNavigate();
  const cards = [
    {
      title: "Our Process",
      description:
        "Experience a streamlined process to craft Sonic Branding assets with ease and joy.",
      icon: <Target className="w-8 h-8" />,
      buttonText: "SEE PROCESS",
      image: "",
      link: "#process",
    },
    {
      title: "SoundBoard",
      description:
        "Have confidence knowing brand audio is perfectly complementing visual branding",
      icon: <Radio className="w-8 h-8" />,
      buttonText: "EXPLORE SOUNDBOARD",
      image: "",
      link: "/soundboard",
    },
  ];

  const handleButtonClick = (link) => {
    if (!link) return;

    if (link.startsWith("#")) {
      const elementId = link.slice(1);
      const element = document.getElementById(elementId);
      element?.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate(link);
    }
  };

  return (
    <section className="bg-[#111111] relative py-32 overflow-hidden">
      {/* Background Pattern */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
      </div>

      <div className="max-w-7xl mx-auto px-6">
        {/* Header Section */}
        <motion.div
          className="text-center max-w-3xl mx-auto mb-24"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <span className="inline-block text-primary-green mb-6">
            GET STARTED
          </span>
          <h2 className="text-6xl font-light text-white mb-8 leading-tight">
            NO IDEA HOW TO START
            <br />
            <span className="font-bold">BUILDING</span>
            <br />
            <span className="font-bold">SONIC BRANDING?</span>
          </h2>

          {/* Animated Line */}
          <div className="relative h-px w-32 mx-auto my-8 overflow-hidden">
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-transparent via-primary-green to-transparent"
              animate={{
                x: ["-100%", "100%"],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "linear",
              }}
            />
          </div>

          <p className="text-white/80 text-xl">
            We have an effective method that takes brands from bland to bold.
          </p>
        </motion.div>

        {/* Cards Section */}
        <div className="grid md:grid-cols-2 gap-8">
          {cards.map((card, index) => (
            <motion.div
              key={card.title}
              className="group relative"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              {/* Card Content */}
              <div className="relative z-10 h-full bg-white/5 backdrop-blur-sm rounded-2xl p-8 hover:bg-white/10 transition-all duration-500">
                <div className="flex items-center gap-4 mb-6">
                  <div className="w-16 h-16 rounded-xl bg-primary-green/20 flex items-center justify-center text-primary-green">
                    {card.icon}
                  </div>
                  <h3 className="text-3xl font-bold text-white">
                    {card.title}
                  </h3>
                </div>

                <p className="text-white/70 text-lg mb-8 leading-relaxed">
                  {card.description}
                </p>

                <motion.button
                  className="flex items-center gap-2 px-8 py-4 bg-transparent border-2 border-primary-green text-primary-green rounded-full group-hover:bg-primary-green group-hover:text-white transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleButtonClick(card.link)}
                >
                  <span className="font-medium">{card.buttonText}</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </motion.button>
              </div>

              {/* Gradient Border */}
              <div className="absolute inset-0 rounded-2xl bg-gradient-to-br from-primary-green/20 to-primary-purple/20 -z-10 blur-sm group-hover:blur-md transition-all duration-500" />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default StartSection;
