import React, { useState, useEffect } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { Link } from "react-router-dom";
import ProjectNavigation from "./ProjectNavigation";
import { getOptimizedImageUrl } from "../utils/imageOptimization";
import {
  ICON_MAP,
  WHAT_ICONS,
  WHERE_ICONS,
  CREDITS_ICONS,
} from "../utils/projectIcons";

const getIconComponent = (iconId) => {
  return ICON_MAP[iconId] || null;
};

const TextBlock = ({ title, content, index, icons = [] }) => {
  const ref = React.useRef(null);
  const isVisible = useInView(ref, { once: true, amount: 0.3 });
  const isActive = isVisible || window.innerWidth < 768;

  const isCredits = title === "Credits";
  const credits = isCredits && Array.isArray(content) ? content : null;

  return (
    <motion.div
      ref={ref}
      className={`group relative rounded-lg transition-colors overflow-hidden cursor-default
        md:bg-[#111111] md:hover:bg-[#1a1a1a]
        ${isActive ? "bg-[#1a1a1a]" : "bg-[#111111]"}`}
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: isVisible ? 1 : 0,
        y: isVisible ? 0 : 20,
      }}
      transition={{ delay: 0.1 * index }}
    >
      {/* Gradient overlay */}
      <div
        className={`absolute inset-0 transition-opacity duration-500
        md:opacity-0 md:group-hover:opacity-100
        ${isActive ? "opacity-100" : "opacity-0"}`}
      >
        <div className="absolute inset-0 bg-gradient-to-br from-primary-purple/5 via-transparent to-primary-green/5" />
      </div>

      {/* Content */}
      <div className="relative p-8 h-full flex flex-col">
        <h2
          className={`text-xl font-bold mb-4 transition-colors duration-500
            ${isActive ? "text-white" : "text-white/90"}`}
        >
          {title}
        </h2>

        {/* Icons Container */}
        {icons.length > 0 && (
          <div className="flex flex-col space-y-4 mb-6">
            {icons.map((iconId, i) => {
              const IconComponent = getIconComponent(iconId);
              const iconConfig = [
                ...WHAT_ICONS,
                ...WHERE_ICONS,
                ...CREDITS_ICONS,
              ].find((config) => config.id === iconId);

              if (!IconComponent || !iconConfig) return null;

              return (
                <IconBox
                  key={i}
                  IconComponent={IconComponent}
                  label={iconConfig.label}
                  isActive={isActive}
                />
              );
            })}
          </div>
        )}

        {isCredits ? (
          <div className="space-y-3">
            {credits.map((credit, index) => (
              <div
                key={index}
                className="bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white/90"
              >
                {credit}
              </div>
            ))}
            {credits.length === 0 && (
              <p className="text-white/50 italic">No credits available</p>
            )}
          </div>
        ) : (
          <p
            className={`text-base leading-relaxed transition-colors duration-500
              ${isActive ? "text-white/90" : "text-white/70"}`}
          >
            {content}
          </p>
        )}
      </div>
    </motion.div>
  );
};

const IconBox = ({ IconComponent, label, isActive }) => {
  return (
    <motion.div
      className="flex items-center space-x-4"
      whileHover={{ scale: 1.05 }}
      initial={false}
    >
      {/* Icon */}
      <div
        className={`
          w-12 h-12 rounded-lg backdrop-blur-sm
          bg-white/5 border border-white/10
          transition-all duration-300 ease
          group-hover:bg-white/10
          flex items-center justify-center
          ${isActive ? "bg-white/10" : ""}
        `}
      >
        <IconComponent className="w-8 h-8 text-primary-green" />
      </div>
      {/* Label */}
      <p className="text-white/90">{label}</p>
    </motion.div>
  );
};

const MediaBlock = ({ src, alt, index }) => {
  const ref = React.useRef(null);
  const isVisible = useInView(ref, { once: true, amount: 0.3 });
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  // // Debug logging
  // console.log("MediaBlock render:", { src, isLoading, hasError });

  return (
    <motion.div
      ref={ref}
      key={`media-${index}`}
      className="aspect-[3/2] rounded-lg overflow-hidden relative bg-[#111111]"
      initial={{ opacity: 0, y: 20 }}
      animate={{
        opacity: isVisible ? 1 : 0,
        y: isVisible ? 0 : 20,
      }}
      transition={{ delay: 0.1 * (index + 1) }}
    >
      {/* Loading skeleton */}
      <AnimatePresence>
        {isLoading && !hasError && (
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute inset-0 bg-white/5 animate-pulse"
          />
        )}
      </AnimatePresence>

      {/* Error state */}
      {hasError && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/5">
          <p className="text-white/50">Failed to load image</p>
        </div>
      )}

      {/* Image */}
      {src && (
        <img
          src={src}
          alt={alt}
          loading="lazy"
          className={`w-full h-full object-cover transition-all duration-700 ease-out
            ${isLoading && !hasError ? "scale-105 blur-lg" : "scale-100 blur-0"}
            ${hasError ? "hidden" : ""}`}
          onLoad={() => {
            // console.log("Image loaded:", src);
            setIsLoading(false);
            setHasError(false);
          }}
          onError={(e) => {
            console.error("Image load error:", src, e);
            setIsLoading(false);
            setHasError(true);
          }}
        />
      )}
    </motion.div>
  );
};

const ProjectDetail = ({ project, clients }) => {
  const [preloadedImages, setPreloadedImages] = useState(new Set());
  const { ref: gridRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const imageGridSection = project?.sections?.find(
      (s) => s.type === "imageGrid"
    );

    if (inView && imageGridSection?.data.images) {
      // Preload images when section comes into view
      imageGridSection.data.images.forEach((imageUrl) => {
        if (!preloadedImages.has(imageUrl)) {
          const img = new Image();
          img.src = imageUrl;
          img.onload = () => {
            setPreloadedImages((prev) => new Set([...prev, imageUrl]));
          };
        }
      });
    }
  }, [inView, project, preloadedImages]);

  if (!project) return null;

  const videoSection = project.sections.find((s) => s.type === "video");
  const overviewSection = project.sections.find((s) => s.type === "overview");
  const imageGridSection = project.sections.find((s) => s.type === "imageGrid");
  const feedbackSection = project.sections.find((s) => s.type === "feedback");

  const mediaBlocks = imageGridSection?.data.images
    .slice(0, 2)
    .map((image, index) => {
      const isPreloaded = preloadedImages.has(image);
      return (
        <MediaBlock
          key={`media-${index}`}
          src={image}
          alt={`Project detail ${index + 1}`}
          index={index}
          isPreloaded={isPreloaded}
        />
      );
    });

  const textBlocks = [
    {
      id: "what-they-loved",
      title: "What",
      content: feedbackSection?.data.whatTheyLoved,
      icons: feedbackSection?.data.whatIcons || [],
    },
    {
      id: "what-we-loved",
      title: "Where",
      content: feedbackSection?.data.whatWeLoved,
      icons: feedbackSection?.data.whereIcons || [],
    },
    {
      id: "the-result",
      title: "Who",
      content: feedbackSection?.data.result || [],
      icons: feedbackSection?.data.creditIcons || [],
    },
  ];

  return (
    <div className="min-h-screen bg-[#111111]">
      <section className="relative">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
        </div>
        <div className="relative">
          <div className="animate-fadeIn">
            {/* Header Section */}
            <section className="pt-32 pb-16">
              <div className="max-w-7xl mx-auto px-6">
                <div className="mb-4 text-primary-green">Project</div>
                <div className="flex justify-between items-start">
                  <div className="max-w-4xl">
                    <h1 className="text-4xl md:text-7xl font-light text-white/90 mb-6">
                      {project.title}
                    </h1>
                    {overviewSection && (
                      <p className="text-xl text-white/70">
                        {overviewSection.data.brief}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </section>

            {/* Content Blocks - Desktop */}
            <div className="hidden md:block max-w-7xl mx-auto px-6 mb-auto">
              <div className="grid grid-cols-12 gap-6" ref={gridRef}>
                {/* Main Video */}
                <div className="col-span-8">
                  <motion.div
                    className="aspect-[16/9] rounded-lg overflow-hidden relative bg-[#111111]"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                  >
                    <video
                      src={videoSection?.data.videoUrl}
                      poster={videoSection?.data.posterImage}
                      controls
                      preload="none"
                      className="w-full h-full object-cover"
                    />
                  </motion.div>
                </div>

                {/* Side Images */}
                <div className="col-span-4 grid grid-rows-2 gap-6">
                  {mediaBlocks}
                </div>
              </div>

              {/* Text Blocks */}
              <div className="grid grid-cols-3 gap-6 mt-12">
                {textBlocks.map((block, index) => (
                  <TextBlock
                    key={block.id}
                    title={block.title}
                    content={block.content}
                    index={index}
                    icons={block.icons}
                  />
                ))}
              </div>
            </div>

            {/* Content Blocks - Mobile */}
            <div className="md:hidden max-w-7xl mx-auto px-6 mb-auto space-y-6">
              {/* Main Video */}
              <motion.div
                className="aspect-[16/9] rounded-lg overflow-hidden relative bg-[#111111]"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <video
                  src={videoSection?.data.videoUrl}
                  poster={videoSection?.data.posterImage}
                  controls
                  preload="none"
                  className="w-full h-full object-cover"
                />
              </motion.div>

              {/* Alternating Content */}
              {textBlocks.map((block, index) => (
                <React.Fragment key={block.id}>
                  <TextBlock
                    title={block.title}
                    content={block.content}
                    index={index}
                    icons={block.icons}
                  />
                  {mediaBlocks[index]}
                </React.Fragment>
              ))}
            </div>

            {/* Back Navigation */}
            <div className="fixed top-8 left-8 z-50">
              <Link to="/client-work">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="flex items-center gap-2 text-white/70 hover:text-white"
                >
                  <ArrowLeft className="w-5 h-4" />
                  Back
                </motion.button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <ProjectNavigation currentProject={project} allProjects={clients} />
    </div>
  );
};

export default ProjectDetail;
