import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { blogOperations } from "../utils/blogFirestore";
import { ArrowLeft, Loader, Calendar, Tag } from "lucide-react";
import Navigation from "../Components/Navigation";
import { audioManager } from "../utils/audioContext";
import BlogSectionRenderer from "../Components/BlogSectionRenderer";
import SEO from "../Components/SEO";

const BlogPost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const fetchedPost = await blogOperations.getPost(postId);
        setPost(fetchedPost);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [postId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <Loader className="w-8 h-8 animate-spin text-primary-green" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#111111] flex items-center justify-center">
        <div className="text-white/70 text-center">
          <p>Error: {error}</p>
          <button
            onClick={() => navigate("/blog")}
            className="mt-4 px-6 py-2 bg-primary-green/90 rounded-full hover:scale-105 transition-transform"
          >
            Back to Blog
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEO pageType="blog" pageData={post} />

      <div className="min-h-screen bg-[#111111] relative overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        {/* Background effects */}
        <div className="fixed inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
        <div className="fixed inset-0 bg-gradient-to-b from-primary-purple/5 via-transparent to-primary-green/5" />

        <div className="relative pt-32 pb-16">
          <div className="container max-w-4xl mx-auto px-4 sm:px-6">
            {/* Back button */}
            <motion.button
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              onClick={() => navigate("/blog")}
              className="flex items-center gap-2 text-white/70 hover:text-white mb-8 group"
            >
              <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
              Back to Blog
            </motion.button>

            {post && (
              <motion.article
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="relative bg-[#111111]/80 backdrop-blur-xl rounded-2xl border border-white/5 overflow-hidden"
              >
                {/* Featured Image */}
                {post.featuredImage && (
                  <div className="relative h-64 md:h-96 w-full">
                    <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#111111]" />
                    <img
                      src={post.featuredImage}
                      alt={post.title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                )}

                {/* Content */}
                <div className="p-6 md:p-12 space-y-8">
                  {/* Metadata */}
                  <div className="flex flex-wrap items-center gap-4 text-sm text-white/60">
                    <div className="flex items-center gap-2">
                      <Calendar className="w-4 h-4" />
                      <span>{post.date.toLocaleDateString()}</span>
                    </div>
                    {post.category && (
                      <div className="flex items-center gap-2">
                        <Tag className="w-4 h-4" />
                        <span className="text-primary-green">
                          {post.category}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Title */}
                  <h1 className="text-4xl md:text-5xl font-light text-white">
                    {post.title}
                  </h1>

                  {/* Excerpt */}
                  {post.excerpt && (
                    <p className="text-xl text-white/70 font-light">
                      {post.excerpt}
                    </p>
                  )}

                  {/* Content Sections */}
                  <div className="space-y-8 pt-8">
                    {post.sections.map((section, index) => (
                      <BlogSectionRenderer key={index} section={section} />
                    ))}
                  </div>
                </div>
              </motion.article>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPost;
