// src/Pages/Services.js
import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import ServicesGrid from "../Components/ServicesGrid";
import BookCallCTA from "../Components/BookCallCTA";
import { audioManager } from "../utils/audioContext";
import SEO from "../Components/SEO";

const Services = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);

  return (
    <>
      <SEO
        title="Services"
        description="Professional audio production, sound design, and sonic branding services for your brand"
        type="website"
      />
      <div className="w-full min-h-screen bg-[#111111] overflow-hidden">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        <main className="relative">
          {" "}
          {/* Added main wrapper for better semantic structure */}
          <section id="services">
            <ServicesGrid audioManager={audioManager} />
          </section>
          <section id="contact">
            {" "}
            {/* Added separate section for CTA */}
            <BookCallCTA audioManager={audioManager} />{" "}
            {/* Added audioManager prop */}
          </section>
        </main>
      </div>
    </>
  );
};

export default Services;
