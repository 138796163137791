import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Plus,
  Edit,
  Trash2,
  Loader,
  LogOut,
  FileText,
  Briefcase,
  GripVertical,
} from "lucide-react";
import { motion } from "framer-motion";
import ProjectEditor from "./ProjectEditor";
import BlogEditor from "./BlogEditor";
import { projectOperations } from "../../utils/projectFirestore";
import { blogOperations } from "../../utils/blogFirestore";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./alert-dialog";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// Define the SortableItem component first, before the main component
const SortableItem = ({ id, item, activeTab, onEdit, onDelete }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`bg-white/5 rounded-lg border ${
        isDragging ? "border-primary-green/20" : "border-white/10"
      } p-6`}
    >
      <div className="flex justify-between items-start">
        <div className="flex items-start gap-4">
          <div {...attributes} {...listeners} className="cursor-move pt-1">
            <GripVertical className="w-4 h-4 text-white/40" />
          </div>
          <div>
            <h3 className="text-xl font-semibold text-white mb-2">
              {item.title}
            </h3>
            <div className="flex items-center gap-4 text-sm text-white/70">
              <span className="px-2 py-1 rounded-full bg-white/10">
                {activeTab === "projects" ? item.type : item.category}
              </span>
              <span>
                Last modified:{" "}
                {item.updatedAt
                  ? new Date(item.updatedAt).toLocaleDateString()
                  : "N/A"}
              </span>
              <span
                className={`px-2 py-1 rounded-full ${
                  item.status === "published"
                    ? "bg-primary-green/20 text-primary-green"
                    : "bg-yellow-500/20 text-yellow-500"
                }`}
              >
                {item.status || "draft"}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onEdit}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <Edit className="w-4 h-4" />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={onDelete}
            className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            <Trash2 className="w-4 h-4" />
          </motion.button>
        </div>
      </div>
    </div>
  );
};

const AdminDashboard = ({ onLogout }) => {
  const [activeTab, setActiveTab] = useState("projects"); // or "blogs"
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [error, setError] = useState(null);
  const [fetchStatus, setFetchStatus] = useState("loading");
  const navigate = useNavigate();
  const [items, setItems] = useState([]);

  const repairOrdering = async () => {
    try {
      setFetchStatus("loading");
      await projectOperations.repairProjectOrders();
      await fetchItems(); // Refresh the list
      setFetchStatus("success");
    } catch (error) {
      console.error("Error repairing order:", error);
      setError(error);
      setFetchStatus("error");
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((prevItems) => {
        const oldIndex = prevItems.findIndex((item) => item.id === active.id);
        const newIndex = prevItems.findIndex((item) => item.id === over.id);

        const newItems = arrayMove(prevItems, oldIndex, newIndex);

        // Update order in Firebase
        projectOperations.updateProjectOrder(
          newItems.map((item, index) => ({
            id: item.id,
            order: index,
          }))
        );

        return newItems;
      });
    }
  };

  // Get the correct operations based on active tab
  const operations =
    activeTab === "projects" ? projectOperations : blogOperations;

  const fetchItems = useCallback(async () => {
    try {
      setFetchStatus("loading");
      const fetchedItems =
        activeTab === "projects"
          ? await projectOperations.getProjects()
          : await blogOperations.getPosts();
      setItems(fetchedItems);
      setFetchStatus("success");
    } catch (error) {
      console.error(`Error fetching ${activeTab}:`, error);
      setError(error.message);
      setFetchStatus("error");
    }
  }, [activeTab]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems, activeTab]);

  const handleLogout = async () => {
    try {
      await onLogout();
      navigate("/");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleCreateNew = () => {
    const timestamp = new Date().toISOString();

    if (activeTab === "projects") {
      setSelectedItem({
        title: "New Project",
        type: "tv-commercial",
        status: "draft",
        createdAt: timestamp,
        updatedAt: timestamp,
        sections: [
          { type: "brand", data: { logo: "" } },
          { type: "video", data: { videoUrl: "", posterImage: "" } },
          { type: "overview", data: { brief: "", overview: "" } },
          {
            type: "feedback",
            data: { whatTheyLoved: "", whatWeLoved: "", result: "" },
          },
          { type: "imageGrid", data: { images: [] } },
        ],
      });
    } else {
      setSelectedItem({
        title: "New Post",
        content: "",
        excerpt: "",
        category: "",
        status: "draft",
        author: "",
        featuredImage: "",
        createdAt: timestamp,
        updatedAt: timestamp,
      });
    }
    setIsEditorOpen(true);
  };

  const handleSave = async (updatedItem) => {
    try {
      setFetchStatus("loading");
      console.log("Starting save operation:", {
        activeTab,
        selectedItem,
        updatedItem,
      });

      if (activeTab === "projects") {
        if (selectedItem?.id) {
          // Ensure we're passing the ID and data correctly
          console.log("Updating project:", selectedItem.id, updatedItem);
          await projectOperations.updateProject(selectedItem.id, {
            ...updatedItem,
            status: updatedItem.status || "draft",
            updatedAt: new Date().toISOString(),
          });
        } else {
          console.log("Creating new project");
          await projectOperations.createProject({
            ...updatedItem,
            status: updatedItem.status || "draft",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      } else {
        if (selectedItem?.id) {
          console.log("Updating blog post:", selectedItem.id, updatedItem);
          await blogOperations.updatePost(selectedItem.id, {
            ...updatedItem,
            status: updatedItem.status || "draft",
            updatedAt: new Date().toISOString(),
          });
        } else {
          console.log("Creating new blog post");
          await blogOperations.createPost({
            ...updatedItem,
            status: updatedItem.status || "draft",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
          });
        }
      }

      // Only clear the editor and refresh after successful save
      setIsEditorOpen(false);
      setSelectedItem(null);
      await fetchItems();
      setFetchStatus("success");
    } catch (error) {
      console.error("Error in handleSave:", error);
      setError(error.message);
      setFetchStatus("error");
    }
  };

  // Updated handleDelete function
  const handleDelete = async () => {
    if (!deleteItem) return;

    try {
      setFetchStatus("loading");
      if (activeTab === "projects") {
        await projectOperations.deleteProject(deleteItem.id);
      } else {
        await blogOperations.deletePost(deleteItem.id);
      }
      await fetchItems();
      setDeleteItem(null);
    } catch (error) {
      console.error(`Error deleting ${activeTab.slice(0, -1)}:`, error);
      setError(error.message);
      setFetchStatus("error");
    }
  };

  return (
    <div className="min-h-screen bg-[#111111]">
      <div className="border-b border-white/10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-6">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-white">
              Content Management
            </h1>
            <div className="flex items-center gap-4">
              {(error || items.length === 0) && (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={repairOrdering}
                  className="px-4 py-2 rounded-full bg-primary-purple/90 text-white flex items-center gap-2"
                >
                  <Loader className="w-4 h-4" />
                  Repair Project Order
                </motion.button>
              )}

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleLogout}
                className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
              >
                <LogOut className="w-4 h-4" />
                Sign Out
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleCreateNew}
                className="px-4 py-2 rounded-full bg-primary-green/90 text-white flex items-center gap-2"
              >
                <Plus className="w-4 h-4" />
                New {activeTab === "projects" ? "Project" : "Post"}
              </motion.button>
            </div>
          </div>

          {/* Content Type Tabs */}
          <div className="flex gap-4 mt-6">
            <button
              onClick={() => setActiveTab("projects")}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors duration-200 ${
                activeTab === "projects"
                  ? "bg-primary-green/90 text-white"
                  : "bg-white/5 text-white/70 hover:bg-white/10"
              }`}
            >
              <Briefcase className="w-4 h-4" />
              Projects
            </button>
            <button
              onClick={() => setActiveTab("blogs")}
              className={`flex items-center gap-2 px-4 py-2 rounded-full transition-colors duration-200 ${
                activeTab === "blogs"
                  ? "bg-primary-green/90 text-white"
                  : "bg-white/5 text-white/70 hover:bg-white/10"
              }`}
            >
              <FileText className="w-4 h-4" />
              Blog Posts
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
        {fetchStatus === "loading" ? (
          <div className="flex flex-col items-center justify-center h-64">
            <Loader className="w-8 h-8 animate-spin text-primary-green mb-4" />
            <p className="text-white/70">Loading {activeTab}...</p>
          </div>
        ) : fetchStatus === "error" ? (
          <div className="flex flex-col items-center justify-center h-64 text-red-400">
            <p>
              Error loading {activeTab}: {error}
            </p>
            <button
              onClick={fetchItems}
              className="mt-4 px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
            >
              Try Again
            </button>
          </div>
        ) : !isEditorOpen ? (
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items.map((item) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              <div className="grid gap-6">
                {items
                  .filter(
                    (item, index, self) =>
                      index === self.findIndex((t) => t.id === item.id)
                  )
                  .map((item) => (
                    <SortableItem
                      key={`${activeTab}-${item.id}`}
                      id={item.id}
                      item={item}
                      activeTab={activeTab}
                      onEdit={() => {
                        setSelectedItem({
                          ...item,
                          updatedAt: item.updatedAt || new Date().toISOString(),
                        });
                        setIsEditorOpen(true);
                      }}
                      onDelete={() => setDeleteItem(item)}
                    />
                  ))}
              </div>
            </SortableContext>
          </DndContext>
        ) : activeTab === "projects" ? (
          <ProjectEditor
            project={selectedItem}
            onClose={() => {
              setIsEditorOpen(false);
              setSelectedItem(null);
            }}
            onSave={handleSave}
            isNew={!selectedItem?.id}
          />
        ) : (
          <BlogEditor
            post={selectedItem}
            onClose={() => {
              setIsEditorOpen(false);
              setSelectedItem(null);
            }}
            onSave={handleSave}
            isNew={!selectedItem?.id}
          />
        )}
      </div>

      <AlertDialog
        open={Boolean(deleteItem)}
        onOpenChange={() => setDeleteItem(null)}
      >
        <AlertDialogContent className="relative p-6 bg-[#111111] border border-primary-purple/20 rounded-lg backdrop-blur-xl shadow-xl shadow-primary-green/5">
          <AlertDialogHeader className="space-y-4">
            <AlertDialogTitle className="text-2xl font-light text-white">
              Delete{" "}
              <span className="font-bold text-primary-purple">
                {deleteItem?.title}
              </span>
              ?
            </AlertDialogTitle>
            <AlertDialogDescription className="text-base text-white/70">
              <div className="p-4 bg-white/5 border border-white/10 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <div className="w-2 h-2 rounded-full bg-primary-green animate-pulse"></div>
                  <span className="text-primary-green font-medium">
                    Warning:
                  </span>
                </div>
                This action cannot be undone. This will permanently delete this{" "}
                {activeTab.slice(0, -1)} and all associated data.
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="flex items-center gap-3 pt-6">
            <button
              onClick={() => setDeleteItem(null)}
              className="flex-1 px-4 py-3 rounded-full bg-white/5 text-white 
                   hover:bg-white/10 transition-colors duration-200
                   border border-white/10 font-medium"
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="flex-1 px-4 py-3 rounded-full bg-primary-purple 
                   text-white hover:bg-primary-purple/90 transition-colors duration-200
                   border border-primary-purple/20 font-medium"
            >
              Delete {activeTab.slice(0, -1)}
            </button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default AdminDashboard;
