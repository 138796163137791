// components/layouts/ClientWorkLayout.jsx
import React, { useState, useRef, useEffect } from "react";
import { motion, useTransform, useScroll } from "framer-motion";

const ClientWorkLayout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start start", "end end"],
  });

  const logoY = useTransform(scrollYProgress, [0, 1], ["-50%", "30%"]);
  const logoScale = useTransform(scrollYProgress, [0, 1], [1.5, 1.8]);
  const logoRotate = useTransform(scrollYProgress, [0, 1], [-5, 10]);
  const noiseY = useTransform(scrollYProgress, [0, 1], ["-20%", "0%"]);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  return (
    <div className="min-h-screen bg-[#111111] relative" ref={containerRef}>
      {/* Background Effects */}
      <div className="fixed inset-0 pointer-events-none overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#111111] via-transparent to-[#111111] z-10" />

        <motion.div
          initial={{ opacity: 0, scale: 1.2 }}
          animate={{ opacity: 0.3 }}
          style={{
            y: isMobile ? logoY : 0,
            scale: isMobile ? logoScale : 1,
            rotate: isMobile ? logoRotate : [0, 3, -3, 0],
          }}
          transition={{
            opacity: { duration: 2 },
            rotate: {
              repeat: Infinity,
              duration: 20,
              ease: "easeInOut",
            },
          }}
          className="absolute inset-0 z-0"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <img
              src="/HEAR ME OUT - Logo - Purple.svg"
              alt=""
              className="w-[200%] md:w-[150%] max-w-none opacity-50 -mt-[25%]"
              style={{ filter: "blur(2px)" }}
            />
          </div>
        </motion.div>

        <motion.div
          style={{ y: isMobile ? noiseY : 0 }}
          className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5 z-20"
        />
      </div>

      {/* Content */}
      <div className="relative z-30">{children}</div>
    </div>
  );
};

export default ClientWorkLayout;
