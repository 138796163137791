import React from "react";
import { Helmet } from "react-helmet-async";

// Default site-wide metadata
const defaultMetadata = {
  siteName: "Hear Me Out Music Co.",
  description:
    "Audio production and sound design studio creating memorable sonic experiences for brands.",
  themeColor: "#111111",
  socialImage: "/HEAR ME OUT - Logo - White _ Purple.svg",
  twitterHandle: "", // Your Twitter handle
  instagramHandle: "@hearmeoutmusic.co", // Your Instagram handle
  linkedinUrl: "https://www.linkedin.com/company/hear-me-outmusic-co", // Your LinkedIn company page
  siteUrl: "https://hearmeoutmusic.co", // Your production URL
};

const metadataTemplates = {
  project: (data) => ({
    title: `${data.title} | ${defaultMetadata.siteName}`,
    description: data.brief || defaultMetadata.description,
    image: data.posterImage || defaultMetadata.socialImage,
    type: "article",
  }),
  blog: (data) => ({
    title: `${data.title} | Blog | ${defaultMetadata.siteName}`,
    description: data.excerpt || defaultMetadata.description,
    image: data.featuredImage || defaultMetadata.socialImage,
    type: "article",
  }),
  service: (data) => ({
    title: `${data.name} | Services | ${defaultMetadata.siteName}`,
    description:
      data.description ||
      `Professional ${data.name.toLowerCase()} services by Hear Me Out Music Co.`,
    image: data.serviceImage || defaultMetadata.socialImage,
    type: "service",
  }),
};

const SEO = ({
  title,
  description,
  image,
  type = "website",
  noindex = false,
  pageData = null,
  pageType = null,
}) => {
  // If pageType and pageData are provided, use the template
  const metadata =
    pageType && pageData
      ? metadataTemplates[pageType](pageData)
      : {
          title: title
            ? `${title} | ${defaultMetadata.siteName}`
            : defaultMetadata.siteName,
          description: description || defaultMetadata.description,
          image: image || defaultMetadata.socialImage,
          type,
        };

  const currentUrl =
    typeof window !== "undefined"
      ? window.location.href
      : defaultMetadata.siteUrl;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{metadata.title}</title>
      <meta name="description" content={metadata.description} />
      <meta name="theme-color" content={defaultMetadata.themeColor} />
      {noindex && <meta name="robots" content="noindex" />}

      {/* Open Graph */}
      <meta property="og:type" content={metadata.type} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      <meta property="og:image" content={metadata.image} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content={defaultMetadata.siteName} />

      {/* Twitter Card */}
      {defaultMetadata.twitterHandle && (
        <>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content={defaultMetadata.twitterHandle} />
          <meta name="twitter:title" content={metadata.title} />
          <meta name="twitter:description" content={metadata.description} />
          <meta name="twitter:image" content={metadata.image} />
        </>
      )}

      {/* Instagram */}
      {defaultMetadata.instagramHandle && (
        <meta
          property="og:see_also"
          content={`https://www.instagram.com/${defaultMetadata.instagramHandle.replace(
            "@",
            ""
          )}`}
        />
      )}

      {/* LinkedIn */}
      {defaultMetadata.linkedinUrl && (
        <meta property="og:see_also" content={defaultMetadata.linkedinUrl} />
      )}

      {/* Social Media Schema.org markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          name: defaultMetadata.siteName,
          url: defaultMetadata.siteUrl,
          logo: defaultMetadata.socialImage,
          sameAs: [
            defaultMetadata.instagramHandle &&
              `https://www.instagram.com/${defaultMetadata.instagramHandle.replace(
                "@",
                ""
              )}`,
            defaultMetadata.linkedinUrl,
            defaultMetadata.twitterHandle &&
              `https://twitter.com/${defaultMetadata.twitterHandle.replace(
                "@",
                ""
              )}`,
          ].filter(Boolean),
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            url: `${defaultMetadata.siteUrl}/contact`,
          },
        })}
      </script>

      {/* Additional metadata */}
      <link rel="canonical" href={currentUrl} />
    </Helmet>
  );
};

export default SEO;
