class AudioManager {
  constructor() {
    this.audioContext = null;
    this.analyzer = null;
    this.gainNode = null;
    this.splitterNode = null;
    this.initialized = false;
    this.audioBuffers = new Map();
    this.currentSequenceIndex = 0;

    // Define visualization frequencies for each note
    this.visualFrequencies = [
      500, // Very low
      1500, // Low
      3000, // Low-mid
      5000, // Mid
      8000, // Mid-high
      12000, // High
      16000, // Very high
    ];

    // Define the sound sequence
    this.soundSequence = [
      {
        path: "/sounds/menu-bar-1.mp3",
        frequencyRange: {
          low: 100,
          mid: 500,
          high: 2000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-2.mp3",
        frequencyRange: {
          low: 200,
          mid: 1000,
          high: 4000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-3.mp3",
        frequencyRange: {
          low: 300,
          mid: 1500,
          high: 6000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-4.mp3",
        frequencyRange: {
          low: 400,
          mid: 2000,
          high: 8000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-5.mp3",
        frequencyRange: {
          low: 500,
          mid: 2500,
          high: 10000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-6.mp3",
        frequencyRange: {
          low: 600,
          mid: 3000,
          high: 12000,
        },
        gain: 0.15,
      },
      {
        path: "/sounds/menu-bar-7.mp3",
        frequencyRange: {
          low: 700,
          mid: 3500,
          high: 14000,
        },
        gain: 0.15,
      },
    ];
  }

  async initialize() {
    if (this.initialized) return this.audioContext;

    try {
      this.audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      await this.audioContext.resume();

      // Adjusted analyzer settings for better blend
      this.analyzer = this.audioContext.createAnalyser();
      this.analyzer.fftSize = 2048;
      this.analyzer.minDecibels = -60;
      this.analyzer.maxDecibels = -20;
      this.analyzer.smoothingTimeConstant = 0.85;

      this.gainNode = this.audioContext.createGain();
      this.splitterNode = this.audioContext.createGain();

      this.splitterNode.connect(this.analyzer);
      this.splitterNode.connect(this.gainNode);
      this.gainNode.connect(this.audioContext.destination);

      await this.preloadSounds();

      this.initialized = true;
      return this.audioContext;
    } catch (error) {
      console.error("Failed to initialize AudioManager:", error);
      this.initialized = false;
      return null;
    }
  }

  async preloadSounds() {
    try {
      const loadPromises = this.soundSequence.map((sound) =>
        this.loadSound(sound.path)
      );
      await Promise.all(loadPromises);
      console.log("All sounds loaded successfully");
    } catch (error) {
      console.error("Error loading sounds:", error);
    }
  }

  async loadSound(path) {
    try {
      const response = await fetch(path);
      const arrayBuffer = await response.arrayBuffer();
      const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);
      this.audioBuffers.set(path, audioBuffer);
    } catch (error) {
      console.error(`Error loading sound: ${path}`, error);
    }
  }

  setVolume(isMuted) {
    if (this.gainNode) {
      this.gainNode.gain.value = isMuted ? 0 : 1;
      console.log("Volume set to:", isMuted ? "muted" : "unmuted");
    }
  }

  cleanupCurrentSound() {
    if (this.currentSoundNodes) {
      const { source, soundGain, ghostOscillator, ghostGain, filters } =
        this.currentSoundNodes;

      // Rapid fadeout
      const now = this.audioContext.currentTime;
      soundGain.gain.exponentialRampToValueAtTime(0.001, now + 0.1);
      ghostGain.gain.exponentialRampToValueAtTime(0.001, now + 0.1);

      // Cleanup after fadeout
      setTimeout(() => {
        source?.stop();
        ghostOscillator?.stop();
        source?.disconnect();
        ghostOscillator?.disconnect();
        soundGain?.disconnect();
        ghostGain?.disconnect();
        filters.forEach((filter) => filter?.disconnect());
      }, 100);

      this.currentSoundNodes = null;
    }
  }

  async playSound(type, position = 0) {
    if (!this.initialized || !this.audioContext) return;

    // Cleanup any currently playing sound
    this.cleanupCurrentSound();

    try {
      const soundConfig = this.soundSequence[this.currentSequenceIndex];
      if (!this.audioBuffers.has(soundConfig.path)) return;

      // Create nodes
      const source = this.audioContext.createBufferSource();
      const soundGain = this.audioContext.createGain();
      const ghostOscillator = this.audioContext.createOscillator();
      const ghostGain = this.audioContext.createGain();

      // Create filters
      const filters = [
        this.audioContext.createBiquadFilter(),
        this.audioContext.createBiquadFilter(),
        this.audioContext.createBiquadFilter(),
      ];

      // Set up filters
      const filterTypes = ["lowpass", "bandpass", "highpass"];
      filters.forEach((filter, i) => {
        filter.type = filterTypes[i];
        filter.frequency.value =
          this.visualFrequencies[this.currentSequenceIndex] * (i + 0.5);
        filter.Q.value = 1;
      });

      // Configure source
      source.buffer = this.audioBuffers.get(soundConfig.path);

      // Set up ghost oscillator
      ghostOscillator.type = "sine";
      ghostOscillator.frequency.setValueAtTime(
        this.visualFrequencies[this.currentSequenceIndex],
        this.audioContext.currentTime
      );

      // Set gains
      const baseGain =
        type === "hover" ? soundConfig.gain * 0.7 : soundConfig.gain;
      soundGain.gain.value = baseGain;
      ghostGain.gain.setValueAtTime(0.02, this.audioContext.currentTime);

      // Connect nodes
      source.connect(soundGain);
      soundGain.connect(this.gainNode);

      filters.forEach((filter) => {
        source.connect(filter);
        filter.connect(this.analyzer);
      });

      ghostOscillator.connect(ghostGain);
      ghostGain.connect(this.analyzer);

      // Store current nodes for cleanup
      this.currentSoundNodes = {
        source,
        soundGain,
        ghostOscillator,
        ghostGain,
        filters,
      };

      // Start playback
      source.start();
      ghostOscillator.start();

      // Set fade out
      ghostGain.gain.exponentialRampToValueAtTime(
        0.001,
        this.audioContext.currentTime + 0.3
      );

      // Auto cleanup
      source.onended = () => {
        if (this.currentSoundNodes?.source === source) {
          this.cleanupCurrentSound();
        }
      };

      this.currentSequenceIndex =
        (this.currentSequenceIndex + 1) % this.soundSequence.length;
    } catch (error) {
      console.error("Error playing sound:", error);
    }
  }

  getAnalyzer() {
    return this.analyzer;
  }

  isInitialized() {
    return this.initialized;
  }

  resume() {
    if (this.audioContext?.state === "suspended") {
      this.audioContext.resume();
    }
  }

  suspend() {
    if (this.audioContext?.state === "running") {
      this.audioContext.suspend();
    }
  }

  resetSequence() {
    this.currentSequenceIndex = 0;
  }

  setSequencePosition(index) {
    this.currentSequenceIndex = index % this.soundSequence.length;
  }

  // Optional method to customize visualization frequencies
  setVisualizationFrequencies(frequencies) {
    if (
      Array.isArray(frequencies) &&
      frequencies.length === this.soundSequence.length
    ) {
      this.visualFrequencies = frequencies;
    }
  }
}

export const audioManager = new AudioManager();
