import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import {
  ArrowUpRight,
  Plus,
  X,
  Upload,
  Trash2,
  Save,
  Link as LinkIcon,
  Loader,
  Check,
} from "lucide-react";
import {
  WHAT_ICONS,
  WHERE_ICONS,
  CREDITS_ICONS,
} from "../../utils/projectIcons";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import { projectOperations } from "../../utils/projectFirestore";

const ProjectEditor = ({ project, onClose, onSave, value, onChange }) => {
  const [uploadProgress, setUploadProgress] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(() => {
    // If we have a project, ensure the feedback section has required arrays
    if (project) {
      // Ensure project.type is always an array
      const projectType = Array.isArray(project.type)
        ? project.type
        : project.type
        ? [project.type] // If string, convert to array
        : []; // If undefined/null, use empty array

      const feedbackSection = project.sections.find(
        (s) => s.type === "feedback"
      );
      if (feedbackSection) {
        feedbackSection.data = {
          ...feedbackSection.data,
          whatIcons: feedbackSection.data.whatIcons || [],
          whereIcons: feedbackSection.data.whereIcons || [],
          creditIcons: feedbackSection.data.creditIcons || [],
          result: Array.isArray(feedbackSection.data.result)
            ? feedbackSection.data.result
            : [],
        };
      }
      return {
        ...project,
        type: projectType, // Use the properly formatted type
      };
    }

    // Default initial state for new project
    return {
      title: "",
      slug: "",
      type: [],
      status: "draft",
      sections: [
        { type: "brand", data: { logo: "" } },
        { type: "video", data: { videoUrl: "", posterImage: "" } },
        { type: "overview", data: { brief: "", overview: "" } },
        {
          type: "feedback",
          data: {
            whatTheyLoved: "",
            whatIcons: [],
            whatWeLoved: "",
            whereIcons: [],
            result: [], // Ensure this is initialized as an empty array
            creditIcons: [],
          },
        },
        { type: "imageGrid", data: { images: [] } },
      ],
    };
  });

  const [isTypeMenuOpen, setIsTypeMenuOpen] = useState(false);

  const projectTypes = [
    { value: "tv-commercial", label: "TV/Film Composition" },
    { value: "sonic-branding", label: "Sonic Branding" },
    { value: "soundboard", label: "Soundboard" },
    { value: "sonic-logo", label: "Sonic Logo" },
    { value: "ad-track", label: "Advertising" },
    { value: "app-sounds", label: "App/UI" },
    { value: "podcast-sounds", label: "Podcast" },
    { value: "soundscape", label: "Immersive Brand Experiences" },
  ];

  const handleTypeSelect = (typeValue) => {
    setFormData((prev) => {
      const currentTypes = [...prev.type];
      const typeIndex = currentTypes.indexOf(typeValue);

      if (typeIndex > -1) {
        // Remove type if already selected
        currentTypes.splice(typeIndex, 1);
      } else if (currentTypes.length < 3) {
        // Add type if under limit
        currentTypes.push(typeValue);
      }

      return {
        ...prev,
        type: currentTypes,
      };
    });
  };

  // Add debug logging to help track the state

  const feedbackSection = formData.sections.find((s) => s.type === "feedback");
  console.log("Feedback section data:", feedbackSection?.data);

  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef({});

  const handleSectionChange = (sectionType, field, value) => {
    setFormData((prev) => ({
      ...prev,
      sections: prev.sections.map((section) =>
        section.type === sectionType
          ? { ...section, data: { ...section.data, [field]: value } }
          : section
      ),
    }));
  };

  // Modified addCredit function to ensure we're working with arrays
  const addCredit = () => {
    setFormData((prev) => {
      const updatedSections = prev.sections.map((section) => {
        if (section.type === "feedback") {
          return {
            ...section,
            data: {
              ...section.data,
              result: Array.isArray(section.data.result)
                ? [...section.data.result, ""]
                : [""],
            },
          };
        }
        return section;
      });
      return { ...prev, sections: updatedSections };
    });
  };

  // Modified updateCredit function
  const updateCredit = (index, value) => {
    setFormData((prev) => {
      const updatedSections = prev.sections.map((section) => {
        if (section.type === "feedback") {
          const currentResults = Array.isArray(section.data.result)
            ? [...section.data.result]
            : [];
          currentResults[index] = value;
          return {
            ...section,
            data: {
              ...section.data,
              result: currentResults,
            },
          };
        }
        return section;
      });
      return { ...prev, sections: updatedSections };
    });
  };

  // Modified removeCredit function
  const removeCredit = (index) => {
    setFormData((prev) => {
      const updatedSections = prev.sections.map((section) => {
        if (section.type === "feedback") {
          const currentResults = Array.isArray(section.data.result)
            ? section.data.result.filter((_, i) => i !== index)
            : [];
          return {
            ...section,
            data: {
              ...section.data,
              result: currentResults,
            },
          };
        }
        return section;
      });
      return { ...prev, sections: updatedSections };
    });
  };

  const handleMediaUpload = async (sectionType, field, file) => {
    try {
      setIsLoading(true);
      const fileId = `${sectionType}-${field}`;
      setUploadProgress((prev) => ({ ...prev, [fileId]: 0 }));

      let path;
      switch (sectionType) {
        case "brand":
          path = "logos";
          break;
        case "video":
          path = field === "videoUrl" ? "videos" : "posters";
          break;
        case "imageGrid":
          path = "images";
          break;
        default:
          path = "other";
      }

      const storageRef = ref(storage, `${path}/${Date.now()}_${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prev) => ({ ...prev, [fileId]: progress }));
        },
        (error) => {
          console.error("Upload error:", error);
          throw error;
        }
      );

      await uploadTask;
      const url = await getDownloadURL(storageRef);

      setUploadProgress((prev) => {
        const newProgress = { ...prev };
        delete newProgress[fileId];
        return newProgress;
      });

      handleSectionChange(sectionType, field, url);
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageGridUpload = async (files) => {
    try {
      setIsLoading(true);
      const uploadPromises = Array.from(files).map(async (file) => {
        const storageRef = ref(storage, `images/${Date.now()}_${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
        });

        await uploadTask;
        return getDownloadURL(storageRef);
      });

      const urls = await Promise.all(uploadPromises);
      const section = formData.sections.find((s) => s.type === "imageGrid");
      // Ensure we're always working with an array
      const currentImages = section.data.images || [];
      const updatedImages = [...currentImages, ...urls];

      handleSectionChange("imageGrid", "images", updatedImages);
    } catch (error) {
      console.error("Image grid upload error:", error);
    } finally {
      setIsLoading(false);
      setUploadProgress({});
    }
  };

  const handleSubmit = async (e, shouldPublish = false) => {
    e.preventDefault();
    if (isSaving) {
      console.log("Submission blocked - already saving");
      return;
    }

    console.log("Starting submission process", { isSaving, shouldPublish });
    setIsSaving(true);

    try {
      const dataToSubmit = {
        ...formData,
        status: shouldPublish ? "published" : "draft",
        updatedAt: new Date().toISOString(),
      };

      console.log("Preparing to submit data:", {
        isNewProject: !project?.id,
        projectId: project?.id,
        dataToSubmit,
      });

      // Call onSave only once and wait for it
      await onSave(dataToSubmit);
      console.log("Save completed successfully");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      console.log("Resetting saving state");
      setIsSaving(false);
    }
  };

  const renderUploadButton = (sectionType, field) => {
    const inputId = `${sectionType}-${field}`;
    const progress = uploadProgress[inputId];

    return (
      <motion.button
        type="button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => fileInputRef.current[inputId].click()}
        className="p-2 rounded-full bg-primary-green/20 text-primary-green relative overflow-hidden"
        disabled={isLoading}
      >
        {progress !== undefined ? (
          <>
            <div
              className="absolute inset-0 bg-primary-green/20"
              style={{ width: `${progress}%` }}
            />
            <span className="relative z-10">{Math.round(progress)}%</span>
          </>
        ) : (
          <Upload className="w-4 h-4" />
        )}
      </motion.button>
    );
  };

  return (
    <div className="bg-[#111111] min-h-screen">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="max-w-4xl mx-auto py-8 px-4"
      >
        <div className="mb-8 flex justify-between items-center">
          <h2 className="text-2xl font-bold text-white">
            {project ? "Edit Project" : "New Project"}
          </h2>
          {/* Close and Save Buttons */}
          <div className="flex gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onClose}
              type="button" // Add this
              className="px-4 py-2 rounded-full bg-white/5 text-white hover:bg-white/10"
            >
              <X className="w-4 h-4" />
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button" // Change from submit to button
              onClick={(e) => handleSubmit(e, false)}
              disabled={isSaving}
              className="px-6 py-2 rounded-full bg-white/5 text-white hover:bg-white/10 flex items-center gap-2"
            >
              {isSaving ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                <>
                  Save as Draft
                  <Save className="w-4 h-4" />
                </>
              )}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="button" // Change from submit to button
              onClick={(e) => handleSubmit(e, true)}
              disabled={isSaving}
              className="px-6 py-2 rounded-full bg-primary-green/90 text-white hover:bg-primary-green flex items-center gap-2"
            >
              {isSaving ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                <>
                  Publish
                  <ArrowUpRight className="w-4 h-4" />
                </>
              )}
            </motion.button>
          </div>
        </div>

        {/* Basic Info */}
        <div className="space-y-6 mb-8">
          <div>
            <label className="block text-sm font-medium text-white/70 mb-2">
              Title
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-white/70 mb-2">
              Slug
            </label>
            <input
              type="text"
              value={formData.slug}
              onChange={(e) =>
                setFormData({ ...formData, slug: e.target.value })
              }
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
            />
          </div>
          <div className="relative">
            <label className="block text-sm font-medium text-white/70 mb-2">
              Type (Select up to 3)
            </label>
            <div className="relative">
              {/* Selected Types Display */}
              <div
                onClick={() => setIsTypeMenuOpen(!isTypeMenuOpen)}
                className="w-full bg-[#111111] border border-white/10 rounded-lg px-4 py-2 text-primary-green 
          cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary-green/20
          hover:border-primary-green/20 transition-colors min-h-[40px] flex flex-wrap gap-2"
              >
                {formData.type.length > 0 ? (
                  formData.type.map((type) => (
                    <div
                      key={type}
                      className="bg-primary-green/10 text-primary-green px-2 py-1 rounded-full flex items-center gap-1"
                    >
                      <span>
                        {projectTypes.find((t) => t.value === type)?.label}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTypeSelect(type);
                        }}
                        className="hover:bg-primary-green/20 rounded-full p-1"
                      >
                        <X className="w-3 h-3" />
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="text-white/50">Select project types...</span>
                )}
              </div>

              {/* Dropdown Menu */}
              {isTypeMenuOpen && (
                <div className="absolute z-50 w-full mt-2 bg-[#111111] border border-white/10 rounded-lg shadow-lg max-h-60 overflow-y-auto">
                  {projectTypes.map((type) => (
                    <motion.button
                      key={type.value}
                      onClick={() => handleTypeSelect(type.value)}
                      className={`w-full px-4 py-2 text-left flex items-center justify-between
                ${
                  formData.type.includes(type.value)
                    ? "text-primary-green bg-primary-green/10"
                    : "text-white hover:bg-white/5"
                }`}
                      whileHover={{
                        backgroundColor: formData.type.includes(type.value)
                          ? "rgba(1, 178, 128, 0.2)"
                          : "rgba(255, 255, 255, 0.05)",
                      }}
                      disabled={
                        formData.type.length >= 3 &&
                        !formData.type.includes(type.value)
                      }
                    >
                      {type.label}
                      {formData.type.includes(type.value) && (
                        <Check className="w-4 h-4" />
                      )}
                    </motion.button>
                  ))}
                </div>
              )}
            </div>
            <div className="text-xs text-white/50 mt-1">
              {3 - formData.type.length} selection
              {formData.type.length === 2 ? "" : "s"} remaining
            </div>
          </div>
        </div>

        {/* Sections */}
        {formData.sections.map((section, index) => (
          <div
            key={section.type}
            className="mb-8 p-6 bg-white/5 rounded-lg border border-white/10"
          >
            <h3 className="text-lg font-semibold text-white mb-4 capitalize">
              {section.type.replace("-", " ")}
            </h3>

            {section.type === "brand" && (
              <div className="space-y-4">
                <label className="block text-sm font-medium text-white/70">
                  Logo
                </label>
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={section.data.logo}
                    onChange={(e) =>
                      handleSectionChange("brand", "logo", e.target.value)
                    }
                    className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                    placeholder="Enter logo URL or upload file"
                  />
                  <input
                    type="file"
                    ref={(el) => (fileInputRef.current["brand-logo"] = el)}
                    onChange={(e) =>
                      handleMediaUpload("brand", "logo", e.target.files[0])
                    }
                    className="hidden"
                    accept="image/*"
                  />
                  {renderUploadButton("brand", "logo")}
                </div>
              </div>
            )}

            {section.type === "video" && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Video
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      value={section.data.videoUrl}
                      onChange={(e) =>
                        handleSectionChange("video", "videoUrl", e.target.value)
                      }
                      className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                      placeholder="Enter video URL or upload file"
                    />
                    <input
                      type="file"
                      ref={(el) =>
                        (fileInputRef.current["video-videoUrl"] = el)
                      }
                      onChange={(e) =>
                        handleMediaUpload(
                          "video",
                          "videoUrl",
                          e.target.files[0]
                        )
                      }
                      className="hidden"
                      accept="video/*"
                    />
                    {renderUploadButton("video", "videoUrl")}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Poster Image
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="text"
                      value={section.data.posterImage}
                      onChange={(e) =>
                        handleSectionChange(
                          "video",
                          "posterImage",
                          e.target.value
                        )
                      }
                      className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                      placeholder="Enter poster image URL or upload file"
                    />
                    <input
                      type="file"
                      ref={(el) =>
                        (fileInputRef.current["video-posterImage"] = el)
                      }
                      onChange={(e) =>
                        handleMediaUpload(
                          "video",
                          "posterImage",
                          e.target.files[0]
                        )
                      }
                      className="hidden"
                      accept="image/*"
                    />
                    {renderUploadButton("video", "posterImage")}
                  </div>
                </div>
              </div>
            )}

            {section.type === "overview" && (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Brief
                  </label>
                  <textarea
                    value={section.data.brief}
                    onChange={(e) =>
                      handleSectionChange("overview", "brief", e.target.value)
                    }
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[100px]"
                  />
                </div>
                {/* <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Overview
                  </label>
                  <textarea
                    value={section.data.overview}
                    onChange={(e) =>
                      handleSectionChange(
                        "overview",
                        "overview",
                        e.target.value
                      )
                    }
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[100px]"
                  />
                </div> */}
              </div>
            )}

            {section.type === "feedback" && (
              <div className="space-y-4">
                {/* What section stays the same */}
                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    What - Select Icons (up to 5)
                  </label>
                  <div className="flex gap-2 mb-2">
                    {WHAT_ICONS.map((iconOption) => {
                      const Icon = iconOption.icon;
                      const isSelected = section.data.whatIcons?.includes(
                        iconOption.id
                      );
                      return (
                        <motion.button
                          key={iconOption.id}
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            const currentIcons = section.data.whatIcons || [];
                            let newIcons;
                            if (isSelected) {
                              newIcons = currentIcons.filter(
                                (id) => id !== iconOption.id
                              );
                            } else if (currentIcons.length < 5) {
                              newIcons = [...currentIcons, iconOption.id];
                            }
                            if (newIcons) {
                              handleSectionChange(
                                "feedback",
                                "whatIcons",
                                newIcons
                              );
                            }
                          }}
                          className={`p-3 rounded-lg ${
                            isSelected
                              ? "bg-primary-green text-white"
                              : "bg-white/5 text-white/70 hover:bg-white/10"
                          }`}
                          title={iconOption.label}
                        >
                          <Icon className="w-5 h-5" />
                        </motion.button>
                      );
                    })}
                  </div>
                  <textarea
                    value={section.data.whatTheyLoved}
                    onChange={(e) =>
                      handleSectionChange(
                        "feedback",
                        "whatTheyLoved",
                        e.target.value
                      )
                    }
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[100px]"
                  />
                </div>

                {/* Where section */}
                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Where - Select Platform Icons (up to 3)
                  </label>
                  <div className="flex gap-2 mb-2">
                    {WHERE_ICONS.map((iconOption) => {
                      const Icon = iconOption.icon;
                      const isSelected = section.data.whereIcons?.includes(
                        iconOption.id
                      );
                      return (
                        <motion.button
                          key={iconOption.id}
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            const currentIcons = section.data.whereIcons || [];
                            let newIcons;
                            if (isSelected) {
                              newIcons = currentIcons.filter(
                                (id) => id !== iconOption.id
                              );
                            } else if (currentIcons.length < 3) {
                              newIcons = [...currentIcons, iconOption.id];
                            }
                            if (newIcons) {
                              handleSectionChange(
                                "feedback",
                                "whereIcons",
                                newIcons
                              );
                            }
                          }}
                          className={`p-3 rounded-lg ${
                            isSelected
                              ? "bg-primary-green text-white"
                              : "bg-white/5 text-white/70 hover:bg-white/10"
                          }`}
                          title={iconOption.label}
                        >
                          <Icon className="w-5 h-5" />
                        </motion.button>
                      );
                    })}
                  </div>
                  <textarea
                    value={section.data.whatWeLoved}
                    onChange={(e) =>
                      handleSectionChange(
                        "feedback",
                        "whatWeLoved",
                        e.target.value
                      )
                    }
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[100px]"
                  />
                </div>

                {/* Updated Credits section */}
                <div>
                  <label className="block text-sm font-medium text-white/70 mb-2">
                    Who - Select Icon
                  </label>
                  <div className="flex gap-2 mb-4">
                    {CREDITS_ICONS.map((iconOption) => {
                      const Icon = iconOption.icon;
                      const isSelected = section.data.creditIcons?.includes(
                        iconOption.id
                      );
                      return (
                        <motion.button
                          key={iconOption.id}
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            const currentIcons = section.data.creditIcons || [];
                            let newIcons;
                            if (isSelected) {
                              newIcons = currentIcons.filter(
                                (id) => id !== iconOption.id
                              );
                            } else if (currentIcons.length < 1) {
                              newIcons = [...currentIcons, iconOption.id];
                            }
                            if (newIcons) {
                              handleSectionChange(
                                "feedback",
                                "creditIcons",
                                newIcons
                              );
                            }
                          }}
                          className={`p-3 rounded-lg ${
                            isSelected
                              ? "bg-primary-green text-white"
                              : "bg-white/5 text-white/70 hover:bg-white/10"
                          }`}
                          title={iconOption.label}
                        >
                          <Icon className="w-5 h-5" />
                        </motion.button>
                      );
                    })}
                  </div>

                  {/* Credits input section */}
                  <div className="space-y-4">
                    <div className="flex justify-between items-center">
                      <h3 className="text-sm font-medium text-white/70">
                        Credits
                      </h3>
                      <motion.button
                        type="button"
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={addCredit}
                        className="px-3 py-1 rounded-full bg-primary-green/20 text-primary-green text-sm flex items-center gap-2"
                      >
                        <Plus className="w-4 h-4" />
                        Add Credit
                      </motion.button>
                    </div>

                    {/* Credits section with safe array handling */}
                    <div className="space-y-3">
                      {Array.isArray(section.data.result) &&
                        section.data.result.map((credit, index) => (
                          <div key={index} className="flex items-center gap-3">
                            <input
                              type="text"
                              value={credit}
                              onChange={(e) =>
                                updateCredit(index, e.target.value)
                              }
                              placeholder="Enter credit (e.g., Sky Bingo - Flutter PLC)"
                              className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                            />
                            <motion.button
                              type="button"
                              whileHover={{ scale: 1.05 }}
                              whileTap={{ scale: 0.95 }}
                              onClick={() => removeCredit(index)}
                              className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                            >
                              <Trash2 className="w-4 h-4" />
                            </motion.button>
                          </div>
                        ))}
                    </div>

                    {(!section.data.result ||
                      section.data.result.length === 0) && (
                      <p className="text-white/50 text-sm italic">
                        No credits added yet
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}

            {section.type === "imageGrid" && (
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-4 mb-4">
                  {section.data.images.map((image, imgIndex) => (
                    <div key={imgIndex} className="relative group">
                      <img
                        src={image}
                        alt=""
                        className="w-full h-32 object-cover rounded-lg"
                      />
                      <button
                        type="button"
                        onClick={() => {
                          const updatedImages = section.data.images.filter(
                            (_, i) => i !== imgIndex
                          );
                          handleSectionChange(
                            "imageGrid",
                            "images",
                            updatedImages
                          );
                        }}
                        className="absolute top-2 right-2 p-1 rounded-full bg-red-500/90 text-white opacity-0 group-hover:opacity-100 transition-opacity"
                      >
                        <Trash2 className="w-4 h-4" />
                      </button>
                    </div>
                  ))}
                </div>

                {/* Add URL Input */}
                <div className="flex gap-4 items-center">
                  <input
                    type="text"
                    placeholder="Enter image URL"
                    className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        const url = e.target.value.trim();
                        if (url) {
                          const currentImages = section.data.images || [];
                          handleSectionChange("imageGrid", "images", [
                            ...currentImages,
                            url,
                          ]);
                          e.target.value = ""; // Clear input after adding
                        }
                      }
                    }}
                  />
                  <motion.button
                    type="button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={(e) => {
                      const input = e.target
                        .closest(".flex")
                        .querySelector("input");
                      const url = input.value.trim();
                      if (url) {
                        const currentImages = section.data.images || [];
                        handleSectionChange("imageGrid", "images", [
                          ...currentImages,
                          url,
                        ]);
                        input.value = ""; // Clear input after adding
                      }
                    }}
                    className="px-4 py-2 rounded-full bg-primary-green/20 text-primary-green border border-primary-green/20"
                  >
                    Add URL
                  </motion.button>
                </div>

                <div className="flex items-center">
                  <div className="flex-1 border-t border-white/10"></div>
                  <span className="px-4 text-white/50 text-sm">OR</span>
                  <div className="flex-1 border-t border-white/10"></div>
                </div>

                {/* File Upload Section */}
                <input
                  type="file"
                  ref={(el) => (fileInputRef.current["imageGrid"] = el)}
                  onChange={(e) => handleImageGridUpload(e.target.files)}
                  className="hidden"
                  multiple
                  accept="image/*"
                />
                <motion.button
                  type="button"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => fileInputRef.current["imageGrid"].click()}
                  className="w-full py-2 rounded-full bg-primary-green/20 text-primary-green border border-primary-green/20 flex items-center justify-center gap-2"
                >
                  <Plus className="w-4 h-4" />
                  Upload Images
                </motion.button>
              </div>
            )}
          </div>
        ))}
      </form>
    </div>
  );
};

export default ProjectEditor;
