import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import imageCompression from "browser-image-compression";

export const getOptimizedImageUrl = (url, options = {}) => {
  const { width = 800, quality = 80, format = "webp" } = options;

  // If it's a Firebase Storage URL, append optimization parameters
  if (url?.includes("firebasestorage.googleapis.com")) {
    // Extract the path from the URL
    const path = url.split("?")[0];
    return `${path}?fm=${format}&w=${width}&q=${quality}&fit=max`;
  }

  return url;
};

// Function to compress and convert image before upload
export const optimizeAndUploadImage = async (file, path) => {
  try {
    // Compression options
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      fileType: "image/webp",
    };

    // Compress the image
    const compressedFile = await imageCompression(file, options);

    // Create storage reference with metadata
    const storageRef = ref(
      storage,
      `${path}/${Date.now()}_${file.name.replace(/\.[^/.]+$/, "")}.webp`
    );

    // Set metadata for the upload
    const metadata = {
      contentType: "image/webp",
      cacheControl: "public, max-age=31536000",
    };

    // Upload and get URL
    const snapshot = await uploadBytes(storageRef, compressedFile, metadata);
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error("Error optimizing and uploading image:", error);
    throw error;
  }
};
