import React, { useState, useRef } from "react";
import { motion } from "framer-motion";

import { audioManager } from "../utils/audioContext";
import { Play, Pause } from "lucide-react";

const processSteps = [
  {
    title: "What is a SoundBoard?",
    description:
      "A SoundBoard paves the way for consistent sound usage across every brand to drive more sales through increased engagement and recall. It is the first step in any Sonic Branding journey.",
    number: "01",
    videoUrl:
      "https://video.wixstatic.com/video/ac6faa_531c96c5a3254c328fa67aeee36d0e1c/1080p/mp4/file.mp4", // Replace with actual URL
  },
  {
    title: "How to use a SoundBoard?",
    description:
      "There might be questions about where to apply the SoundBoard and in what context. Here are the most common examples used by brands to help them cut through the noise and be bold market leaders.",
    number: "02",
    videoUrl:
      "https://video.wixstatic.com/video/ac6faa_dd8c92e8d32e4f648bfa316f32da5343/1080p/mp4/file.mp4", // Replace with actual URL
  },
  {
    title: "Onboarding",
    description:
      "Elevating brands through a cost-effective, innovative Sonic Branding audio solution that sparks excitement. You focus on growing the brand, we'll focus on making it sound great and be more memorable.",
    number: "03",
    videoUrl:
      "https://video.wixstatic.com/video/ac6faa_e6710c7c90fe4b169afbc8ba983a91c7/1080p/mp4/file.mp4", // Replace with actual URL
  },
  {
    title: "Creating the SoundBoard",
    description:
      "We create custom SoundBoards for every client: A mood board for music. We dive deep into the brand's DNA to collate a customised selection of sound samples and reference tracks tailored to your brand's ethos and values.",
    number: "04",
    videoUrl:
      "https://video.wixstatic.com/video/ac6faa_48ab3a80d7994630a4a8336818c63823/1080p/mp4/file.mp4", // Replace with actual URL
  },
];

const ProcessStep = ({ title, description, number, videoUrl, index }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6, delay: index * 0.2 }}
      className="bg-[#6F56A5]/30 backdrop-blur-xl border border-white/5 p-8 rounded-lg"
    >
      <div className="flex flex-col gap-4">
        <span className="text-primary-green font-medium text-xl">{number}</span>
        <h3 className="text-2xl font-bold text-white/90">{title}</h3>
        <p className="text-white/70 text-lg">{description}</p>

        {/* <div className="mt-4 relative aspect-video bg-black/20 rounded-lg overflow-hidden">
          <video
            ref={videoRef}
            className="w-full h-full object-cover cursor-pointer"
            src={videoUrl}
            poster={videoUrl + "?format=jpg"}
            playsInline
            onClick={handlePlayPause}
          />

          {!isPlaying && (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handlePlayPause}
              className="absolute inset-0 flex items-center justify-center bg-black/30 group"
            >
              <div className="w-16 h-16 rounded-full bg-primary-green/90 flex items-center justify-center group-hover:scale-105 transition-transform">
                {isPlaying ? (
                  <Pause className="w-8 h-8 text-white" />
                ) : (
                  <Play className="w-8 h-8 text-white translate-x-0.5" />
                )}
              </div>
            </motion.button>
          )}
        </div> */}
      </div>
    </motion.div>
  );
};

const SoundBoardProcess = () => {
  return (
    <div>
      <div className="min-h-screen py-24 relative">
        {/* Background elements */}
        <div className="absolute inset-0 pointer-events-none">
          <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="absolute inset-0"
            // style={{
            //   background:
            //     "linear-gradient(135deg, rgba(111,87,165,0.05), transparent 50%, rgba(1,178,128,0.05))",
            // }}
          />
        </div>

        {/* Content */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-7xl font-light text-white/90">
              So how does it <span className="font-bold">work?</span>
            </h2>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {processSteps.map((step, index) => (
              <ProcessStep key={step.number} {...step} index={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundBoardProcess;
