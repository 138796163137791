// utils/blogFirestore.js
import {
  collection,
  query,
  orderBy,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";

export const blogOperations = {
  getPosts: async () => {
    try {
      const postsRef = collection(db, "blog-posts");
      const q = query(postsRef, orderBy("date", "desc"));
      const querySnapshot = await getDocs(q);

      return querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date?.toDate() || new Date(),
          updatedAt: data.updatedAt?.toDate() || new Date(),
        };
      });
    } catch (error) {
      console.error("Error fetching posts:", error);
      throw error;
    }
  },

  createPost: async (postData) => {
    try {
      const postsRef = collection(db, "blog-posts");
      const timestamp = Timestamp.fromDate(new Date());
      const newPost = {
        ...postData,
        date: timestamp,
        updatedAt: timestamp,
      };
      const docRef = await addDoc(postsRef, newPost);
      return docRef.id;
    } catch (error) {
      console.error("Error creating post:", error);
      throw error;
    }
  },

  updatePost: async (postId, postData) => {
    try {
      const postRef = doc(db, "blog-posts", postId);
      const updateData = {
        ...postData,
        updatedAt: Timestamp.fromDate(new Date()),
      };
      await updateDoc(postRef, updateData);
    } catch (error) {
      console.error("Error updating post:", error);
      throw error;
    }
  },

  getPost: async (postId) => {
    try {
      const postRef = doc(db, "blog-posts", postId);
      const docSnap = await getDoc(postRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          id: docSnap.id,
          ...data,
          date: data.date?.toDate() || new Date(),
          updatedAt: data.updatedAt?.toDate() || new Date(),
        };
      }
      throw new Error("Post not found");
    } catch (error) {
      console.error("Error fetching post:", error);
      throw error;
    }
  },

  deletePost: async (postId) => {
    try {
      const postRef = doc(db, "blog-posts", postId);
      await deleteDoc(postRef);
    } catch (error) {
      console.error("Error deleting post:", error);
      throw error;
    }
  },
};
