import React, { useState } from "react";
import {
  X,
  Upload,
  Loader,
  Film,
  Image as ImageIcon,
  Plus,
  GripVertical,
  Type,
  Heading,
  Link as LinkIcon,
} from "lucide-react";
import { motion } from "framer-motion";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const SECTION_TYPES = {
  TITLE: "title",
  TEXT: "text",
  IMAGE: "image",
  VIDEO: "video",
  SUBTITLE: "subtitle",
};

// Generate a unique ID
const generateId = () => Math.random().toString(36).substr(2, 9);

const SortableItem = ({ section, onRemove, renderSection }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: section.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`relative group bg-white/5 rounded-lg border ${
        isDragging ? "border-primary-green/20" : "border-white/10"
      } p-4`}
    >
      <div className="flex items-center justify-between mb-4">
        <div
          className="flex items-center gap-2 cursor-move"
          {...attributes}
          {...listeners}
        >
          <GripVertical className="w-4 h-4 text-white/40" />
          <span className="text-sm font-medium text-white/70">
            {section.type.charAt(0).toUpperCase() + section.type.slice(1)}
          </span>
        </div>
        <button
          type="button"
          onClick={() => onRemove(section.id)}
          className="p-1 rounded-full bg-white/5 hover:bg-white/10 text-white opacity-0 group-hover:opacity-100 transition-opacity"
        >
          <X className="w-4 h-4" />
        </button>
      </div>
      {renderSection(section)}
    </div>
  );
};

const BlogEditor = ({ post, onClose, onSave }) => {
  const [loading, setLoading] = useState(false);
  const [urlInputs, setUrlInputs] = useState({});
  const [activeId, setActiveId] = useState(null);

  const [metadata, setMetadata] = useState({
    title: post?.title || "",
    excerpt: post?.excerpt || "",
    category: post?.category || "",
    status: post?.status || "draft",
    author: post?.author || "",
    featuredImage: post?.featuredImage || "",
  });

  const [sections, setSections] = useState(() => {
    const initialSections = post?.sections || [
      { type: SECTION_TYPES.TEXT, content: "" },
    ];
    return initialSections.map((section) => ({
      ...section,
      id: generateId(),
    }));
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleMetadataChange = (e) => {
    const { name, value } = e.target;
    setMetadata((prev) => ({ ...prev, [name]: value }));
  };

  const handleSectionChange = (sectionId, content) => {
    setSections((prev) =>
      prev.map((section) =>
        section.id === sectionId ? { ...section, content } : section
      )
    );
  };

  const addSection = (type) => {
    setSections((prev) => [
      ...prev,
      {
        id: generateId(),
        type,
        content: type === SECTION_TYPES.TEXT ? "" : null,
      },
    ]);
  };

  const removeSection = (sectionId) => {
    setSections((prev) => prev.filter((section) => section.id !== sectionId));
    setUrlInputs((prev) => {
      const newInputs = { ...prev };
      delete newInputs[sectionId];
      return newInputs;
    });
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveId(null);

    if (over && active.id !== over.id) {
      setSections((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleImageUpload = async (e, sectionId) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `blog-images/${Date.now()}_${file.name}`);
      await uploadBytes(storageRef, file);
      const imageUrl = await getDownloadURL(storageRef);

      if (sectionId !== undefined) {
        handleSectionChange(sectionId, imageUrl);
      } else {
        setMetadata((prev) => ({ ...prev, featuredImage: imageUrl }));
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUrlSubmit = (sectionId) => {
    const url = urlInputs[sectionId];
    if (url) {
      handleSectionChange(sectionId, url);
      setUrlInputs((prev) => ({ ...prev, [sectionId]: "" }));
    }
  };

  const renderSection = (section) => {
    switch (section.type) {
      case SECTION_TYPES.TEXT:
        return (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white/70">
              Content
            </label>
            <textarea
              value={section.content}
              onChange={(e) => handleSectionChange(section.id, e.target.value)}
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[200px]"
              placeholder="Write your content here..."
            />
          </div>
        );

      case SECTION_TYPES.SUBTITLE:
        return (
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white/70">
              Subtitle
            </label>
            <input
              type="text"
              value={section.content}
              onChange={(e) => handleSectionChange(section.id, e.target.value)}
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
              placeholder="Enter subtitle..."
            />
          </div>
        );

      case SECTION_TYPES.IMAGE:
        return (
          <div className="space-y-4">
            <label className="block text-sm font-medium text-white/70">
              Image
            </label>
            {section.content && (
              <div className="relative w-full h-48 mb-4 overflow-hidden rounded-lg">
                <img
                  src={section.content}
                  alt=""
                  className="object-cover w-full h-full"
                />
                <button
                  onClick={() => handleSectionChange(section.id, null)}
                  className="absolute top-2 right-2 p-2 rounded-full bg-black/50 hover:bg-black/70 text-white"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            )}
            <div className="flex flex-col space-y-4">
              {/* URL Input */}
              <div className="flex space-x-2">
                <input
                  type="text"
                  value={urlInputs[section.id] || ""}
                  onChange={(e) =>
                    setUrlInputs((prev) => ({
                      ...prev,
                      [section.id]: e.target.value,
                    }))
                  }
                  placeholder="Enter image URL..."
                  className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                />
                <button
                  onClick={() => handleUrlSubmit(section.id)}
                  className="px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10 text-white"
                >
                  <LinkIcon className="w-4 h-4" />
                </button>
              </div>
              {/* Upload Button */}
              <div className="flex justify-center">
                <button
                  type="button"
                  onClick={() =>
                    document
                      .getElementById(`image-upload-${section.id}`)
                      .click()
                  }
                  className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
                >
                  <Upload className="w-4 h-4" />
                  Upload Image
                </button>
                <input
                  id={`image-upload-${section.id}`}
                  type="file"
                  onChange={(e) => handleImageUpload(e, section.id)}
                  accept="image/*"
                  className="hidden"
                />
              </div>
            </div>
          </div>
        );

      case SECTION_TYPES.VIDEO:
        return (
          <div className="space-y-4">
            <label className="block text-sm font-medium text-white/70">
              Video
            </label>
            {section.content && (
              <div className="relative w-full aspect-video mb-4 rounded-lg overflow-hidden">
                <iframe
                  src={section.content}
                  className="w-full h-full"
                  allowFullScreen
                  title="Video content"
                />
                <button
                  onClick={() => handleSectionChange(section.id, null)}
                  className="absolute top-2 right-2 p-2 rounded-full bg-black/50 hover:bg-black/70 text-white"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            )}
            <div className="flex space-x-2">
              <input
                type="text"
                value={urlInputs[section.id] || ""}
                onChange={(e) =>
                  setUrlInputs((prev) => ({
                    ...prev,
                    [section.id]: e.target.value,
                  }))
                }
                className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                placeholder="Enter video URL (YouTube or Vimeo)..."
              />
              <button
                onClick={() => handleUrlSubmit(section.id)}
                className="px-4 py-2 rounded-lg bg-white/5 hover:bg-white/10 text-white"
              >
                <LinkIcon className="w-4 h-4" />
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Strip the ids before saving
      const sectionsForSave = sections.map(
        ({ id, ...sectionData }) => sectionData
      );
      await onSave({
        ...metadata,
        sections: sectionsForSave,
      });
    } catch (error) {
      console.error("Error saving post:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="bg-white/5 rounded-lg border border-white/10 p-6">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-white">
          {post?.id ? "Edit Post" : "New Post"}
        </h2>
        <button
          onClick={onClose}
          className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
        >
          <X className="w-4 h-4" />
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Metadata Section */}
        <div className="space-y-4 border-b border-white/10 pb-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Title */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-white/70 mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={metadata.title}
                onChange={handleMetadataChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                placeholder="Enter post title..."
                required
              />
            </div>

            {/* Category & Status */}
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Category
              </label>
              <input
                type="text"
                name="category"
                value={metadata.category}
                onChange={handleMetadataChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                placeholder="Enter category..."
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Status
              </label>
              <select
                name="status"
                value={metadata.status}
                onChange={handleMetadataChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
              </select>
            </div>

            {/* Author & Featured Image */}
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Author
              </label>
              <input
                type="text"
                name="author"
                value={metadata.author}
                onChange={handleMetadataChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                placeholder="Enter author name..."
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-white/70 mb-2">
                Featured Image
              </label>
              <div className="flex items-center gap-4">
                {metadata.featuredImage && (
                  <div className="relative w-20 h-20">
                    <img
                      src={metadata.featuredImage}
                      alt="Featured"
                      className="w-full h-full object-cover rounded-lg"
                    />
                    <button
                      type="button"
                      onClick={() =>
                        setMetadata((prev) => ({ ...prev, featuredImage: "" }))
                      }
                      className="absolute -top-2 -right-2 p-1 rounded-full bg-black/50 hover:bg-black/70 text-white"
                    >
                      <X className="w-4 h-4" />
                    </button>
                  </div>
                )}
                <button
                  type="button"
                  onClick={() =>
                    document.getElementById("featured-image-upload").click()
                  }
                  className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
                >
                  <Upload className="w-4 h-4" />
                  {metadata.featuredImage ? "Change Image" : "Upload Image"}
                </button>
                <input
                  id="featured-image-upload"
                  type="file"
                  onChange={(e) => handleImageUpload(e)}
                  accept="image/*"
                  className="hidden"
                />
              </div>
            </div>

            {/* Excerpt */}
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-white/70 mb-2">
                Excerpt
              </label>
              <textarea
                name="excerpt"
                value={metadata.excerpt}
                onChange={handleMetadataChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white h-24"
                placeholder="Enter a brief excerpt..."
                required
              />
            </div>
          </div>
        </div>

        {/* Draggable Content Sections */}
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext
            items={sections.map((s) => s.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-6">
              {sections.map((section) => (
                <SortableItem
                  key={section.id}
                  section={section}
                  onRemove={removeSection}
                  renderSection={renderSection}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>

        {/* Add Section Button */}
        <div className="border-t border-white/10 pt-6">
          <div className="flex flex-wrap gap-2">
            <button
              type="button"
              onClick={() => addSection(SECTION_TYPES.TEXT)}
              className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
            >
              <Type className="w-4 h-4" />
              Add Text
            </button>
            <button
              type="button"
              onClick={() => addSection(SECTION_TYPES.IMAGE)}
              className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
            >
              <ImageIcon className="w-4 h-4" />
              Add Image
            </button>
            <button
              type="button"
              onClick={() => addSection(SECTION_TYPES.VIDEO)}
              className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
            >
              <Film className="w-4 h-4" />
              Add Video
            </button>
            <button
              type="button"
              onClick={() => addSection(SECTION_TYPES.SUBTITLE)}
              className="px-4 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white flex items-center gap-2"
            >
              <Heading className="w-4 h-4" />
              Add Subtitle
            </button>
          </div>
        </div>

        {/* Submit Buttons */}
        <div className="flex justify-end gap-4">
          <button
            type="button"
            onClick={onClose}
            className="px-6 py-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="px-6 py-2 rounded-full bg-primary-green text-white flex items-center gap-2 disabled:opacity-50"
          >
            {loading && <Loader className="w-4 h-4 animate-spin" />}
            Save Post
          </button>
        </div>
      </form>
    </div>
  );
};

export default BlogEditor;
