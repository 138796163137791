import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import {
  Volume2,
  Menu,
  VolumeX,
  ArrowUpRight,
  Contact,
  Link,
} from "lucide-react";
import EQVisualization from "../Components/EQVisualization";
import PartnersSection from "../Components/PartnersSection";
import { audioManager } from "../utils/audioContext";
import Navigation from "../Components/Navigation";
import ValueSection from "../Components/ValueSection";
import SonicBrandingSection from "../Components/SonicBrandingSection";
import InfoBoxesSection from "../Components/InfoBoxesSection";
import TestimonialSection from "../Components/TestimonialSection";
import StartSection from "../Components/StartSection";
import WorkingProcess from "../Components/WorkingProcess";
import TestimonialSlider from "../Components/TestimonialSlider";
import StatSection from "../Components/StatSection";
import ClientWork from "../Components/ClientWork";
import useIsMobile from "../hooks/isMobile";
import { X } from "lucide-react";
import InfiniteScrollText from "../Components/InfiniteScrollText";
import BookCallCTA from "../Components/BookCallCTA";
import SEO from "../Components/SEO";

const Homepage = () => {
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const isMobile = useIsMobile();

  return (
    <>
      <SEO
        title="Home"
        description="Hear Me Out Music Co. - Audio production and sound design studio creating memorable sonic experiences for brands"
        type="website"
      />
      <div className="relative min-h-screen bg-[#111111] overflow-x-hidden w-full">
        <Navigation
          audioManager={audioManager}
          isAudioInitialized={isAudioInitialized}
          setIsAudioInitialized={setIsAudioInitialized}
        />

        {/* Hero Section */}
        <div
          className="relative w-full"
          style={{ height: isMobile ? "75vh" : "100vh" }}
        >
          {/* Background Elements */}
          <div className="absolute inset-0">
            {/* Video Background - Now visible on all screens */}
            <div className="absolute inset-0 overflow-hidden">
              <video
                src="https://video.wixstatic.com/video/ac6faa_f4ca730807da4046926ada8d23e1fee4/720p/mp4/file.mp4"
                className="w-full h-full object-cover opacity-80"
                autoPlay
                loop
                muted
                playsInline
              />
              {/* Gradient overlay that fades video into EQ */}
              <div className="absolute inset-0 bg-gradient-to-b from-transparent via-[#111111]/80 to-[#111111]" />
            </div>

            {/* Dot pattern overlay */}
            <div className="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,_rgb(1,178,128)_1px,_transparent_0)] [background-size:40px_40px] opacity-5" />
          </div>

          {/* EQ Visualization - Now shown below video */}
          <div
            className="absolute inset-0 hidden md:block"
            style={{ top: "50%" }}
          >
            <EQVisualization />
          </div>

          {/*Mobile Video Background*/}
          <div className="absolute inset-0 overflow-hidden md:hidden">
            <video
              src="https://video.wixstatic.com/video/ac6faa_f4ca730807da4046926ada8d23e1fee4/720p/mp4/file.mp4"
              className="w-full h-full object-cover opacity-20"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>

          {/* Main Content */}
          <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 pt-24 sm:pt-48 ">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="text-center max-w-4xl mx-auto"
            >
              {/* Pre-heading */}
              <motion.span
                className="inline-block text-primary-green mb-4 sm:mb-6 text-base sm:text-lg"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
              >
                WELCOME TO HEAR ME OUT
              </motion.span>

              {/* Main Heading */}
              <motion.h1
                className="text-4xl md:text-7xl font-light text-white mb-6 sm:mb-8 leading-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                HELPING BRANDS
                <br />
                <span className="font-bold">SOUND AS GOOD</span>
                <br />
                <span className="font-bold">AS THEY LOOK</span>
              </motion.h1>

              <motion.p
                className="text-lg sm:text-xl text-white/70 mb-8 sm:mb-12"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                {/* MAXIMIZE YOUR MARKETING SPEND BY USING SOUND STRATEGICALLY. */}
                Unlocking value that only sound can.
              </motion.p>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.8 }}
                onHoverStart={() => audioManager.playSound("hover", 0)}
                onClick={() => audioManager.playSound("click", 0)}
              >
                <a
                  href="https://join.hearmeoutmusic.co/free-pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="group inline-flex items-center gap-2 px-4 sm:px-8 py-3 sm:py-4 bg-primary-green text-white rounded-full font-medium text-base sm:text-lg hover:bg-primary-green/90 transition-all"
                >
                  <Volume2 className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="text-sm sm:text-base">
                    GRAB OUR FREE SONIC BRANDING EBOOK
                  </span>
                  <ArrowUpRight className="w-4 h-4 sm:w-5 sm:h-5 group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform" />
                </a>
              </motion.button>
            </motion.div>
          </div>

          {/* Services Strip */}
          <div className="absolute bottom-0 left-0 right-0 z-10">
            <InfiniteScrollText />
          </div>
        </div>

        {/* Main Content Sections */}
        <main className="relative z-10">
          <section id="partners">
            <PartnersSection />
          </section>

          <section id="value">
            <ValueSection />
          </section>

          <section id="sonic-branding">
            <SonicBrandingSection />
          </section>

          <section id="info-boxes">
            <InfoBoxesSection />
          </section>

          <section id="testimonial">
            <TestimonialSection />
          </section>

          <section id="start">
            <StartSection />
          </section>

          <section id="process">
            <WorkingProcess />
          </section>

          <section id="testimonial-slider">
            <TestimonialSlider />
          </section>

          <section id="stats">
            <StatSection />
          </section>

          <section id="client-work">
            <ClientWork />
          </section>

          <section id="contact">
            <BookCallCTA />
          </section>
        </main>
      </div>
    </>
  );
};

export default Homepage;
