import "./App.css";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Homepage";
import Services from "./Pages/Services";
import ClientWork from "./Pages/ClientWork";
import ProjectPage from "./Pages/[slug]"; // Change this import
import AdminRoute from "./Components/admin/AdminRoute";
import "./firebase";
import ProcessSection from "./Components/ProcessSection";
import ROI from "./Pages/ROI";
import useInitAudio from "./Components/useInitAudio";
import AudioInitialiser from "./Components/AudioInitialiser";
import SoundBoard from "./Pages/Soundboard";
import BlogPage from "./Pages/BlogPage";
import BlogPost from "./Pages/BlogPost";

import Contact from "./Pages/Contact";

function App() {
  useInitAudio();
  return (
    <HelmetProvider>
      <Router>
        {/* <AudioInitialiser /> */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/client-work" element={<ClientWork />} />
          <Route path="/work/:slug" element={<ProjectPage />} />{" "}
          <Route path="/process" element={<ProcessSection />} /> //
          <Route path="/soundboard" element={<SoundBoard />} />
          <Route path="/about" element={<Homepage />} />
          <Route path="/roi" element={<ROI />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:postId" element={<BlogPost />} />
          <Route path="/admin/*" element={<AdminRoute />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
